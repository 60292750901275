import React from "react";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";

import { Actions, FormHandlers, FormSetters, FormTabHandler } from "./useForm";
import { useGoto } from "./useGoto";
import { useRouter } from "./useRouter";

export function useQuery <T>(query: T): [ T, Actions<T> ] {
  const route = useRouter();
  const { goto } = useGoto();

  const setters = { } as FormSetters<T>;
  const handle = { } as FormHandlers<T>;
  const tabHandle = {} as FormTabHandler<T>;
  const result = { } as T;
  for (const k of Object.keys(query || {})) {
    const key = k as keyof T;
    
    result[key] = route.query.hasOwnProperty(key) 
      ? (typeof query[key] === 'number' 
        ? parseInt(route.query[key] as string, 10) 
        : route.query[key] as unknown as number
      ) as T[keyof T]
      : query[key];

    /* eslint-disable-next-line react-hooks/rules-of-hooks */
    setters[key] = React.useCallback(
      (value: T[keyof T]) => {
        goto({ ...route, query: { ...route.query, [key]: value?.toString() } })
      },
      [ goto, route, key ]
    );
    /* eslint-disable-next-line react-hooks/rules-of-hooks */
    handle[key] = React.useCallback(
      (
        event: 
          React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          | SelectChangeEvent<T[keyof T]>
      ) => {
        goto({ ...route, query: { ...route.query, [key]: event.target.value as string } })
      },
      [ goto, route, key ]
    );
    /* eslint-disable-next-line react-hooks/rules-of-hooks */
    tabHandle[key] = React.useCallback(
      (_:unknown, value: unknown) => {
        goto({ ...route, query: { ...route.query, [key]: value?.toString() } })
      },
      [ goto, route, key ]
    );
  } 

  return [ result, { set: setters, handle, tabHandle } ]
}

export type QuerySetters<T> = { [key in keyof T]: (value: T[key]) => void; };