import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiBilling } from "..";

@ApiEntity("Withdraws", "Methods for managing withdraws")
export class APIWithdraws {
  constructor (
    public api: ApiBilling
  ) { }

  @ApiMethod(
    "Method for create withdraw",
    [ "withdraws#create" ],
    {
      device: "device_id",
      service: "service_id",
      direction: "18002727373",
      amount: 1,
      dueInterval: 300,
      isInternal: false,
      payload: { }
    } as WithdrawCreateRequest,
    {
      id: "id",
      secret: "secret"      
    } as WithdrawCreateResponse
  )
  async create<T> (request: WithdrawCreateRequest<T>): Promise<WithdrawCreateResponse> {
    return this.api.execute("/withdraws", "POST", null, request);
  }

  @ApiMethod(
    "Method for apply withdraw",
    [ "withdraws#apply" ],
    {
      secret: "secret",
      reason: "reason"
    } as WithdrawApplyCancelRequest,
    undefined
  )
  async apply (request: WithdrawApplyCancelRequest): Promise<void> {
    return this.api.execute(
      `/withdraws/${request.secret}`, 
      "PUT", 
      { reason: request.reason }, 
      null
    );
  }

  @ApiMethod(
    "Method for apply withdraw",
    [ "withdraws#cancel" ],
    {
      secret: "secret",
      reason: "reason"
    } as WithdrawApplyCancelRequest,
    undefined
  )
  async cancel (request: WithdrawApplyCancelRequest): Promise<void> {
    return this.api.execute(
      `/withdraws/${request.secret}`, 
      "DELETE", 
      { reason: request.reason }, 
      null
    );
  }
}

export type WithdrawCreateRequest<T = Record<string, unknown>> = {
  device: string;
  service: string;
  direction: string;
  amount: number;
  isInternal: boolean;
  dueInterval: number;
  payload: T;
};

export type WithdrawCreateResponse = {
  id: string,
  secret: string
};

export type WithdrawApplyCancelRequest = {
  secret: string;
  reason: string;
};