import { Security } from "./security";
import { Sessions } from "./sessions";

export function SettingsTab ({ tab }: RouterProps) {
  switch (tab) {
    case 'security':
      return <Security />;

    case 'sessions':
      return <Sessions />;

    default:
      return <div>Unhandled tab: {JSON.stringify(tab)}</div>
  }
}

export type RouterProps = {
  tab: string
};