import React from "react";
import { useForm } from "../../hooks";

import { LayoutAppBar } from "./AppBar";
import { LayoutMenu } from "./Menu";
import { useStyles } from "./styles";

export function Layout ({ children }: LayoutProps) {
  const styles = useStyles();
  const [ { menuVisible, userMenuVisible }, actions ] = useForm({ 
    menuVisible: false,
    userMenuVisible: false
  });

  const handleMenuOpen = React.useCallback(() => actions.set.menuVisible(true), [ actions ]);
  const handleMenuClose = React.useCallback(() => actions.set.menuVisible(false), [ actions ]);
  const handleUserMenuOpen = React.useCallback(() => actions.set.userMenuVisible(true), [ actions ]);
  const handleUserMenuClose = React.useCallback(() => actions.set.userMenuVisible(false), [ actions ]);

  return (
    <div className={styles.root}>
      <LayoutAppBar 
        userMenuVisible={userMenuVisible}
        onMenuOpen={handleMenuOpen}
        onUserMenuOpen={handleUserMenuOpen}
        onUserMenuClose={handleUserMenuClose}
      />
      <LayoutMenu open={menuVisible} onClose={handleMenuClose} />
      <div className={styles.children}>
        {children}
      </div>
    </div>
  )
}

export type LayoutProps =  React.PropsWithChildren;