import FormActions from './actions'
import React from 'react';
import { useStyles } from './styles'

export function Form ({ children, className }: FormProps) {
  const styles = useStyles();

  return (
    <div className={`${styles.root} ${className || ''}`}>
      {children}
    </div>
  )
}

export type FormProps = React.PropsWithChildren<{ 
  className?: string
}>;

Form.Actions = FormActions;