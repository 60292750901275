import React from "react";
import { useDev } from "../../../hooks/useDev";

export function useDevApi ({ q, service, entity, permission }: DevApiOptions) {
  const { methods } = useDev();

  return React.useMemo(() => ({
    methods,
    visibleMethods: Object.values(methods).filter((method) => {
      const methodService = method.entity.service.id;
      const methodEntity = method.entity.name;

      if (
        q != null 
        && q !== '' 
        && `${method.entity.name}/${method.name}`.toLowerCase().indexOf(q.toLowerCase()) < 0
      ) {
        return false;
      }
      
      if (
        service != null 
        && service !== '' 
        && methodService.indexOf(service.toLowerCase()) < 0
      ) {
        return false;
      }

      if (
        entity != null 
        && entity !== '' 
        && methodEntity.toLowerCase().indexOf(entity.toLowerCase()) < 0
      ) {
        return false;
      }

      if (
        permission != null 
        && permission !== '' 
        && method.permissions.find((x) => x.toLowerCase().indexOf(permission.toLowerCase()) >= 0) == null
      ) {
        return false;
      }

      return true;
    })
  }), [ methods, q, service, entity, permission ]);
}

export type DevApiOptions = {
  q: string | null,
  service: string | null,
  entity: string | null,
  permission: string | null,
};