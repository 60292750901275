export const rhai = `# rhai

## For what

We need to be able to safely run custom scripts.

This will allow us to flexibly collect reports, write custom checks, and generally useful.

## How?

To do this, we need a safe sandbox and rhai the answer to our prayers.

We check the signature, inject the objects or [api](https://rhai.rs/book/rust/functions.html) 
we need and run it.

## Data

Store as date we will [rhai ast](https://rhai.rs/book/engine/compile.html).`;