import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React from "react";
import { useQuery } from "../../../hooks"
import { SettingsTab } from "./tabs";

export function PersonalSettings () {
  const [ { tab }, queryActions ] = useQuery({ tab: 'security' });

  const setTab = queryActions.set.tab;
  const handleTab = React.useCallback((_: unknown, value: string) => setTab(value), [ setTab ]);

  return (
    <>
      <Tabs value={tab} onChange={handleTab}>
        <Tab value="security" label="Security" />
        <Tab value="sessions" label="Sessions" />
      </Tabs>
      <Box sx={{ marginTop: 2 }}>
        <SettingsTab tab={tab} />
      </Box>
    </>
  )
}