import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Groups3Icon from "@mui/icons-material/Groups3";
import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import WalletIcon from "@mui/icons-material/Wallet";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { Route, useGoto } from "../../../hooks";

const PAGES: Record<'handleSettings' | 'handleWallets' | 'handleGroups', Route> = {
  handleSettings: { page: 'settings', query: {} },
  handleWallets: { page: 'wallets', query: {} },
  handleGroups: { page: 'groups', query: {} },
};

export function LayoutAppBar({
  userMenuVisible,
  onMenuOpen,
  onUserMenuOpen,
  onUserMenuClose
}: LayoutAppBarProps) {
  const { handleSettings, handleWallets, handleGroups } = useGoto(PAGES, onUserMenuClose);
  const anchorEl = React.useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <div style={{ width: '100%', height: 80 }} />
      <AppBar position="fixed" sx={{ marginBottom: 2 }}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={onMenuOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Narayana
          </Typography>
          <IconButton
            ref={anchorEl}
            size="large"
            aria-label="wallet of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            color="inherit"
            onClick={onUserMenuOpen}
          >
            <AccountCircleIcon />
          </IconButton>
          <Menu
            open={userMenuVisible}
            anchorEl={anchorEl.current}
            keepMounted
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right', }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right', }}
            onClose={onUserMenuClose}
          >
            <MenuItem onClick={handleSettings}>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText>
                Settings
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={handleWallets}>
              <ListItemIcon>
                <WalletIcon />
              </ListItemIcon>
              <ListItemText>
                Wallets
              </ListItemText>
            </MenuItem>
            <MenuItem onClick={handleGroups}>
              <ListItemIcon>
                <Groups3Icon />
              </ListItemIcon>
              <ListItemText>
                Groups
              </ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText>
                Sign Out
              </ListItemText>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </>
  )
}

export type LayoutAppBarProps = {
  userMenuVisible: boolean,
  onMenuOpen: () => void,
  onUserMenuOpen: () => void,
  onUserMenuClose: () => void,
};