import { DirectionsImportDataProps } from ".";
import { useForm } from "../../../../../hooks";
import { parse } from "./parse";

export const useData = ({
  onDataChange,
  onDataColumnsChange
}: DirectionsImportDataProps) => {
  const [ 
    { uploadError },
    { 
      set: { uploadError: setUploadError }
    }
  ] = useForm({
    uploadError: null as string | null
  });

  const onDrop = (acceptedFiles: File[]) => {
    onDataChange([]);
    onDataColumnsChange([]);

    if (acceptedFiles.length < 1) {
      setUploadError('File not found');
      return;
    }

    setUploadError(null);

    parse(acceptedFiles[0])
      .then(({ data, columns }) => {
        onDataChange(data);
        onDataColumnsChange(columns);
      })
      .catch((e) => setUploadError(e.message))
  };

  return {
    onDrop,
    uploadError
  }
}