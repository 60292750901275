import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Form } from "@narayana/ui";
import { GroupUserCreateRequest } from "@narayana/sso-api";
import { useCallback, useState } from "react";

export const AddMembersForm = function (props: AddMembersFormProps) {
  const [user, setUser] = useState('');
  const [error, setError] = useState('');
  const [pending, setPending] = useState(false);
  const onUserChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setError('');
    setUser(event.target.value);
  }, []);
  const handleAdd = useCallback(async () => {
    setPending(true);
    const request = {
      group: props.group || '',
      user,
      payload: {}
    };
    try {
      await props.onClick(request);
      props.onVisibleChange(false);
    } catch (error) {
      setError((error as Error).message);
    } finally {
      setPending(false);
    }
  }, [props, user]);

  return (
    <>
      <Form>
        <TextField 
          label="user" 
          onChange={onUserChange} 
          value={user} 
          disabled={pending}
        />
        <Form.Actions error={error || ''}>
          <Button 
            onClick={handleAdd}
            disabled={pending}
          >
            Create
          </Button>
        </Form.Actions>
      </Form>
    </>
  )
}

export type AddMembersFormProps = {
  onClick: (request: GroupUserCreateRequest) => void;
  onVisibleChange: (visible: boolean) => void;
  group: string;
}