import { useData } from "./useData"
import { Provider } from "@narayana/billing-api";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Form, Loading } from "@narayana/ui";
import { Code } from "../../../../Code";

export const ProviderTabDevices = ({ provider, loading }: ProviderTabDevicesProps) => {
  const {
    plan,
    code,
    gate,
    payload,
    isActive,
    inProgress,
    error,
    onPlanChange,
    setGate,
    setCode,
    setPayload,
    onDeleteRestore,
    onSave
  } = useData(provider);

  return (
    <Form>
      <Loading loading={loading}>
        <TextField
          fullWidth
          disabled={inProgress}
          label="Plan"
          value={plan}
          onChange={onPlanChange}
        />
      </Loading>
      <Loading loading={loading}>
        <Code
          language="json"
          title="Code"
          value={code}
          onChange={setCode}
          disabled={inProgress}
        />
      </Loading>
      <Loading loading={loading}>
        <Code
          language="json"
          title="Gate"
          value={gate}
          onChange={setGate}
          disabled={inProgress}
        />
      </Loading>
      <Loading loading={loading}>
        <Code
          language="json"
          title="Payload"
          value={payload}
          onChange={setPayload}
          disabled={inProgress}
        />
      </Loading>
      <Form.Actions error={error}>
        {isActive === null
          ? null
          : (
            <Button onClick={onDeleteRestore} color="secondary">
              {isActive ? 'delete device': 'restore'}
            </Button>
          )
        }
        <Button onClick={onSave} color="primary">
          {isActive === null?
            'CREATE DEVICE':
            'SAVE'
          }
        </Button>
      </Form.Actions>
    </Form>
  )
}

export type ProviderTabDevicesProps = {
  provider: Provider,
  loading: boolean,
};
