import AppBar from "@mui/material/AppBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import EuroIcon from '@mui/icons-material/Euro';
import HistoryIcon from '@mui/icons-material/History';
import IconButton from "@mui/material/IconButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Toolbar from "@mui/material/Toolbar";

import { Route, useGoto } from "../../../hooks";

type Hadlers = 'devServices'
  | 'devApi'
  | 'devTools'
  | 'ssoUsers'
  | 'billingCurrencies'
  | 'billingDevices'
  | 'billingDirections'
  | 'billingServices'
  | 'billingPlans'
  | 'billingProviders'
  | 'billingActs'
  | 'invites'
  | 'history';

const PAGES: Record<Hadlers, Route> = {
  devServices: { page: 'dev/services', query: {} },
  devApi: { page: 'dev/api', query: {} },
  devTools: { page: 'dev/tools', query: {} },
  ssoUsers: { page: 'sso/users', query: {} },
  billingCurrencies: { page: 'billing/currencies', query: {} },
  billingDevices: { page: 'billing/devices', query: {} },
  billingDirections: { page: 'billing/directions', query: {} },
  billingServices: { page: 'billing/services', query: {} },
  billingPlans: { page: 'billing/plans', query: {} },
  billingProviders: { page: 'billing/providers', query: {} },
  billingActs: { page: 'billing/acts', query: {} },
  invites: { page: 'invites', query: {} },
  history: { page: 'history', query: {} },
};

export function LayoutMenu({ open, onClose }: LayoutMenuProps) {
  const goto = useGoto(PAGES, onClose);

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
    >
      <AppBar position="relative">
        <Toolbar sx={{ width: 150, maxWidth: '80vw' }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={onClose}
          >
            <ArrowBackIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
      <MenuList>
        <MenuItem>
          <ListItemIcon>
            <DeveloperBoardIcon />
          </ListItemIcon>
          <ListItemText>Dev Zone</ListItemText>
        </MenuItem>
        <MenuItem onClick={goto.devServices}>
          <ListItemText inset>Services</ListItemText>
        </MenuItem>
        <MenuItem onClick={goto.devApi}>
          <ListItemText inset>API</ListItemText>
        </MenuItem>
        <MenuItem onClick={goto.devTools}>
          <ListItemText inset>Tools</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <ManageAccountsIcon />
          </ListItemIcon>
          <ListItemText>SSO</ListItemText>
        </MenuItem>
        <MenuItem onClick={goto.ssoUsers}>
          <ListItemText inset>Users</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem>
          <ListItemIcon>
            <EuroIcon />
          </ListItemIcon>
          <ListItemText>Billing</ListItemText>
        </MenuItem>
        <MenuItem onClick={goto.billingCurrencies}>
          <ListItemText inset>Currencies</ListItemText>
        </MenuItem>
        <MenuItem onClick={goto.billingDevices}>
          <ListItemText inset>Devices</ListItemText>
        </MenuItem>
        <MenuItem onClick={goto.billingDirections}>
          <ListItemText inset>Directions</ListItemText>
        </MenuItem>
        <MenuItem onClick={goto.billingServices}>
          <ListItemText inset>Services</ListItemText>
        </MenuItem>
        <MenuItem onClick={goto.billingPlans}>
          <ListItemText inset>Plans</ListItemText>
        </MenuItem>
        <MenuItem onClick={goto.billingActs}>
          <ListItemText inset>Acts</ListItemText>
        </MenuItem>
        <MenuItem onClick={goto.billingProviders}>
          <ListItemText inset>Providers</ListItemText>
        </MenuItem>
      </MenuList>
      <Divider />
      <MenuItem onClick={goto.invites}>
          <ListItemText inset>Invites</ListItemText>
        </MenuItem>
      <Divider />
      <MenuItem onClick={goto.history}>
        <ListItemIcon>
          <HistoryIcon />
        </ListItemIcon>
        <ListItemText>History</ListItemText>
      </MenuItem>
    </Drawer>
  )
}

export type LayoutMenuProps = {
  open: boolean,
  onClose: () => void,
};