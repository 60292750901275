import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiBilling } from "..";

@ApiEntity("Currencies", "Methods for managing currencies")
export class APICurrencies {
  constructor (
    public api: ApiBilling
  ) { }

  @ApiMethod(
    "Method for create currency",
    [ "currencies#create" ],
    {
      id: "currency_id",
      title: "currency_title",
      comma: 2,
      payload: { }
    } as CurrencyCreateRequest,
    { 
      id: "currency_id",
      title: "My currency",
      comma: 2,
      isActive: true,
      payload: { }
    } as Currency
  )
  async create<T> (request: CurrencyCreateRequest<T>): Promise<Currency> {
    return this.api.execute("/currencies", "POST", null, request);
  }

  @ApiMethod(
    "Method for update currency",
    [ "currencies#update" ],
    {
      id: "currency_id",
      title: "currency_title",
      comma: 2,
      payload: { }
    } as CurrencyUpdateRequest,
    undefined
  )
  async update (request: CurrencyUpdateRequest): Promise<void> {
    return this.api.execute("/currencies", "PUT", null, request);
  }

  @ApiMethod(
    "Method for delete currency",
    [ "currencies#delete" ],
    "<currency-id>",
    undefined
  )
  async delete (id: string): Promise<void> {
    return this.api.execute(`/currencies/${id}`, "DELETE", null, null);
  }

  @ApiMethod(
    "Method for restore currency",
    [ "currencies#restore" ],
    "<currency-id>",
    undefined
  )
  async restore (id: string): Promise<void> {
    return this.api.execute(`/currencies/${id}/restore`, "PUT", null, null);
  }

  @ApiMethod(
    "Method for get currency",
    [ "currencies#read" ],
    "<currency-id>",
    { 
      id: "<uuid>",
      title: "My currency",
      comma: 2,
      isActive: true,
      payload: { answer: 42 }
    } as Currency
  )
  async get (id: string): Promise<Currency> {
    return this.api.execute(`/currencies/${id}`, "GET", null, null);
  }

  @ApiMethod(
    "Method for counting the number of currencies by filter (all parameters are optional)",
    [ "currencies#read" ],
    {
      q: "Search filter",
      is_active: true
    },
    { count: 42 }
  )
  async count (filter: CurrenciesListFilterCount): Promise<{ count: number }> {
    return this.api.execute(`/currencies/count`, "GET", filter, null);
  }

  @ApiMethod(
    "Method for counting the number of currencies by filter (all parameters are optional)",
    [ "currencies#read" ],
    {
      q: "Search filter",
      is_active: true,
      limit: 10,
      offset: 0
    },
    [
      { 
        id: "<uuid>",
        title: "My currency",
        comma: 2,
        isActive: true,
        payload: { answer: 42 }
      }
    ] as Currency[]
  )
  async list (filter: CurrenciesListFilter): Promise<Currency[]> {
    return this.api.execute(`/currencies`, "GET", filter, null);
  }
}

export type Currency<T = CurrencyPayload> = {
  id: string;
  title: string;
  comma: number;
  isActive: boolean;
  payload: T;
}

export type CurrencyCreateRequest<T = CurrencyPayload> = {
  id: string;
  title: string;
  comma: number;
  payload: T;
}

export type CurrencyUpdateRequest<T = CurrencyPayload> = {
  id: string,
  title: string;
  comma: number;
  payload: T,
}

export type CurrenciesListFilterCount = {
  q?: string | null,
  is_active?: boolean | null,
}

export type CurrenciesListFilter = CurrenciesListFilterCount & {
  limit: number,
  offset: number,
};

export type CurrencyPayload = Record<string, unknown>;