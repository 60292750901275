import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { AbsorptionRequest } from "@narayana/billing-api";
import { Form } from "@narayana/ui";
import { useCallback } from "react";
import { useForm } from "../../../../hooks";

export function ActsAbsorption({ inProgress, execute, error, absorptionRequestStarted }: ActsAbsorptionProps) {
    const [
        { wallet, currency, amount },
        {
            handle: {
                wallet: handleWallet,
                currency: handleCurrency,
                amount: handleAmount,
            },

        }
    ] = useForm({
        wallet: '',
        currency: '',
        amount: '0',
        emissionRequestStarted: false
    });

    const handleExecute = useCallback(
        () => {
            execute({
                wallet,
                currency,
                amount: parseInt(amount, 10)
            });
        },
        [execute, wallet, currency, amount]
    );

    return (
        <Form>
            <TextField
                id="wallet"
                label="Wallet"
                variant="outlined"
                value={wallet}
                disabled={inProgress}
                onChange={handleWallet}
            />
            <TextField
                id="currency"
                label="Currency"
                variant="outlined"
                value={currency}
                disabled={inProgress}
                onChange={handleCurrency}
            />
            <TextField
                id="amount"
                label="Amount"
                variant="outlined"
                value={amount}
                disabled={inProgress}
                onChange={handleAmount}
            />
            <Form.Actions
                error={error}
                success={!inProgress && !error && absorptionRequestStarted ? "Done" : ""}
            >
                <Button
                    variant="contained"
                    onClick={handleExecute}
                    disabled={inProgress}
                >
                    Absorp
                </Button>
            </Form.Actions>
        </Form>
    );
}
export type ActsAbsorptionProps = {
    inProgress: boolean;
    execute: (request: AbsorptionRequest) => void;
    error: string;
    absorptionRequestStarted: boolean;
}