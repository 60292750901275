import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  header: {
    display: 'flex',
    flexDirection: 'row'
  },
  content: {
    marginTop: 4
  }
});