import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { SetOverdraftRequest } from "@narayana/billing-api";
import { Form } from "@narayana/ui";
import { useCallback } from "react";
import { useForm } from "../../../../hooks";

export function ActsOverdraft({ inProgress, execute, error, setOverdraftRequestStarted }: ActsOverdraftProps) {
    const [
        { wallet, currency, overdraft },
        {
            handle: {
                wallet: handleWallet,
                currency: handleCurrency,
                overdraft: handleOverdraft,
            },

        }
    ] = useForm({
        wallet: '',
        currency: '',
        overdraft: '0',
        emissionRequestStarted: false
    });

    const handleExecute = useCallback(
        () => {
            execute({
                wallet,
                currency,
                overdraft: parseInt(overdraft, 10)
            });
        },
        [execute, wallet, currency, overdraft]
    );

    return (
        <>
            <Form>
                <TextField
                    id="wallet"
                    label="Wallet"
                    variant="outlined"
                    value={wallet}
                    disabled={inProgress}
                    onChange={handleWallet}
                />
                <TextField
                    id="currency"
                    label="Currency"
                    variant="outlined"
                    disabled={inProgress}
                    onChange={handleCurrency}
                />
                <TextField
                    id="overdraft"
                    label="Overdraft"
                    variant="outlined"
                    disabled={inProgress}
                    onChange={handleOverdraft}
                />
                <Form.Actions
                    error={error}
                    success={!inProgress && !error && setOverdraftRequestStarted ? "Done" : ""}
                >
                    <Button
                        variant="contained"
                        onClick={handleExecute}
                        disabled={inProgress}
                    >
                        Set Overdraft
                    </Button>
                </Form.Actions>
            </Form>
        </>
    );
}
export type ActsOverdraftProps = {
    inProgress: boolean;
    execute: (request: SetOverdraftRequest) => void;
    error: string;
    setOverdraftRequestStarted: boolean;
}