import { Filter, Form } from "@narayana/ui";
import { useApi, useForm, useQuery, useRequest } from "../../../hooks"

import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { List } from "../../../ui/List"
import { PlanListItem } from "../../../ui/billing/plans/ListItem";
import { Plan, PlansListRequest, PlanUpdateRequest } from "@narayana/billing-api";
import React from "react";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Entity } from "../../../ui";
import { PlanEdit } from "../../../ui/billing/plans/Edit";

export function BillingPlans() {
  const api = useApi();
  const [{ filterVisible }, actions] = useForm({ filterVisible: false });
  const [{ page, id, q, wallet, isActive }, queryActions] = useQuery({
    page: 1,
    id: null as string | null,
    q: '',
    wallet: '' as string | null,
    isActive: null as string | null
  });
  const countRequest = useRequest(
    (filter: PlansListRequest) => api.billing.plans.count(filter),
    { debounce: true }
  );
  const filter = React.useMemo(() => ({
    q: q || null,
    id: id || null,
    is_active: isActive == null ? null : isActive === 'true',
    wallet: wallet || null,
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE
  }), [q, id, isActive, wallet, page]);

  const countRequestExecute = countRequest.execute;
  React.useEffect(() => { countRequestExecute(filter) }, [filter, countRequestExecute]);

  const handleIsActiveChange = React.useCallback(
    (_: unknown, value: boolean) => queryActions.set.isActive(value.toString()),
    [queryActions.set]
  );

  const fetchEntities = React.useCallback(
    (filter: PlansListRequest) => api.billing.plans.list(filter),
    [api.billing.plans]
  );
  const handleDeleteFilterKey = React.useCallback(
    (key: string) => {
      switch (key) {
        case 'wallet':
          queryActions.set.wallet(null);
          break;
          
        case 'isActive':
          queryActions.set.isActive(null);
          break;

        default:
          throw new Error(`Unhandled filter key: ${JSON.stringify(key)}`)
      }
    },
    [queryActions.set]
  );

  const handleListClick = React.useCallback(
    (plan: Plan) => queryActions.set.id(plan.id),
    [queryActions.set]
  );
  const handleIdClear = React.useCallback(
    () => queryActions.set.id(null),
    [queryActions.set]
  );
  

  return (
    <>
      <Typography variant="h4" sx={{ display: 'flex' }}>
        Plans
      </Typography>
      <Filter
        query={q}
        filterVisible={filterVisible}
        page={page}
        maxPage={Math.max(1, Math.ceil((countRequest.result?.count || 1) / PAGE_SIZE))}
        filter={{
          isActive: isActive == null ? null : (isActive === 'true' ? 'Active' : 'Inactive'),
          wallet: wallet == null || wallet === '' ? null : `Wallet:${wallet}`,
        }}
        onQueryChange={queryActions.set.q}
        onFilterVisibleChange={actions.set.filterVisible}
        onPageChange={queryActions.set.page}
        onDeleteFilterKey={handleDeleteFilterKey}
      >
        <Form>
          <TextField
            fullWidth
            label="Wallet"
            type="string"
            value={wallet}
            onChange={queryActions.handle.wallet}
          />
          <FormGroup>
            <FormControlLabel
              label="Is Active"
              control={<Switch checked={isActive === 'true'} onChange={handleIsActiveChange} />}
            />
          </FormGroup>
        </Form>
      </Filter>
      <List
        fetch={fetchEntities}
        filter={filter}
        entityKey="plan"
        Container={PlanListItem}
        ContainerProps={{
          disabled: false,
          onClick: handleListClick,
          onDelete: (_: Plan) => { }
        }}
      />
      <Entity
        id={id}
        key={id}
        componentKey="plan"
        get={(id: string) => api.billing.plans.get(id)}
        save={async (plan: PlanUpdateRequest) => {
          await api.billing.plans.update(plan);
          handleIdClear();
        }}
        DialogProps={{
          fullScreen: true
        }}
        Component={PlanEdit}
        ComponentProps={{ }}
        onClose={handleIdClear}
      />
    </>
  )
}

const PAGE_SIZE = 10;