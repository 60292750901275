import SettingsInputComponentIcon from '@mui/icons-material/SettingsInputComponent';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { Loading } from "@narayana/ui";
import { Provider } from "@narayana/billing-api";

export const ProviderListItem = (props: ProviderListItemProps) => {
  const provider = props.provider;
  const propsOnClick = props.onClick;
  const onClick = React.useCallback(
    () => { propsOnClick(provider) },
    [provider, propsOnClick]
  );

  return (
    <ListItem disabled={props.disabled}>
      <ListItemButton
        disabled={props.disabled || props.loading}
        onClick={onClick}
      >
        <ListItemIcon>
          <Loading variant="circular" loading={props.loading} width={40} height={40}>
            <SettingsInputComponentIcon />
          </Loading>
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ sx: props.provider.isActive ? {} : { textDecoration: 'line-through' } }}
          primary={
            <Loading loading={props.loading} height={24} width={280}>
              {props.provider.title || props.provider.id}
            </Loading>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

export type ProviderListItemProps = {
  provider: Provider;
  disabled: boolean;
  loading: boolean;
  onClick: (provider: Provider) => void;
  onDelete: (provider: Provider) => void;
}

