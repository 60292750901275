import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";
import { Form } from "@narayana/ui";
import md5 from "md5";
import React from "react";
import { v4 as uuidV4 } from "uuid";
import { useApi, useForm, useMe, useRequest } from "../../../../../hooks";

export function Security() {
  const api = useApi();
  const me = useMe();

  const [state, actions] = useForm({
    changePasswordVisible: false,
    password: '',
    newPassword: '',
    repeatPassword: '',
  });

  const handleChangePasswordShow = React.useCallback(() => actions.set.changePasswordVisible(true), [actions]);
  const handleChangePasswordHide = React.useCallback(() => {
    actions.set.changePasswordVisible(false);
    actions.set.password('');
    actions.set.newPassword('');
    actions.set.repeatPassword('');
  }, [actions]);

  const changePassword = useRequest(
    async (request: ChangePasswordAction) => {
      if (request.newPassword.length < 4) {
        throw new Error("Password too short")
      }
  
      if (request.newPassword !== request.repeatPassword) {
        throw new Error("Passwords do not match")
      }

      const { salt } = await api.sso.password.salt(me.user.id);
      const newSalt = uuidV4();

      await api.sso.password.change({ 
        hash: md5(`${salt}${request.password}`),
        newSalt,
        newHash: md5(`${newSalt}${request.newPassword}`),
       });
  
      handleChangePasswordHide();
    },
  );
  
  const handleChangePassword = React.useCallback(
    () => changePassword.execute(state), 
    [changePassword, state]
  );

  return (
    <Form>
      <Card>
        <CardHeader
          title="Password"
        />
        <CardContent>
          <Collapse in={state.changePasswordVisible}>
            <Form>
              <TextField
                value={state.password}
                label="Current password"
                type="password"
                disabled={changePassword.inProgress}
                onChange={actions.handle.password}
              />
              <TextField
                value={state.newPassword}
                label="New password"
                type="password"
                disabled={changePassword.inProgress}
                onChange={actions.handle.newPassword}
              />
              <TextField
                value={state.repeatPassword}
                label="Repeat password"
                type="password"
                disabled={changePassword.inProgress}
                onChange={actions.handle.repeatPassword}
              />
            </Form>
          </Collapse>
        </CardContent>
        <Box>
          <Form.Actions error={changePassword.error?.message}>
            {!state.changePasswordVisible
              ? (
                <Button
                  variant="outlined"
                  sx={{ marginRight: 2 }}
                  disabled={changePassword.inProgress}
                  onClick={handleChangePasswordShow}
                >
                  CHANGE PASSWORD
                </Button>
              )
              : null}
            {state.changePasswordVisible
              ? (
                <>
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{ marginRight: 1 }}
                    disabled={changePassword.inProgress}
                    onClick={handleChangePasswordHide}
                  >
                    CANCEL
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                    disabled={changePassword.inProgress}
                    onClick={handleChangePassword}
                  >
                    CHANGE
                  </Button>
                </>
              )
              : null}
          </Form.Actions>
        </Box>
      </Card>
    </Form>
  )
}

type ChangePasswordAction = {
  password: string,
  newPassword: string,
  repeatPassword: string,
};