import { Device } from '@narayana/billing-api'
import { ApiFreeswitch } from '..';

export class APICalls {
  constructor (
    public api: ApiFreeswitch,
    public prefix: string
  ) { }

  create (request: CallsCreateRequest): Promise<CallsCreateResponse> {
    return this.api.execute(`${this.prefix}`, "POST", null, request);
  }

  withdraw (request: CallsWithdrawRequest): Promise<null> {
    return this.api.execute(`${this.prefix}/withdraw`, "PUT", null, request);
  }

  close (request: CallsWithdrawRequest): Promise<null> {
    return this.api.execute(`${this.prefix}`, "DELETE", null, request);
  }
}

export type CallsCreateRequest = {
  device: string,
  direction: string,
  isInternal: boolean,
};

export type CallsCreateResponse = {
  id: string,
  secret: string,
  device: Device,
  services: CallsPlanService[],
};

export type CallsPlanService = {
  id: string,
  provider: string,
  payload: Record<string, unknown>
};

export type CallsCloseRequest = {
  secret: string,
  reason: string
};

export type CallsWithdrawRequest = {
  secret: string,
  elapsedTime: number | string,
  planService: string,
  dueInterval: number
}