import { Form, Loading } from "@narayana/ui";

import Button from '@mui/material/Button';
import { Currency, CurrencyCreateRequest } from "@narayana/billing-api";
import React from 'react'
import TextField from '@mui/material/TextField';
import { Code } from "../../../Code";

export const CurrencyEdit = ({
  currency,
  loading,
  disabled,
  error,
  onChange,
  onDelete,
  onRestore,
  onCreate
}: CurrencyEditProps) => {
  const [title, setTitle] = React.useState('');
  const [comma, setComma] = React.useState(0);
  const [payload, setPayload] = React.useState('{}');
  const [internalError, setInternalError] = React.useState('');

  React.useEffect(
    () => {
      if (currency == null) {
        return;
      }

      setTitle(currency.title || '');
      setComma(currency.comma || 0);
      setPayload(JSON.stringify(currency.payload, null, 2) || '{}');
    },
    [currency, setTitle, setPayload]
  );

  const handleSaveClick = React.useCallback(
    () => {
      setInternalError('');
      try {
        onChange && currency && onChange({ id: currency.id, title, comma, isActive: currency.isActive, payload: JSON.parse(payload) })
      } catch (e) {
        setInternalError((e as Error).message);
      }
    },
    [onChange, currency, title, comma, payload]
  );
  const handleCreateClick = React.useCallback(
    () => {
      setInternalError('');
      try {
        onCreate && currency && onCreate({ id: title, title, comma, payload: JSON.parse(payload) });
      } catch (e) {
        setInternalError((e as Error).message);
      }
    },
    [comma, currency, onCreate, payload, title]
  )
  const handleDeleteClick = React.useCallback(
    () => {
      setInternalError('');
      try {
        onDelete && currency && onDelete(currency.id);
      } catch (e) {
        setInternalError((e as Error).message);
      }
    },
    [onDelete, currency]
  );
  const handleRestoreClick = React.useCallback(
    () => {
      setInternalError('');
      try {
        onRestore && currency && onRestore(currency.id);
      } catch (e) {
        setInternalError((e as Error).message);
      }
    },
    [onRestore, currency]
  );
  const handleTitleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setTitle(value);
    },
    [setTitle]
  );
  const handleCommaChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;
      setComma(parseInt(value, 10) || 0);
    },
    [setComma]
  );
  const handlePayloadChange = React.useCallback(
    (payload: string) => {
      setPayload(payload || '{}');
    },
    [setPayload]
  )
  return (
    <Form>
      <Loading loading={loading} height={56}>
        <TextField
          label="Title"
          variant="outlined"
          disabled={loading || disabled}
          value={title}
          onChange={handleTitleChange}
        />
      </Loading>
      <Loading loading={loading} height={56}>
        <TextField
          label="Comma"
          variant="outlined"
          disabled={loading || disabled}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          value={comma}
          onChange={handleCommaChange}
        />
      </Loading>
      <Loading loading={loading} height={56}>
        <Code
          value={payload}
          language="json"
          title="Payload"
          disabled={loading || disabled}
          onChange={handlePayloadChange}
        />
      </Loading>
      <Form.Actions error={error || internalError}>
        <Loading loading={loading} height={56}>
          {(currency?.id)
            ? <Button
              disabled={disabled}
              color="secondary"
              onClick={Boolean(currency?.isActive) ? handleDeleteClick : handleRestoreClick}
            >
              {Boolean(currency?.isActive) ? 'Delete' : 'Restore'}
            </Button>
            : null
          }
        </Loading>
        <Loading loading={loading} height={56}>
          <Button
            disabled={disabled}
            onClick={currency?.id
              ? handleSaveClick
              : handleCreateClick
            }
          >
            {currency?.id
              ? "Save currency"
              : "Create currency"
            }
          </Button>
        </Loading>
      </Form.Actions>
    </Form>
  )
}

export type CurrencyEditProps = {
  currency: Currency | null | undefined;
  loading?: boolean;
  disabled?: boolean;
  error?: string;
  onChange?: (currency: Currency) => void;
  onDelete?: (id: string) => void;
  onRestore?: (id: string) => void;
  onCreate?: (currency: CurrencyCreateRequest) => void;
}

export default CurrencyEdit;