import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { ServiceDescription } from "@narayana/api";
import React from "react";
import { useGoto } from "../../hooks";

export function Service({ service, serviceId, description }: ServiceProps) {
  const { goto } = useGoto({ })

  const handleClick = React.useCallback(
    () => {
      if (service == null) {
        return;
      }
      
      goto({ page: `dev/services/${service?.id}`,  query: { } })
    },
    [ goto, service ]
  );
  
  return (
    <ListItemButton onClick={handleClick}>
      <ListItemAvatar>
        <Avatar>
          <MiscellaneousServicesIcon />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={service == null
          ? (serviceId == null ? `Service not found` : `[NOT IMPLEMENTED] ${serviceId}`)
          : service.name}
        secondary={description}
      />
    </ListItemButton>
  )
}

export type ServiceProps = {
  service: ServiceDescription | null,
  serviceId?: string | null | undefined,
  description?: string | null | undefined,
};