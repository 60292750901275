export const readme = `# Package manager

## For what?

- Gives the ability to flexibly replace components in UI
- Safely execute custom code on our server

## How?

[WebAssembly](https://webassembly.org/) for browsers and 
[RHAI](https://rhai.rs/) for server side.

## TODO

- js-api
- client
- db
- service
- dev & env (proxy)
- start charging megabytes from users for posting content`;
