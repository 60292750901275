import React, { ReactNode } from "react";

import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Loading } from "@narayana/ui/target/Loading";
import PersonIcon from '@mui/icons-material/Person';
import Tooltip from '@mui/material/Tooltip';
import Typography from "@mui/material/Typography";
import { User } from "@narayana/sso-api";

export const UserListItem = (props: UserListItemProps) => {
  const onClick = React.useCallback(
    () => { props.onClick(props.user) },
    [props]
  );

  return (
    <ListItem disabled={props.disabled}>
      <ListItemButton
        disabled={props.disabled || props.loading}
        onClick={onClick}
      >
        <ListItemIcon>
          <Loading variant="circular" loading={props.loading} width={40} height={40}>
            <Tooltip title={"Status: " + props.user.status}>
              <Avatar>
                {props.avatar || <PersonIcon />}
              </Avatar>
            </Tooltip>
          </Loading>
        </ListItemIcon>
        <ListItemText
          primary={
            <Loading loading={props.loading} height={24} width={80}>
              <Tooltip title={props.user.isActive ? "" : "User is deleted."}>
                <Typography
                  sx={props.user.isActive ? {} : { textDecoration: 'line-through' }}
                >
                  {props.user.id}
                </Typography>
              </Tooltip>
            </Loading>
          }
          secondary={
            <Loading loading={props.loading} height={20} width={65}>
              {`Status: ${props.user.status}`}
            </Loading>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}
export type UserListItemProps = {
  user: User;
  avatar?: ReactNode;
  disabled: boolean;
  loading: boolean;
  onClick: (user: User) => void;
}

export default UserListItem;