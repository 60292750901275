import { Form, Loading } from "@narayana/ui";

import Button from '@mui/material/Button';
import React from 'react'
import TextField from '@mui/material/TextField';
import { User } from "@narayana/sso-api";
import { Code } from "../../../Code";

export const UserEdit = ({
  user,
  loading,
  disabled,
  error,
  onChange,
  onDelete,
  onRestore
}: UserEditProps) => {
  const [status, setStatus] = React.useState(user?.status || 0);
  const [payload, setPayload] = React.useState(JSON.stringify(user?.payload, null, 2) || '{}');
  const [internalError, setInternalError] = React.useState('');
  React.useEffect(
    () => {
      if (user == null) {
        return;
      }
      setStatus(user.status);
    },
    [user, setStatus]
  );

  const handleSaveClick = React.useCallback(
    () => onChange && user && onChange({ id: user.id, status, isActive: user.isActive, payload: JSON.parse(payload) }),
    [onChange, user, status, payload]
  );
  const handleDeleteClick = React.useCallback(
    () => onDelete && user && onDelete(user.id),
    [onDelete, user]
  );
  const handleRestoreClick = React.useCallback(
    () => onRestore && user && onRestore(user.id),
    [onRestore, user]
  );
  const handleStatusChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = parseInt(e.target.value, 10);
      setStatus(isNaN(value) ? 0 : value);
    },
    [setStatus]
  );
  const handlePayloadChange = React.useCallback(
    (payload: string) => {
      setInternalError('');
      try {
        setPayload(JSON.stringify(JSON.parse(payload), null, 2));
      } catch (e) {
        setInternalError((e as Error).message);
      }
    },
    [setInternalError]
  )

  return (
    <Form>
      <Loading loading={loading} height={56}>
        <TextField
          id="id"
          label="ID"
          variant="outlined"
          fullWidth
          disabled
          value={user?.id || ''}
        />
      </Loading>
      <Loading loading={loading} height={56}>
        <TextField
          id="status"
          label="Status"
          variant="outlined"
          disabled={loading || disabled}
          value={status}
          onChange={handleStatusChange}
        />
      </Loading>
      <Loading loading={loading} height={56}>
        <Code
          language="javascript"
          value={payload}
          onChange={handlePayloadChange}
          disabled={loading || disabled}
        />
      </Loading>
      <Form.Actions error={error || internalError}>
        <Loading loading={loading} height={56}>
          <Button
            disabled={disabled}
            color="secondary"
            onClick={Boolean(user?.isActive) ? handleDeleteClick : handleRestoreClick}
          >
            {Boolean(user?.isActive) ? 'Delete' : 'Restore'}
          </Button>
        </Loading>
        <Loading loading={loading} height={56}>
          <Button
            disabled={disabled}
            onClick={handleSaveClick}
          >
            Save
          </Button>
        </Loading>
      </Form.Actions>
    </Form>
  )
}

export type UserEditProps = {
  user: User | null | undefined;
  loading?: boolean;
  disabled?: boolean;
  error?: string;
  onChange?: (user: User) => void;
  onDelete?: (id: string) => void;
  onRestore?: (id: string) => void;
}

export default UserEdit;