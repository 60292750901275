export default `
# TODO
- [ ] Improve docs
  - [ ] Provide better mermaid
- [ ] Move functionality of _@narayana/freeswitch-service_ into GSM-integration service
	- [ ] Rename _gsm-service_ into _integrations-service_
- [ ] Log _CDR_'s into _History_ DB
- [ ] Choose different strategy to implement *XML* and *JSON* processing
	- [ ] (Optional) Think about splitting _JsonOrXml_ into separate Request Guard and Data Guard
	- [ ] Try to split route into two using [format](https://rocket.rs/v0.5-rc/guide/requests/#format) and [forwarding](https://rocket.rs/v0.5-rc/guide/requests/#forwarding)

`