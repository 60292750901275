import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { PasswordSignIn } from '@narayana/sso-ui';
import Dialog from '@mui/material/Dialog';
import { Router } from '../pages';
import { Layout } from '../ui';
import { useStyles } from './styles';
import { useApp } from './useApp';
import { MeContext } from '../hooks/useMe';

function App() {
  const styles = useStyles();
  const { isReady, me, error, setError, signIn } = useApp();

  if (!isReady) {
    return (
      <Backdrop open={!isReady}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (me == null) {
    return (
      <Dialog 
        open={me == null}
        fullScreen
      >
        <DialogTitle>
          Password authorization
        </DialogTitle>
        <DialogContent>
          <div className={styles.signIn}>
            <PasswordSignIn 
              error={error}
              disabled={false}
              onErrorChange={setError}
              onLogin={signIn}
            />
          </div>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <MeContext.Provider value={me}>
      <Layout>
        <Router />
      </Layout>
    </MeContext.Provider>
  )
}

export default App;
