import { Provider, ProviderUpdateRequest } from '@narayana/billing-api'
import { useCallback, useEffect } from 'react';

import { useApi, useForm, useRequest } from '../../../../../hooks';

export function useData(provider: Provider) {
  const api = useApi();

  const [
    { plan, gate, code, payload, isActive, error },
    {
      set: {
        gate: setGate,
        code: setCode,
        payload: setPayload,
        isActive: setIsActive,
        error: setError
      },
      handle: {
        plan: onPlanChange,
      }
    }
  ] = useForm({
    plan: provider.devices?.plan || '',
    gate: JSON.stringify(provider.devices?.gate || {}, null, 2),
    code: JSON.stringify(provider.devices?.code || {"main":"/* code */"}, null, 2),
    payload: JSON.stringify(provider.devices?.payload || {}, null, 2),
    isActive: (provider.devices?.isActive || null) as boolean | null,
    error: undefined as string | undefined
  });

  const { execute: deleteRestore, ...deleteRestoreRequest } = useRequest(
    async ({ id, isActive }: DeleteRestoreRequest) => {
      await api.billing.providers[(isActive ? 'deleteDevices' : 'restoreDevices')](id);
      setIsActive(!isActive);
    }
  );
  const { execute: save, ...saveRequest } = useRequest(
    async (provider: ProviderUpdateRequest) => {
      if (provider.id === '') {
        setError(`Id can't be empty`);
        return;
      }
      if (provider.devices == null) {
        setError ('No device providers to save');
        return;
      }

      await api.billing.providers.update({
        id: provider.id,
        devices: provider.devices
      });
    }
  );

  useEffect(() => setError(saveRequest.error?.message), [setError, saveRequest.error]);
  useEffect(() => setError(deleteRestoreRequest.error?.message), [setError, deleteRestoreRequest.error]);

  const onDeleteRestore = useCallback((_: unknown) => {
    setError(undefined);
    deleteRestore({ id: provider.id, isActive: isActive === true });
  }, [deleteRestore, provider, isActive, setError]);

  const onSave = useCallback((_: unknown) => {
    setError(undefined);
    let payloadObj = {};
    try {
      payloadObj = JSON.parse(payload);
    } catch (e) {
      setError('Invalid payload (expected JSON)');
      return;
    }
    setPayload(JSON.stringify(payloadObj, null, 2));

    let codeObj = {};
    try {
      codeObj = JSON.parse(code);
    } catch (e) {
      setError('Invalid code (expected JSON {"main": string, ...})');
      return;
    }
    setCode(JSON.stringify(payloadObj, null, 2));

    let gateObj = {};
    try {
      gateObj = JSON.parse(gate);
    } catch (e) {
      setError('invalid gate (expected JSON)');
      return;
    }
    setGate(JSON.stringify(gateObj, null, 2));

    save({ id: provider.id, devices: {plan, gate: gateObj, code: codeObj, payload: payloadObj}});
  }, [code, gate, payload, plan, provider.id, save, setCode, setError, setGate, setPayload]);

  return {
    error,
    plan,
    gate,
    code,
    payload,
    isActive,
    inProgress: deleteRestoreRequest.inProgress || saveRequest.inProgress,
    onPlanChange,
    setGate,
    setCode,
    setPayload,
    onDeleteRestore,
    onSave,
  }
}

export type DeleteRestoreRequest = {
  id: string,
  isActive: boolean
};

export type ProviderDevicePayload = Record<string, any>;