import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ListItemText } from "@mui/material";
import { Loading } from "@narayana/ui/target/Loading";
import React from "react";
import { Session } from "@narayana/sso-api";
import Tooltip from '@mui/material/Tooltip';

export const SessionListItem = (props: SessionListItemProps) => {
  const onClick = React.useCallback(
    () => { props.onClick(props.session) },
    [props.onClick, props.session]
  );
  
  return (
    <ListItem disabled={props.disabled}>
      <ListItemButton
        disabled={props.disabled || props.loading}
        onClick={onClick}
      >
        <ListItemIcon>
          <Loading loading={props.loading} width={40} height={40}>
            <Tooltip title={"Auth: " + props.session.auth}>
              <LaptopChromebookIcon />
            </Tooltip>
          </Loading>
        </ListItemIcon>
        <ListItemText
          primary={
            <Loading loading={props.loading} height={24} width={60}>
              {'ID: ' + props.session.id}
            </Loading>
          }
          secondary={
            <Loading loading={props.loading} height={20} width={360}>
              {props.session.start} - {props.session.due}
            </Loading>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}
export interface SessionListItemProps {
  /** 
   * session description 
   * */
  session: Session;
  disabled: boolean;
  loading: boolean;
  onClick: (session: Session) => void;
}

export default SessionListItem;