import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    display: 'flex',
    gap: 12,
    margin: `12px 0 0 0`,
    justifyContent: 'flex-end',
    height: 48
  },
  error: {
    flex: 1
  }
});