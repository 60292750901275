export default `
# Billing

Microservice for accounting for money/mbs/seconds/other, tariff plans and other stuff.

Yes, it is probably very fatty and needs to be crushed.

## Features

- custom currencies
- providers & directions managment 
- providers balance
- plans managment
- devices managment
- connections managment
`