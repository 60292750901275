import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiPublic } from "..";
import { LAMBDA_MATH_PLUS, LAMBDA_MATH_PLUS_EXECUTE, LAMBDA_MATH_PLUS_ID } from "./examples";

@ApiEntity("Lambdas", "Methods for manipulate with lambdas")
export class APILambdas {
  constructor (
    public api: ApiPublic
  ) { }

  @ApiMethod(
    "Method for create lambda",
    [ "lambda#create" ],
    LAMBDA_MATH_PLUS,
    undefined
  )
  async create (request: Lambda): Promise<void> {
    return this.api.execute(`/lambdas`, "POST", null, request);
  }

  @ApiMethod(
    "Method for update lambda",
    [ "lambda#update" ],
    LAMBDA_MATH_PLUS,
    undefined
  )
  async update (request: Lambda): Promise<void> {
    return this.api.execute(`/lambdas`, "PUT", null, request);
  }

  @ApiMethod(
    "Method for delete lambda",
    [ "lambda#delete" ],
    LAMBDA_MATH_PLUS_ID,
    undefined
  )
  async delete ({ package: packageId, id }: LambdaID): Promise<void> {
    return this.api.execute(`/lambdas/${packageId}/${id}`, "DELETE", null, null);
  }

  @ApiMethod(
    "Method for get lambda",
    [ "lambda#read" ],
    LAMBDA_MATH_PLUS_ID,
    LAMBDA_MATH_PLUS
  )
  async get ({ package: packageId, id }: LambdaID): Promise<Lambda> {
    return this.api.execute(`/lambdas/${packageId}/${id}`, "GET", null, null);
  }

  @ApiMethod(
    "Method for get lambdas count",
    [ "lambda#read" ],
    {
      q: "<query string>",
      tags: [ "public" ]
    } as LambdasCountRequest,
    { count: 5 } as Count
  )
  async count (query: LambdasCountRequest): Promise<Count> {
    return this.api.execute(`/lambdas/count`, "GET", query, null);
  }

  @ApiMethod(
    "Method for get lambdas count",
    [ "lambda#read" ],
    {
      q: "<query string>",
      tags: [ "public" ],
      limit: 10,
      offset: 0
    } as LambdasListRequest,
    { count: 5 } as Count
  )
  async list (query: LambdasListRequest): Promise<Lambda[]> {
    return this.api.execute(`/lambdas`, "GET", query, null);
  }

  @ApiMethod(
    "Method for execute lambdas",
    [ "lambda#execute" ],
    LAMBDA_MATH_PLUS_EXECUTE,
    42 as unknown
  )
  async execute <T = unknown>(query: LambdasExecuteRequest): Promise<T> {
    return this.api.execute(`/lambdas`, "PUT", query, null);
  }
}

export type LambdaID = {
  package: string,
  id: string
};

export type Lambda = LambdaID & {
  name: string,
  description: string,
  tags: string[],
  code: string,
  payload: Record<string, unknown>
};

export type LambdasCountRequest = {
  q: string,
  tags: string[]
};

export type LambdasListRequest = LambdasCountRequest & {
  limit: number,
  offset: number
};

export type Count = { 
  count: number 
};

export type LambdasExecuteRequest = LambdaID & { 
  request: unknown 
};
