import AddIcon from "@mui/icons-material/Add";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CalculateIcon from "@mui/icons-material/Calculate";
import DataArrayIcon from "@mui/icons-material/DataArray";
import DeleteIcon from "@mui/icons-material/Delete";
import FormatOverlineIcon from "@mui/icons-material/FormatOverline";
import GetAppIcon from "@mui/icons-material/GetApp";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import MoneyIcon from "@mui/icons-material/Money";
import ErrorIcon from "@mui/icons-material/Error";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RestoreIcon from "@mui/icons-material/Restore";
import UpdateIcon from "@mui/icons-material/Update";

export function MethodIcon ({ methodName }: MethodIconProps) {
  switch (methodName) {
    case 'create':
      return <AddIcon />;
    
    case 'change':
    case 'update':
      return <UpdateIcon />;

    case 'delete':
    case 'close':
      return <DeleteIcon />;

    case 'restore':
      return <RestoreIcon />;

    case 'get':
    case 'salt':
    case 'me':
    case 'hash':
      return <GetAppIcon />;

    case 'list':
      return <DataArrayIcon />;

    case 'count':
      return <CalculateIcon />;

    case 'signIn':
      return <LoginIcon />;

    case 'signOut':
      return <LogoutIcon />;

    case 'emission':
      return <MoneyIcon />;

    case 'setOverdraft':
      return <FormatOverlineIcon />;

    case 'absorption':
    case 'transfer':
      return <AttachMoneyIcon />;
      
    case '':
      return <ErrorIcon />

    default:
      return <PlayArrowIcon />
  }
}

export type MethodIconProps = {
  methodName: string
};