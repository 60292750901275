export const todo =  `
## Close future

* Read config (env) [redis;sso_host;]
* dev: docker:build
* dev: deploy
* tests
* user client
* api for other services
* 

## Unsorted/unplanned for nearest release

* message delivery status
* message read status
* manage (create, delete, rename) 'room's
* revoke messages
* clear notifications remotely
* notifications bundling (group same type messages to bundles if they was not seen)
* presense-aware notifications (deliver only to currently active and used device)
* dnd status and notifications
* specify level of details for every notifications (to switch off displaying all verbose notifications, switch off all unimportant ones)
* notification acknowledge shared on group of users (records who acked and when)
`;