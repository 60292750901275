import { Code } from "../../../../../ui";

export function DirectionsImportCode ({ code, onCodeChange }: DirectionsImportCodeProps) {
  return (
    <Code 
      value={code}
      language="javascript"
      title="Import"
      onChange={onCodeChange}
    />
  )
}

export type DirectionsImportCodeProps = {
  code: string,
  onCodeChange: (value: string) => void
};