import { Wallet } from "@narayana/billing-api";

export function WalletTabUsers({wallet, isVisible, loading}: WalletTabUsersProps){
  if (!isVisible){
    return null;
  }
  // const api = useApi()
  return (
    <div>
      Users (API is not implemented)
    </div>
  )
}

export type WalletTabUsersProps = {
  wallet: Wallet;
  isVisible: boolean;
  loading: boolean;
}