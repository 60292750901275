import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ServiceDescription } from "@narayana/api";
import { routeLink } from "../../../hooks";
import { MdComponent } from "../../../ui";

export function DevServicesList ({ services }: DevServicesListProps) {
  return (
    <div>
      <Typography variant="h3">
        Services
      </Typography>
      {Object.keys(services).map((key) => (
        <Card sx={{ marginTop: 2, marginBottom: 2 }}>
          <CardContent>
            <MdComponent md={services[key].description} />
            <CardActions sx={{ justifyContent: 'end' }}>
              <Link 
                color="primary" 
                href={routeLink({ page: `dev/services/${key}`, query: {} })}
              >
                MORE
              </Link>
            </CardActions>
          </CardContent>
        </Card>
      ))}
    </div>
  )
}

export type DevServicesListProps = {
  services: Record<string, ServiceDescription<unknown>>
};