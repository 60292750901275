import {
  BillingActs,
  BillingCurrencies,
  BillingDevices,
  BillingDirections,
  BillingPlans,
  BillingProviders,
  BillingServices
} from "../billing";
import { DevApi, DevServices, DevTools } from "../dev";
import { HistoryMain } from "../history/main";
import { NotFound } from "../NotFound";
import { PersonalGroup, PersonalGroups, PersonalSettings, PersonalWallets } from "../personal";
import { SSOUsers } from "../sso";
import { Invites } from "../invites";
import { useRouter } from "../../hooks";

export function Router() {
  const route = useRouter();

  switch (true) {
    case /^dev\/api$/.test(route.page):
      return <DevApi />

    case /^dev\/services/.test(route.page):
      return <DevServices />

    case /^dev\/tools/.test(route.page):
      return <DevTools />

    case /^sso\/users$/.test(route.page):
      return <SSOUsers />

    case /^billing\/acts$/.test(route.page):
      return <BillingActs />

    case /^billing\/currencies$/.test(route.page):
      return <BillingCurrencies />

    case /^billing\/devices$/.test(route.page):
      return <BillingDevices />

    case /^billing\/directions$/.test(route.page):
      return <BillingDirections />

    case /^billing\/plans$/.test(route.page):
      return <BillingPlans />

    case /^billing\/providers$/.test(route.page):
      return <BillingProviders />

    case /^billing\/services$/.test(route.page):
      return <BillingServices />

    case /^history$/.test(route.page):
      return <HistoryMain />

    case /^groups$/.test(route.page):
      return <PersonalGroups />

    case /^group/.test(route.page):
      return <PersonalGroup />

    case /^settings$/.test(route.page):
      return <PersonalSettings />

    case /^wallets$/.test(route.page):
      return <PersonalWallets />

    case /^invites$/.test(route.page):
      return <Invites />

    default:
      return <NotFound />
  }
}