import AddIcon from "@mui/icons-material/Add";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React from "react";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Filter, Form } from "@narayana/ui";
import { Provider, ProvidersListFilter } from "@narayana/billing-api";

import { Entity, MainAction } from "../../../ui";
import { List } from "../../../ui/List"
import { ProviderEdit } from "../../../ui/billing/providers/Edit";
import { ProviderListItem } from "../../../ui/billing/providers/ListItem";
import { useApi, useForm, useQuery, useRequest } from "../../../hooks"

export function BillingProviders() {
  const api = useApi();
  const [{ filterVisible }, actions] = useForm({ filterVisible: false });
  const [{ page, id, q, wallet, isActive }, queryActions] = useQuery({
    page: 1,
    id: null as string | null,
    q: '',
    wallet: '' as string | null,
    isActive: null as string | null
  });
  const countRequest = useRequest(
    (filter: ProvidersListFilter) => api.billing.providers.count(filter),
    { debounce: true }
  );
  const filter = React.useMemo(() => ({
    q: q || null,
    id: id || null,
    is_active: isActive == null ? null : isActive === 'true',
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE
  }), [q, id, isActive, page]);

  const countRequestExecute = countRequest.execute;
  React.useEffect(() => { countRequestExecute(filter) }, [filter, countRequestExecute]);

  const handleIsActiveChange = React.useCallback(
    (_: unknown, value: boolean) => queryActions.set.isActive(value.toString()),
    [queryActions.set]
  );

  const fetchEntities = React.useCallback(
    (filter: ProvidersListFilter) => api.billing.providers.list(filter),
    [api.billing.providers]
  );
  const handleDeleteFilterKey = React.useCallback(
    (key: string) => {
      switch (key) {  
        case 'isActive':
          queryActions.set.isActive(null);
          break;

        default:
          throw new Error(`Unhandled filter key: ${JSON.stringify(key)}`)
      }
    },
    [queryActions.set]
  );

  const handleListClick = React.useCallback(
    (provider: Provider) => queryActions.set.id(provider.id),
    [queryActions.set]
  );
  const handleIdClear = React.useCallback(
    () => queryActions.set.id(null),
    [queryActions.set]
  );
  const handleCreate = React.useCallback(
    () => queryActions.set.id(''),
    [ queryActions.set ]
  )

  return (
    <>
      <Typography variant="h4" sx={{ display: 'flex' }}>
        Providers
      </Typography>
      <Filter
        query={q}
        filterVisible={filterVisible}
        page={page}
        maxPage={Math.max(1, Math.ceil((countRequest.result?.count || 1) / PAGE_SIZE))}
        filter={{
          isActive: isActive == null ? null : (isActive === 'true' ? 'Active' : 'Inactive'),
          wallet: wallet == null || wallet === '' ? null : `Wallet:${wallet}`,
        }}
        onQueryChange={queryActions.set.q}
        onFilterVisibleChange={actions.set.filterVisible}
        onPageChange={queryActions.set.page}
        onDeleteFilterKey={handleDeleteFilterKey}
      >
        <Form>
          <TextField
            fullWidth
            label="Wallet"
            type="string"
            value={wallet}
            onChange={queryActions.handle.wallet}
          />
          <FormGroup>
            <FormControlLabel
              label="Is Active"
              control={<Switch checked={isActive === 'true'} onChange={handleIsActiveChange} />}
            />
          </FormGroup>
        </Form>
      </Filter>
      <List
        fetch={fetchEntities}
        filter={filter}
        entityKey="provider"
        Container={ProviderListItem}
        ContainerProps={{
          disabled: false,
          onClick: handleListClick,
          onDelete: (_: Provider) => { }
        }}
      />
      <Entity
        id={id}
        key={id}
        componentKey="provider"
        get={(id: string) => 
          id === '' 
            ? Promise.resolve({} as unknown as Provider)
            : api.billing.providers.get(id)
        }
        save={async (provider: Provider) => {
          await api.billing.providers.update(provider);
          handleIdClear();
        }}
        DialogProps={{
          fullScreen: true
        }}
        Component={ProviderEdit}
        ComponentProps={{ }}
        onClose={handleIdClear}
      />
      <MainAction onClick={handleCreate}>
        <AddIcon />
      </MainAction>
    </>
  )
}

const PAGE_SIZE = 10;