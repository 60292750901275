import { useApi, useQuery, useRequest } from "../../../../hooks";
import { DirectionsViewType } from ".";
import { useEffect, useMemo } from "react";
import { Direction, DirectionGroup, DirectionsListRequest } from "@narayana/billing-api";

export const useView = (props: DirectionsViewType) => {
  const api = useApi();
  const [ 
    { q },
    { 
      set: {
        q: setQ
      },
      handle: {
        q: handleQ
      }
    }
  ] = useQuery({ q: '' });
  const { execute: requestListExecute, ...requestList } = useRequest(
    (f: DirectionsListRequest) => api.billing.directions.list(f),
    { debounce: true }
  );
  const filter = useMemo(() => ({
    service: props.service,
    provider: null,
    is_active: true,
    q
  }), [ props.service, q ]);

  useEffect(
    () => requestListExecute(filter),
    [ requestListExecute, filter ]
  );

  
  const list = useMemo(
    () => {
      if (requestList.result == null) {
        return [];
      }

      const list: ListItem[] = [];
      for (const direction of requestList.result.directions) {
        list.push({ isGroup: false, direction });
      }

      for (const group of requestList.result.groups) {
        list.push({ isGroup: true, group });
      }

      list.sort((a, b) => {
        let aDirection = a.isGroup ? a.group.direction : a.direction.direction;
        let bDirection = b.isGroup ? b.group.direction : b.direction.direction;

        return aDirection === bDirection
          ? 0
          : aDirection < bDirection ? -1 : 1;
      });
      
      return list;
    },
    [ requestList.result ]
  )

  return {
    q,
    handleQ,
    setQ,
    inProgress: requestList.inProgress,
    list
  }
};

export type ListItemDirection = {
  isGroup: false,
  direction: Direction
}

export type ListItemGroup = {
  isGroup: true,
  group: DirectionGroup
}

export type ListItem = ListItemDirection | ListItemGroup;