export const HelpMd = `# Narayana markdown

## About

Powered by [GitHub markdown](https://docs.github.com/en/get-started/writing-on-github/getting-started-with-writing-and-formatting-on-github/basic-writing-and-formatting-syntax)

## Examples

### Services

~~~md
\`\`\`service:sso
\`\`\`
\`\`\`service:sso
with description
\`\`\`
\`\`\`service:another
exemple of not implemented service
\`\`\`
~~~

\`\`\`service:sso
\`\`\`
\`\`\`service:sso
with description
\`\`\`
\`\`\`service:another
exemple of not implemented service
\`\`\`

### Methods

~~~md
Default request

\`\`\`method:sso_users_get
\`\`\`

Custom request

\`\`\`method:sso_users_get
{"customRequest": 42}

Methods list

\`\`\`methods:Users/read methods
sso_users_get
sso_users_count
sso_users_list
\`\`\`
\`\`\`
~~~

Default request

\`\`\`method:sso_users_get
\`\`\`

Custom request

\`\`\`method:sso_users_get
{"customRequest": 42}
\`\`\`

Methods list

\`\`\`methods:Users/read methods
sso_users_get
sso_users_count
sso_users_list
\`\`\`

### Mermaid

~~~md
\`\`\`mermaid
erDiagram
Users {
  id VARCHAR
  status INTEGER
  isActive BOOL   
  payload JSONB  
}
Auth {
  id VARCHAR
  user VARCHAR
  type VARCHAR
  isActive BOOL
  payload JSONB
}
Auth }o--|| Users : user
...
\`\`\`
~~~

\`\`\`mermaid
erDiagram
Users {
  id VARCHAR
  status INTEGER
  isActive BOOL   
  payload JSONB  
}
Auth {
  id VARCHAR
  user VARCHAR
  type VARCHAR
  isActive BOOL
  payload JSONB
}
Auth }o--|| Users : user
Groups {
  id VARCHAR
  owner VARCHAR
  title VARCHAR
  isPersonal BOOL
  isActive BOOL
  payload JSONB
}
Groups }o--|| Users : owner
GroupUsers {
  id VARCHAR
  group VARCHAR
  user VARCHAR
  payload JSONB
}
GroupUsers }o--|| Groups : group
GroupUsers }o--|| Users : user
Permissions {
  id VARCHAR
  group VARCHAR
  entity VARCHAR
  permission VARCHAR
  payload JSONB
}
Permissions }o--|| Groups : group
Sessions {
  id VARCHAR
  secret VARCHAR
  auth VARCHAR
  start TIMESTAMP
  due TIMESTAMP
}
History {
  date TIMESTAMP
  session VARCHAR
  type VARCHAR
  payload JSONB
}
\`\`\`

Read more about [mermaid](https://github.com/mermaid-js/mermaid#readme) 

### List

\`\`\`md
* [ ] Task
* [X] Another
\`\`\`

* [ ] Task
* [X] Another

### Tables

\`\`\`md
| Tables   |      Are      |  Cool |
|----------|:-------------:|------:|
| col 1 is |  left-aligned | $1600 |
| col 2 is |    centered   |   $12 |
| col 3 is | right-aligned |    $1 |
\`\`\`

| Tables   |      Are      |  Cool |
|----------|:-------------:|------:|
| col 1 is |  left-aligned | $1600 |
| col 2 is |    centered   |   $12 |
| col 3 is | right-aligned |    $1 |

### Divider

\`\`\`md
---
\`\`\`

---`;