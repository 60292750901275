import Skeleton, { SkeletonProps } from '@mui/material/Skeleton'
import React from 'react'

export const Loading = ({ children, loading, ...props }: LoadingProps) => {
  if (!loading) {
    return <>{children}</>;
  }

  return (
    <Skeleton 
      animation="wave" 
      variant="rounded" 
      height={56}
      {...props} 
    />
  )
}

export type LoadingProps = SkeletonProps & { loading?: boolean };