import { useRouter } from "../../../hooks";
import { NotFound } from "../../NotFound";
import { DevToolsList } from "./List";
import { MdEditor } from "./MdEditor";

export function DevTools () {
  const route = useRouter();

  switch (route.page) {
    case 'dev/tools':
      return <DevToolsList />;

    case 'dev/tools/md':
      return <MdEditor />;

    default:
      return <NotFound />;
  }
}