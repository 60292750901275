import React from "react";
import { useRequest } from "../../hooks";

export function List<T, F, K extends string, C> ({ 
  fetch,
  filter,
  entityKey,
  Container,
  ContainerProps
}: ListProps<T, F, K, C>) {
  const request = useRequest(fetch, { debounce: true });

  const requestExeute = request.execute;
  React.useEffect(() => requestExeute(filter), [ requestExeute, filter ]);

  if (request.isError()) {
    return (
      <div>
        List fetch error: {JSON.stringify(request.error)}
      </div>
    )
  }

  const list = request.result || [ {} as T, {} as T, {} as T ];
  const loading = request.result == null;

  return (
    <div>
      {list.map((entity, index) => {
        const containerProps = {
          ...ContainerProps,
          loading, 
          [entityKey]: entity as T
        } as (C & Record<K, T> & { loading: boolean });

        return <Container key={index} {...containerProps} />
      })}
    </div>
  );
}

export type ListProps<T, F, K extends string, C> = {
  fetch: (filter: F) => Promise<T[]>,
  filter: F,
  entityKey: K,
  Container: React.FC<C & Record<K, T> & { loading: boolean }>,
  ContainerProps?: C
};

List.defaultProps = {
  ContainerProps: {}
}