import { Invite } from "../../ui/Invite";
import { useData } from "./useData";
import { TextField } from "@mui/material";
import { Form } from "@narayana/ui";

export const Invites = () => {
  const {
    message,
    currency,
    amount,
    error,
    inProgress,
    result,
    onMessageChange,
    onBonusCurrencyChange,
    onBonusAmountChange,
    onCreateInvite
  } = useData();
  return (
    <Form>
      <Invite
        message={message || ''}
        bonus={{currency:currency!, amount:amount!} || null}
        error={error?.message}
        inProgress={inProgress}
        onMessageChange={onMessageChange}
        onBonusCurrencyChange={onBonusCurrencyChange}
        onBonusAmountChange={onBonusAmountChange}
        onCreateInvite={onCreateInvite}
      />
    {result?<TextField multiline value={`https://${process.env["NARAYANA_DOMAIN"]}/#!/?invite="${(result as unknown as {secret:string}).secret}"`} />:null}
    </Form>
  );
}