export default `
# Public database

\`\`\`mermaid
erDiagram
Lambda {
  package VARCHAR
  id VARCHAR
  name VARCHAR
  description VARCHAR
  tags VARCHAR[]
  code VARCHAR
  payload JSONB
}
History {
  date TIMESTAMP
  session VARCHAR
  type VARCHAR
  payload JSONB
}
\`\`\`
`