import { CodeProps } from "react-markdown/lib/ast-to-react";
import { useDev } from "../../../hooks/useDev";
import { Code } from "../../Code";
import { Mermaid } from "../../Mermaid";
import { Method } from "../../Method";
import { Service } from "../../Service";
import { Methods } from "../Methods";

export function MdCode (props: CodeProps) {
  const { methods, services } = useDev();

  const match = /language-(.+)/.exec(props.className || '');
  let code = ((props.children ? props.children[0] : '')?.toString() || '')
    .split('\n');

  while (code.length > 0 && code[code.length - 1].trim() === '') {
    code.pop();
  }

  const language = match ? match[1] : '';
  const methodMatch = /^method:(.+)$/.exec(language);
  const method = (methodMatch ? methodMatch[1] : '').toLowerCase();
  const methodsMatch = /^methods:(.+)$/.exec(language);
  const methodsTitle = methodsMatch ? methodsMatch[1] : '';
  const serviceMatch = /^service:(.+)/.exec(language);
  const service = (serviceMatch ? serviceMatch[1] : '').toLowerCase();

  switch (true) {
    case method !== '': 
      return <Method method={methods[method]} request={parseRequest(code.join('\n'))} />;

    case service !== '': 
      return <Service service={services[service]} serviceId={service} description={code[0]} />;

    case methodsTitle !== '':
      return <Methods title={methodsTitle} methods={code} />

    case language === 'mermaid':
      return (
        <Mermaid>
          {code.join('\n')}
        </Mermaid>
      )

    default:
      return <Code disabled language={language} value={code.join('\n')} />;
  }
}

const parseRequest = (value: string) => {
  if (value === '') {
    return null;
  }

  try {
    return JSON.parse(value);
  } catch (e) {
    console.log('VALUE:', value)
    console.error(`MD parse method request error`, e);
    return (e as Error).message || 'Parse method request error';
  }
}