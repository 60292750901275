export default `
# Billing

Billing microservice

\`\`\`mermaid
flowchart LR
  subgraph Processes
  service("Billing API [WebApp]")
  worker("Billing Worker [Not implemented]")
  migrations("Billing Migrations")
  end
  
  subgraph DWH
  billing_db[(Billing DB - postgres)]
  history_db[(History DB - postgres)]
  billing_db-->|Logical replication|history_db
  end

  subgraph Microservices
  public_api(Public API)
  sso(SSO)
  end

  public_api-->service
  service-->billing_db
  service-->sso
  worker-->service
  migrations-->billing_db
\`\`\`

## Complex entities

### Connections

Connections are used to log connections that have passed through the system

\`\`\`methods:API
billing_connections_create
billing_connections_withdraw
billing_connections_close
\`\`\`

#### Create

When creating a connection, no write-offs occur!

#### Withdraw

The write-off takes into account the need to write off the connection fee or not and, if necessary, writes off.
Further, if it is within free seconds, then nothing is write off, otherwise, the entire time from the start 
of the connection.

### Holds

Holds are used to write-offs for services.

\`\`\`methods:API
billing_holds_create
billing_holds_withdraw
billing_holds_cancel
billing_holds_force
\`\`\`

### Wallets & Devices

It is worth noting that these entities, when created, create groups in the sso.

| Group         | Is private  | Permissions               |
|---------------|-------------|---------------------------|
| wallet:owner  | true        | wallet:id#*               |
| wallet:update | false       | wallet:id#update,read,use |
| wallet:read   | false       | wallet:id#*               |
| device:owner  | true        | device:id#*               |
| device:update | false       | device:id#update,read,use |
| device:read   | false       | device:id#*               |

It is also worth noting that you need to give access to them (and close) them 
through the appropriate billing methods, and not directly in the SSO groups.
`