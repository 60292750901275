export default `
# GSM Service

Microservice for GSM integration. Responsible in handling GSM-requests. Uses:

\`\`\`service:billing
\`\`\`


## Methods:


- GSM Process (/gsm/process). This route is a main functionality of this service at the moment.
Accepts XML payload and responds with _<funds></funds>_ that available for _subscriber_, for more info see _Concept_:
  - Request:
  - \`\`\`xml
    <?xml version="1.0" encoding="UTF-8"?>
    <getBalance>
    	<msisdn>74951200001</msisdn>
    	<tos>3</tos>
    	<callid>normal_call_id</callid>
    	<mccmnc>23420</mccmnc>
    	<currency>EUR</currency>
    </getBalance>
    \`\`\`
  - Response:
  - \`\`\`xml
    <?xml version="1.0" encoding="UTF-8"?>
    <getBalanceRes>
    	<allow>yes</allow>
    	<text>ok</text>
    	<funds>1337</funds>
    </getBalanceRes>
    \`\`\`



`