import { ApiEntity, ApiMethod } from "@narayana/api";

import { ApiBilling } from "..";

const PROVIDER: Provider = {
  id: "t-mobile",
  title: "t-mobile",
  isActive: true,
  payload: { a: 31337 },
  devices: {
    plan: 'device_cost_plan',
    gate: { "aaa": 42 },
    code: { "main": "/* code */" },
    isActive: true,
    payload: { }
  },
  services: {
    gate: { "url": "https://aaa" },
    isActive: true,
    payload: { }
  }
};

@ApiEntity("Providers", "Methods for managing providers")
export class APIProviders {
  constructor(
    public api: ApiBilling
  ) { }

  @ApiMethod(
    "Method for create provider",
    ["providers#create"],
    {
      id: "t-mobile",
      title: "t-mobile",
      ip: [ "aaaa" ],
      payload: { a: 31337 },
      devices: {
        plan: "devices",
        gate: { "aaa": 42 },
        code: { "main": "/* code */" },
        payload: { }
      },
      services: {
        gate: { "url": "https://aaa" },
        payload: { }
      }
    } as ProviderCreateRequest,
    undefined
  )
  async create<T>(request: ProviderCreateRequest): Promise<Provider<T>> {
    return this.api.execute("/providers", "POST", null, request);
  }

  @ApiMethod(
    "Method to update provider",
    ["providers#update"],
    {
      id: "t-mobile",
      provider: {
        ip: ['127.0.0.1'],
        title: "TMobile",
        payload: { a: 31337 },
      },
      devices: {
        plan: 'device_cost_plan',
        gate: { "aaa": 42 },
        code: { "main": "/* code */" },
        isActive: true,
        payload: { }
      },
      services: {
        gate: { "url": "https://aaa" },
        payload: { }
      }
    } as ProviderUpdateRequest,
    undefined
  )
  async update<T>(request: ProviderUpdateRequest<T>): Promise<void> {
    return this.api.execute("/providers", "PUT", null, request);
  }

  @ApiMethod(
    "Method for delete provider",
    ["providers#delete"],
    "<provider-id>",
    undefined
  )
  async delete(id: string): Promise<void> {
    return this.api.execute(`/providers/${id}`, "DELETE", null, null);
  }

  @ApiMethod(
    "Method for delete devices provider",
    ["providers#delete"],
    "<provider-id>",
    undefined
  )
  async deleteDevices(id: string): Promise<void> {
    return this.api.execute(`/providers/devices/${id}`, "DELETE", null, null);
  }

  @ApiMethod(
    "Method for delete services provider",
    ["providers#delete"],
    "<provider-id>",
    undefined
  )
  async deleteServices(id: string): Promise<void> {
    return this.api.execute(`/providers/services/${id}`, "DELETE", null, null);
  }

  @ApiMethod(
    "Method to restore provider",
    ["providers#restore"],
    "<provider-id>",
    undefined
  )
  async restore(id: string): Promise<void> {
    return this.api.execute(`/providers/${id}/restore`, "PUT", null, null);
  }

  @ApiMethod(
    "Method to restore devices provider",
    ["providers#restore"],
    "<provider-id>",
    undefined
  )
  async restoreDevices(id: string): Promise<void> {
    return this.api.execute(`/providers/devices/${id}/restore`, "PUT", null, null);
  }

  @ApiMethod(
    "Method to restore services provider",
    ["providers#restore"],
    "<provider-id>",
    undefined
  )
  async restoreServices(id: string): Promise<void> {
    return this.api.execute(`/providers/services/${id}/restore`, "PUT", null, null);
  }

  @ApiMethod(
    "Method to get provider",
    ["providers#get"],
    "<provider-id>",
    PROVIDER
  )
  async get(id: string): Promise<Provider> {
    return await this.api.execute(`/providers/${id}`, "GET", null, null);
  }

  @ApiMethod(
    "Method for counting number of providers by filter",
    ["providers#read"],
    {
      q: "t-mob",
      is_devices_provider: true,
      is_services_provider: true,
      is_active: true,
    } as ProvidersListFilterCount,
    { count: 42 }
  )
  async count(filter: ProvidersListFilterCount): Promise<{ count: number }> {
    return this.api.execute(`/providers/count`, "GET", filter, null);
  }

  @ApiMethod(
    "Method for get providers list",
    ["providers#read"],
    {
      q: "t-mob",
      is_active: true,
      is_devices_provider: true,
      is_services_provider: true,
      limit: 10,
      offset: 0
    } as ProvidersListFilter,
    [ PROVIDER ]
  )
  async list(filter: ProvidersListFilter): Promise<Provider[]> {
    return this.api.execute(`/providers`, "GET", filter, null);
  }
}

export type Provider<P = ProviderPayload, D = DevicesPayload, S = ServicesPayload> = {
  id: string;
  title: string;
  isActive: boolean;
  payload: P;
  ip?: string[];
  devices?: DeviceProvider<D> | null | undefined
  services?: ServicesProvider<S> | null | undefined
}

export type ProviderCreateRequest<P = ProviderPayload, D = DevicesPayload, S = ServicesPayload> = {
  id: string;
  title: string;
  payload: P;
  devices?: Omit<DeviceProvider<D>, 'isActive'> | null | undefined
  services?: Omit<ServicesProvider<S>, 'isActive'> | null | undefined
}

export type ProviderUpdateRequest<P = ProviderPayload, D = DevicesPayload, S = ServicesPayload> = {
  id: string;
  provider?: {
    title: string;
    ip: string[];
    payload: P;
  } | null | undefined,
  devices?: Omit<DeviceProvider<D>, 'isActive'> | null | undefined
  services?: Omit<ServicesProvider<S>, 'isActive'> | null | undefined
}

export type ProvidersListFilterCount = {
  q?: string | null,
  is_devices_provider?: boolean | null,
  is_services_provider?: boolean | null,
  is_active?: boolean | null,
}

export type ProvidersListFilter = ProvidersListFilterCount & {
  limit: number,
  offset: number,
};

export type DeviceProvider<T> = {
  plan: string,
  gate: Record<string, unknown>,
  code: Record<string, unknown>,
  isActive: boolean;
  payload: T;
};

export type DeviceProviderWithId<T> = DeviceProvider<T> & {
  id: string,
};

export type ServicesProvider<T> = {
  gate: Record<string, unknown>,
  isActive: boolean;
  payload: T;
};

export type ProviderPayload = Record<string, unknown>;
export type DevicesPayload = Record<string, unknown>;
export type ServicesPayload = Record<string, unknown>;