import React from "react";
import { SelectChangeEvent } from "@mui/material";

export function useForm<T>(init: T): [T, Actions<T>] {
  const [state, dispatch] = React.useReducer(
    <K extends keyof T>(state: T, action: { key: K, value: T[K] }) => ({
      ...state,
      [action.key]: action.value
    } as T),
    init
  );

  const actions = React.useMemo(() => {
    const result = { set: {}, handle: {}, tabHandle: {} } as Actions<T>;
    for (const k of Object.keys(init || {})) {
      const key = k as keyof T;
      result.set[key] = (value: T[keyof T]) => dispatch({ key, value });
      result.handle[key] = (
        e:
          React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          | SelectChangeEvent<T[keyof T]>
      ) =>
        dispatch({
          key,
          value: e.target!.value as T[keyof T]
        });
      result.tabHandle[key] = (_: unknown, value: unknown) => dispatch({ key, value: value as T[keyof T] });
    }

    return result
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  return [state, actions]
}

export type Actions<T> = {
  set: FormSetters<T>,
  handle: FormHandlers<T>,
  tabHandle: FormTabHandler<T>
}

export type FormSetters<T> = {
  [key in keyof T]: (value: T[key]) => void;
}

export type FormHandlers<T> = {
  [key in keyof T]: (
    event:
      React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
      | SelectChangeEvent<T[keyof T]>
  ) => void;
}

export type FormTabHandler<T> = {
  [key in keyof T]: (_: unknown, value: unknown) => void;
}