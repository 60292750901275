import AddIcon from "@mui/icons-material/Add";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React from "react";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import { CurrenciesListFilter, CurrencyCreateRequest } from "@narayana/billing-api";
import { Currency } from "@narayana/billing-api";
import { Filter, Form } from "@narayana/ui";

import { CurrencyEdit } from "../../../ui/billing"
import { CurrencyListItem } from "../../../ui/billing/currencies/ListItem";
import { Entity } from "../../../ui";
import { List } from "../../../ui/List"
import { MainAction } from "../../../ui";
import { useApi, useForm, useQuery, useRequest } from "../../../hooks"

export function BillingCurrencies() {
  const api = useApi();
  const [{ filterVisible }, actions] = useForm({ filterVisible: false });
  const [{ id, page, q, isActive }, queryActions] = useQuery({
    id: null as string | null,
    page: 1,
    q: '',
    isActive: null as string | null
  });
  const countRequest = useRequest(
    (filter: CurrenciesListFilter) => api.billing.currencies.count(filter),
    { debounce: true }
  );
  const filter = React.useMemo(() => ({
    id: id,
    q: q || null,
    is_active: isActive == null ? null : isActive === 'true',
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE
  }), [id, q, isActive, page]);
  const countRequestExecute = countRequest.execute;
  React.useEffect(
    () => { countRequestExecute(filter) },
    [filter, countRequestExecute]
  );
  const handleCreate = React.useCallback(
    () => queryActions.set.id(''),
    [queryActions.set]
  );
  const handleIsActiveChange = React.useCallback(
    (_: unknown, value: boolean) => queryActions.set.isActive(value.toString()),
    [queryActions.set]
  );
  const fetchEntities = React.useCallback(
    (filter: CurrenciesListFilter) => api.billing.currencies.list(filter),
    [api.billing.currencies]
  );
  const handleDeleteFilterKey = React.useCallback(
    (key: string) => {
      switch (key) {
        case 'isActive':
          queryActions.set.isActive(null);
          break;

        default:
          throw new Error(`Unhandled filter key: ${JSON.stringify(key)}`)
      }
    },
    [queryActions.set]
  );
  const handleIdClear = React.useCallback(
    () => queryActions.set.id(null),
    [queryActions.set]
  );
  const handleListClick = React.useCallback(
    (currency: Currency) => queryActions.set.id(currency.id),
    [queryActions.set]
  );
  const handleCurrencyDelete = React.useCallback(
    async (id: string) => {
      await api.billing.currencies.delete(id);
      handleIdClear();
    },
    [api.billing.currencies, handleIdClear]
  );
  const handleCurrencyRestore = React.useCallback(
    async (id: string) => {
      await api.billing.currencies.restore(id);
      handleIdClear();
    },
    [api.billing.currencies, handleIdClear]
  );
  const handleCurrencyCreate = React.useCallback(
    async (currency: CurrencyCreateRequest) => {
      const currencyObject = await api.billing.currencies.create(currency);
      queryActions.set.id(currencyObject.id)
    },
    [api.billing.currencies, queryActions.set]
  )

  return (
    <>
      <Typography variant="h4" sx={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          Currencies
        </div>
      </Typography>
      <Filter
        query={q}
        filterVisible={filterVisible}
        page={page}
        maxPage={Math.max(1, Math.ceil((countRequest.result?.count || 1) / PAGE_SIZE))}
        filter={{
          isActive: isActive == null ? null : (isActive === 'true' ? 'Active' : 'Inactive'),
        }}
        onQueryChange={queryActions.set.q}
        onFilterVisibleChange={actions.set.filterVisible}
        onPageChange={queryActions.set.page}
        onDeleteFilterKey={handleDeleteFilterKey}
      >
        <Form>
          <FormGroup>
            <FormControlLabel
              label="Is Active"
              control={<Switch checked={isActive === 'true'} onChange={handleIsActiveChange} />}
            />
          </FormGroup>
        </Form>
      </Filter>
      <List
        fetch={fetchEntities}
        filter={filter}
        entityKey="currency"
        Container={CurrencyListItem}
        ContainerProps={{
          disabled: false,
          onClick: handleListClick
        }}
      />
      <Entity
        id={id}
        key={id}
        componentKey="currency"
        get={(id: string) => id === ''
          ? Promise.resolve({} as unknown as Currency)
          : api.billing.currencies.get(id)}
        save={async (currency: Currency) => {
          await api.billing.currencies.update(currency);
          handleIdClear();
        }}
        Component={CurrencyEdit}
        ComponentProps={{
          onDelete: handleCurrencyDelete,
          onRestore: handleCurrencyRestore,
          onCreate: handleCurrencyCreate

        }}
        onClose={handleIdClear}
      />
      <MainAction onClick={handleCreate}>
        <AddIcon />
      </MainAction>
    </>
  )
}

const PAGE_SIZE = 10;