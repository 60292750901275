import { Form, Loading } from "@narayana/ui";

import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import { Group } from "@narayana/sso-api";
import React from 'react'
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';

export const GroupEdit = (props: GroupEditProps) => {
  const [group, setGroup] = React.useState(props.group);
  const onSave = React.useCallback(() => { props.onChange(group) }, [group, props.onChange]);
  const onIsActiveChanged = React.useCallback((e) => {
    setGroup(
      { ...group, isActive: e.target.checked }
    );
  }, [group.isActive]);
  const onTitleChanged = React.useCallback((e) => {
    setGroup({ ...group, title: e.target.value })
  },[group.title]);
  
  return (
    <Form>
      <Loading loading={props.loading} height={56}>
        <TextField
          id="id"
          label="ID"
          variant="outlined"
          fullWidth
          disabled
          value={group.id}
        />
      </Loading>
      <Loading loading={props.loading} height={56}>
        <TextField
          id="owner"
          label="Owner"
          variant="outlined"
          disabled
          value={group.owner}
        />
      </Loading>
      <Loading loading={props.loading} height={56}>
        <TextField
          id="title"
          label="Title"
          variant="outlined"
          disabled={group.isPersonal || props.disabled}
          value={group.title}
          onChange={onTitleChanged}
        />
      </Loading>
      <Loading loading={props.loading} height={38} width={110}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                value={group.isPersonal}
                disabled
                id="isPersonal"
              />
            }
            label="Is Personal"
          />
        </FormGroup>
      </Loading>
      <Loading loading={props.loading} height={38} width={110}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                value={group.isActive}
                disabled={group.isPersonal || props.disabled}
                id="isActive"
                onChange={onIsActiveChanged}
              />
            }
            label="Is Active"
          />
        </FormGroup>
      </Loading>
      <Form.Actions error={props.error}>
        <Loading loading={props.loading} height={38} width={130}>
          <Button
            color='primary'
            onClick={onSave}
          >
            Save
          </Button>
        </Loading>
      </Form.Actions>
    </Form>
  );
}
export type GroupEditProps = {
  group: Group;
  loading: boolean;
  disabled: boolean;
  error?: string;
  onChange: (group: Group) => void;
}

export default GroupEdit;