import { Form } from "@narayana/ui";
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import React from "react"
import { useStyles } from "./styles";

export const PasswordSignIn = ({ 
  error,
  disabled,
  className,
  onErrorChange,
  onLogin
}: PasswordSignInProps) => {
  const styles = useStyles();

  const [ { login, password, passwordVisible }, dispatch ] = React.useReducer(reducer, INIT_STATE);
  const refs = {
    login: React.useRef<HTMLInputElement | undefined>(),
    password: React.useRef<HTMLInputElement | undefined>(),
  }
  
  const handleLoginChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: 'SET_LOGIN', value: e.target.value });
      onErrorChange(null);
    },
    [ dispatch, onErrorChange ]
  );
  const handlePasswordChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch({ type: 'SET_PASSWORD', value: e.target.value });
      onErrorChange(null);
    },
    [ dispatch, onErrorChange ]
  );
  const handlePasswordVisibleToggle = React.useCallback(
    (_: unknown) => dispatch({ type: 'TOGGLE_PASSWORD_VISIBLE', value: null }),
    [ dispatch ]
  );
  const handleLogin = React.useCallback(
    (_: unknown) => {
      const formattedLogin = login.trim().toLowerCase();
      if (formattedLogin.length < 2) {
        onErrorChange({ field: 'login', message: 'Too short' })
        return;
      }

      if (password.length < 2) {
        onErrorChange({ field: 'password', message: 'Too short' })
        return;
      }
      
      onLogin(formattedLogin, password);
    },
    [ onLogin, onErrorChange, login, password ]
  );

  const field = error?.field;
  React.useEffect(() => refs[field!]?.current?.focus(), [ field ]);

  return (
    <Form className={`${styles.root} ${className ? className : ''}`}>
      <TextField 
        fullWidth
        disabled={disabled}
        label="Login"
        value={login}
        error={error?.field === 'login'}
        helperText={error?.field === 'login' ? error.message : undefined}
        inputRef={refs.login}
        onChange={handleLoginChange}
      />
      <TextField 
        fullWidth
        disabled={disabled}
        label="Password"
        value={password}
        type={passwordVisible ? undefined : "password"}
        error={error?.field === 'password'}
        helperText={error?.field === 'password' ? error.message : undefined}
        inputRef={refs.password}
        InputProps={{
          endAdornment: (
            <IconButton onClick={handlePasswordVisibleToggle}>
              {passwordVisible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          )
        }}
        onChange={handlePasswordChange}
      />
      <Form.Actions error={error && error.field == null ? error?.message : undefined}>
        <Button color="primary" disabled={disabled} onClick={handleLogin}>
          SIGN IN
        </Button>
      </Form.Actions>
    </Form>
  )
}

export type PasswordSignInProps = {
  error?: {
    field: 'login' | 'password' | null,
    message: string
  } | null | undefined,
  disabled: boolean,
  className?: string,
  onErrorChange: (error: PasswordSignInProps['error']) => void
  onLogin: (login: string, password: string) => void
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_LOGIN':
      return { ...state, login: action.value };

    case 'SET_PASSWORD':
      return { ...state, password: action.value };

    case 'TOGGLE_PASSWORD_VISIBLE':
      return { ...state, passwordVisible: !state.passwordVisible };

    default:
      throw new Error(`Unhandled action:${JSON.stringify(action)}`)
  }
}

const INIT_STATE: State = {
  login: '',
  password: '',
  passwordVisible: false
};

type State = {
  login: string,
  password: string,
  passwordVisible: boolean
}

type Action = { type: 'SET_LOGIN', value: string } 
  | { type: 'SET_PASSWORD', value: string } 
  | { type: 'TOGGLE_PASSWORD_VISIBLE', value: null };

export default PasswordSignIn;