import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Loading } from "@narayana/ui";
import { Plan } from "@narayana/billing-api";
import React from "react";

export const PlanListItem = (props: PlanListItemProps) => {
  const plan = props.plan;
  const propsOnClick = props.onClick;
  const onClick = React.useCallback(
    () => { propsOnClick(plan) },
    [plan, propsOnClick]
  );

  return (
    <ListItem disabled={props.disabled}>
      <ListItemButton
        disabled={props.disabled || props.loading}
        onClick={onClick}
      >
        <ListItemIcon>
          <Loading variant="circular" loading={props.loading} width={40} height={40}>
            <AppSettingsAltIcon />
          </Loading>
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ sx: props.plan.isActive ? {} : { textDecoration: 'line-through' } }}
          primary={
            <Loading loading={props.loading} height={24} width={280}>
              {`${props.plan.title} (${props.plan.id})`}
            </Loading>
          }
          secondary={
            <Loading loading={props.loading} height={20} width={180}>
              `Wallet: ${props.plan.wallet}`
            </Loading>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

export type PlanListItemProps = {
  plan: Plan;
  disabled: boolean;
  loading: boolean;
  onClick: (plan: Plan) => void;
  onDelete: (plan: Plan) => void;
}

