export default `
# Freeswitch

Freeswitch microservice

\`\`\`mermaid
flowchart LR
  subgraph Freeswitch
  freeswitch_0(Freeswitch 0)
  freeswitch_1(Freeswitch 1)
  freeswitch_2(Freeswitch 2)
  end
  
  subgraph Processes
  service("Freeswitch API [WebApp]")
  end
  
  subgraph Microservices
  billing(Billing)
  sso(SSO)
  end

  freeswitch_0-->service
  freeswitch_1-->service
  freeswitch_2-->service
  service-->billing
  service-->sso
\`\`\`
`