import { ApiEntity, ApiMethod } from "@narayana/api";
import { Provider } from "@narayana/billing-api";

import { ApiFreeswitch } from "..";

const PROVIDER: Provider = {
  id: "t-mobile",
  title: "t-mobile",
  isActive: true,
  payload: { a: 31337 },
  devices: {
    plan: 'device_cost_plan',
    gate: { "aaa": 42 },
    code: { "main": "/* code */" },
    isActive: true,
    payload: { }
  },
  services: {
    gate: { "url": "https://aaa" },
    isActive: true,
    payload: { }
  }
};

@ApiEntity("Providers", "Methods get providers")
export class APIProviders {
  constructor(
    public api: ApiFreeswitch
  ) { }

  @ApiMethod(
    "Method for get providers list",
    ["freeswitch#providers"],
    { },
    [ PROVIDER ]
  )
  async list(filter: { }): Promise<Provider[]> {
    return this.api.execute(`/providers`, "GET", filter, null);
  }

  @ApiMethod(
    "Method for get provider by ip",
    ["freeswitch#providers"],
    "127.0.0.1",
    PROVIDER
  )
  async getByIP(ip: string): Promise<Provider> {
    return this.api.execute(`/providers/get_by_ip/${ip}`, "GET", null, null);
  }
}
