import mermaid from 'mermaid';
import React from 'react';
import { useStyles } from './styles';

mermaid.initialize({ startOnLoad: true })

export function Mermaid (props: MermaidProps) {
  const styles = useStyles();

  React.useEffect(() => mermaid.contentLoaded(), [ ])

  return (
    <div className={`mermaid ${styles.root}`}>
      {props.children}
    </div>
  )
}

export type MermaidProps = {
  children: string
}