import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Filter } from '@narayana/ui';
import { Permission, PermissionCreateRequest } from "@narayana/sso-api";
import { PermissionListItem } from "@narayana/sso-ui";
import { PermissionsFilter } from '@narayana/sso-api';
import { useCallback, useEffect, useMemo, useState } from "react";

import { List } from "../../../../ui/List";
import { MainAction } from "../../../../ui/MainAction";
import { PermissionCreationForm } from "./PermissionCreationForm";
import { useApi, useForm, useQuery, useRequest } from "../../../../hooks";

const PAGE_SIZE = 10;

export function GroupPermissionsTab(props: GroupPermissionsTabPropsType) {
  const api = useApi();
  const [permissionCreationFormVisible, setPermissionCreationFormVisible] = useState(false);
  const [{ now }, actions] = useForm({ now: Date.now() });
  const [{ page, q, group }, queryActions] = useQuery({
    group: props.groupId,
    page: 1,
    q: '',
  });
  const countRequest = useRequest(
    (filter: PermissionsFilter) => api.sso.permissions.count(filter),
    { debounce: true }
  );
  const filter = useMemo(() => ({
    q: q || '',
    limit: PAGE_SIZE,
    group,
    offset: (page - 1) * PAGE_SIZE,
    now
  }), [q, group, page, now]);
  const countRequestExecute = countRequest.execute;
  useEffect(() => { countRequestExecute(filter) }, [filter, countRequestExecute]);
  const fetchEntities = useCallback(
    (filter: PermissionsFilter) => api.sso.permissions.list(filter),
    [api.sso.permissions]
  );
  const handleDeleteClick = useCallback(
    async (permission: Permission) => {
      !props.isPersonal && await api.sso.permissions.delete(permission.id);
      actions.set.now(Date.now());
    },
    [actions.set, api.sso.permissions, props.isPersonal]
  );
  const handleListClick = useCallback(
    (permission: Permission) => 1,//console.log(permission),
    []
  );
  const onCreateClicked = useCallback(() => {
    setPermissionCreationFormVisible(true);
  },
    []
  );
  const handleCloseCreationForm = useCallback(()=>{
    setPermissionCreationFormVisible(false);
    actions.set.now(Date.now());
  },[actions.set]);
  const handleCreate = useCallback(async (permission: PermissionCreateRequest) => {
    await api.sso.permissions.create(permission);
    actions.set.now(Date.now());
  }, [actions.set, api.sso.permissions]);

  return (
    <>
      <div>
        <Filter
          query={q || ''}
          filterVisible={false}
          page={page}
          maxPage={Math.max(1, Math.ceil((countRequest.result?.count || 1) / PAGE_SIZE))}
          filter={{

          }}
          onQueryChange={queryActions.set.q}
          onPageChange={queryActions.set.page}
        />
      </div>
      <div>
        <List
          fetch={fetchEntities}
          filter={filter}
          entityKey="permission"
          Container={PermissionListItem}
          ContainerProps={{
            disabled: props.isPersonal,
            onClick: handleListClick,
            onDelete: handleDeleteClick
          }}
        />
      </div>
      <MainAction onClick={onCreateClicked}>
        <AddIcon />
      </MainAction>
      <Dialog open={permissionCreationFormVisible} onClose={handleCloseCreationForm}>
        <DialogTitle>{"Add new permission"}</DialogTitle>
        <DialogContent>
          <PermissionCreationForm 
            onClick={handleCreate}
            onVisibleChange={setPermissionCreationFormVisible}
            group={group}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export type GroupPermissionsTabPropsType = {
  groupId: string;
  isPersonal: boolean;
}