import WorkspacesIcon from "@mui/icons-material/Workspaces";
import Avatar from "@mui/material/Avatar";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { useDev } from "../../../hooks/useDev";
import { Method } from "../../Method";

export function Methods ({ title, methods }: MethodsProps) {
  const [ open, setOpen ] = React.useState(false);
  const { methods: methodsById } = useDev();

  const handleOpenToggle = React.useCallback(
    () => setOpen(!open),
    [ open ]
  );

  return (
    <>
      <ListItemButton onClick={handleOpenToggle}>
        <ListItemAvatar>
          <Avatar>
            <WorkspacesIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={title.replace('_', ' ')}
          secondary={`${methods.length} methods`}
        />
      </ListItemButton>
      <Collapse in={open}>
        <List>
          {methods.map((method, i) => (
            <Method key={i} method={methodsById[method]} />
          ))}
        </List>
      </Collapse>
    </>
  )
}

export type MethodsProps = {
  title: string,
  methods: string[]
};