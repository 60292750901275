import { EntityDescription } from "@narayana/api";
import { DevServicesList } from "./List";
import { DevService } from "./Service";
import { useServices } from "./useServices";

export function DevServices () {
  const { serviceId, articleId, services, entities } = useServices();

  switch (serviceId) {
    case null:
    case undefined:
    case '':
      return <DevServicesList services={services} />

    default:
      return (
        <DevService 
          serviceId={serviceId} 
          articleId={articleId}
          service={services[serviceId]} 
          entities={(entities[serviceId] || []) as EntityDescription[]}
        />
      )
  }
}