import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiBilling } from "..";

@ApiEntity("Acts", "Methods for emission/set overdraft/transfer/absorption")
export class APIActs {
  constructor (
    public api: ApiBilling
  ) { }

  @ApiMethod(
    "Absorption method",
    [ "acts#absorption" ],
    {
      wallet: "wallet_id",
      currency: "currency_id",
      amount: 10,
    } as AbsorptionRequest,
    undefined
  )
  async absorption(request: AbsorptionRequest): Promise<void> {
    return this.api.execute("/acts/absorption", "POST", null, request);
  }

  @ApiMethod(
    "Emission method",
    [ "acts#emission" ],
    {
      wallet: "wallet_id",
      currency: "currency_id",
      amount: 10,
    } as EmissionRequest,
    undefined
  )
  async emission(request: EmissionRequest): Promise<void> {
    return this.api.execute("/acts/emission", "POST", null, request);
  }

  @ApiMethod(
    "Set overdraft",
    [ "acts#set-overdraft" ],
    {
      wallet: "wallet_id",
      currency: "currency_id",
      overdraft: 10,
    } as SetOverdraftRequest,
    undefined
  )
  async setOverdraft(request: SetOverdraftRequest): Promise<void> {
    return this.api.execute("/acts/set-overdraft", "POST", null, request);
  }

  @ApiMethod(
    "Transfer",
    [ "<wallet-from>#use" ],
    {
      currency: "currency_id",
      from: "wallet_id",
      to: "wallet_id",
      amount: 10,
    } as TransferRequest,
    undefined
  )
  async transfer(request: TransferRequest): Promise<void> {
    return this.api.execute("/acts/transfer", "POST", null, request);
  }
}

export type AbsorptionRequest = {
  wallet: string,
  currency: string,
  amount: number,
};

export type EmissionRequest = {
  wallet: string,
  currency: string,
  amount: number,
};

export type SetOverdraftRequest = {
  wallet: string,
  currency: string,
  overdraft: number,
};

export type TransferRequest = {
  currency: string,
  from: string,
  to: string,
  amount: number,
};