import { Service } from "@narayana/billing-api"

import { useData } from "./useData"
// import { useStyles } from "./styles"
import React from "react";
import { Form, Loading } from "@narayana/ui";
import TextField from "@mui/material/TextField";
import { Code } from "../../../Code";
import Button from "@mui/material/Button";

export const ServiceEdit = ({ service, loading, onChange }: ServiceEditProps) => {
    const {
        isNew,
        isActive,
        inProgress,
        id,
        title,
        payload,
        error,
        onIdChange,
        onTitleChange,
        onPayloadChange,
        onDeleteRestore,
        onSave,
    } = useData(service, onChange);
    //   const styles = useStyles();

    return (
        // <div className={styles.root}>
        <Form>
            Edit service
            <Loading loading={loading}>
                <TextField
                    fullWidth
                    disabled={!isNew || inProgress}
                    label="ID"
                    value={id}
                    onChange={onIdChange}
                />
            </Loading>
            <Loading loading={loading}>
                <TextField
                    fullWidth
                    disabled={inProgress}
                    label="Title"
                    value={title || ''}
                    onChange={onTitleChange}
                />
            </Loading>
            <Loading loading={loading}>
                <Code
                    language="json"
                    title="Payload"
                    value={payload || '{}'}
                    disabled={inProgress}
                    onChange={onPayloadChange}
                />
            </Loading>
            <Form.Actions error={error}>
                {isNew
                    ? <></>
                    : (
                        <Button onClick={onDeleteRestore} color="secondary">
                            {isActive ? 'delete' : 'restore'}
                        </Button>
                    )
                }
                <Button onClick={onSave} color="primary">
                    {service.id ? 'UPDATE' : 'CREATE'}
                </Button>
            </Form.Actions>
        </Form>
    )
}

export type ServiceEditProps = {
    service: Service,
    loading: boolean,
    onChange: (service: Service) => void,
};
