import {
  API, 
  ApiDescription, 
  ApiEntityConstructor, 
  ApiEntityDescription, 
  API_ENTITY_SYMBOL, 
  MethodDescription,
  ServiceDescription
} from "@narayana/api";
import { APIInstance } from "./useApi";

export function useDev () {
  return DEVAPIInstance
}

const DEVAPIInstance = (() => {
  const apiArr: API[] = [ ...Object.values(APIInstance) ];
  const desc = new ApiDescription(apiArr);

  const servicesArr = desc.getServices();
  const methodsArr = desc.methods();

  // Services
  const services = servicesArr.reduce((agg, method) => {
    agg[method.id] = method;

    return agg;
  }, {} as Record<string, ServiceDescription>);

  // Methods
  const methods = methodsArr.reduce((agg, method) => {
    agg[method.id] = method;

    return agg;
  }, {} as Record<string, MethodDescription>);
  
  // Entities
  const entities = servicesArr.reduce((agg, service) => {
    const instance = service.instance as Record<string, Object>;

    for (const entityKey of Object.keys(instance)) {
      const entity = instance[entityKey];
      const ctr = entity?.constructor as ApiEntityConstructor;
      if (typeof entity !== 'object' || ctr == null || ctr[API_ENTITY_SYMBOL] == null) {
        continue;
      }
      
      agg[service.id] = agg[service.id] || [];
      agg[service.id].push(ctr[API_ENTITY_SYMBOL]);
    }

    return agg;
  }, { } as Record<string, ApiEntityDescription[]>)

  return { services, methods, entities }
})();