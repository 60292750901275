import AddIcon from "@mui/icons-material/Add";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React from "react";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Filter, Form } from "@narayana/ui";
import { Wallet, WalletListItem, WalletPayload, WalletsListFilter } from "@narayana/billing-api";

import { Entity } from "../../../ui/Entity";
import { List } from "../../../ui/List"
import { MainAction } from "../../../ui/MainAction";
import { NewWalletForm } from "./NewWallet";
import { PersonalWallet } from "./Wallet";
import { WalletListItemComponent } from "./WalletListItem";
import { useApi, useForm, useQuery, useRequest } from "../../../hooks"

export function PersonalWallets() {
  const api = useApi();
  const [
    { filterVisible, createNewVisible },
    actions
  ] = useForm(
    { filterVisible: false, createNewVisible: false}
  );
  const [{ page, id, q, owner, isActive }, queryActions] = useQuery({
    page: 1,
    id: null as string | null,
    q: '',
    owner: null as string | null,
    isActive: null as string | null
  });
  const countRequest = useRequest(
    (filter: WalletsListFilter) => api.billing.wallets.count(filter),
    { debounce: true }
  );

  const filter = React.useMemo(() => ({
    q: q || null,
    id: id || null,
    owner: owner == null || owner === '' ? null : owner,
    is_active: isActive == null ? null : isActive === 'true',
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE
  }), [q, id, owner, isActive, page]);

  const countRequestExecute = countRequest.execute;
  React.useEffect(() => { countRequestExecute(filter) }, [filter, countRequestExecute]);

  const fetchEntities = React.useCallback(
    (filter: WalletsListFilter) => api.billing.wallets.list<WalletPayload>(filter),
    [api.billing.wallets]
  );
  const handleIdClear = React.useCallback(
    () => queryActions.set.id(null),
    [queryActions.set]
  );
  const handleListClick = React.useCallback(
    (wallet: WalletListItem) => queryActions.set.id(wallet.id),
    [queryActions.set]
  );
  const handleDeleteFilterKey = React.useCallback(
    (key: string) => {
      switch (key) {
        case 'isActive':
          queryActions.set.isActive(null);
          break;

        case 'owner':
          queryActions.set.owner(null);
          break;

        default:
          throw new Error(`Unhandled filter key: ${JSON.stringify(key)}`)
      }
    },
    [queryActions.set]
  );
  const handleCreate = React.useCallback(() =>
    actions.set.createNewVisible(true),
    [actions.set]
  );
  const handleWalletDelete = React.useCallback(
    async (id: string) => {
      await api.billing.wallets.delete(id);
      handleIdClear();
    },
    [api.billing.wallets, handleIdClear]
  );
  const handleWalletRestore = React.useCallback(
    async (id: string) => {
      await api.billing.wallets.restore(id);
      handleIdClear();
    },
    [api.billing.wallets, handleIdClear]
  );
  const handleIsActiveChange = React.useCallback(
    (_: unknown, value: boolean) => queryActions.set.isActive(value.toString()),
    [queryActions.set]
  );
  const handleNewClose = React.useCallback(
    (_: unknown) => actions.set.createNewVisible(false),
    [actions.set]
  );

  return (
    <>
      <Typography variant="h4" sx={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          Wallets
        </div>
      </Typography>
      <Filter
        query={q}
        filterVisible={filterVisible}
        page={page}
        maxPage={Math.max(1, Math.ceil((countRequest.result?.count || 1) / PAGE_SIZE))}
        filter={{
          owner: owner == null || owner === '' ? null : `Owner:${owner}`,
          isActive: isActive == null ? null : (isActive === 'true' ? 'Active' : 'Inactive'),
        }}
        onQueryChange={queryActions.set.q}
        onFilterVisibleChange={actions.set.filterVisible}
        onPageChange={queryActions.set.page}
        onDeleteFilterKey={handleDeleteFilterKey}
      >
        <Form>
          <TextField
            fullWidth
            label="Owner"
            type="string"
            value={owner}
            onChange={queryActions.handle.owner}
          />
          <FormGroup>
            <FormControlLabel
              label="Is Active"
              control={<Switch checked={isActive === 'true'} onChange={handleIsActiveChange} />}
            />
          </FormGroup>
        </Form>
      </Filter>
      <List
        fetch={fetchEntities}
        filter={filter}
        entityKey="wallet"
        Container={WalletListItemComponent}
        ContainerProps={{
          disabled: false,
          onClick: handleListClick,
        }}
      />
      <Entity
        id={id}
        componentKey="wallet"
        DialogProps={{
          fullScreen: true
        }}
        get={(id: string) => api.billing.wallets.get<WalletPayload>(id)}
        save={async (wallet: Wallet<WalletPayload>) => {
          await api.billing.wallets.update(wallet);
          handleIdClear();
        }}
        Component={PersonalWallet}
        ComponentProps={{
          onDelete: handleWalletDelete,
          onRestore: handleWalletRestore
        }}
        onClose={handleIdClear}
      />
      <NewWalletForm visible={createNewVisible} onClose={handleNewClose} />
      <MainAction onClick={handleCreate}>
        <AddIcon />
      </MainAction>
    </>
  );
}

const PAGE_SIZE = 10;