import { ApiEntity, ApiMethod } from "@narayana/api";

import { ApiBilling } from "..";
import { Currency } from "../currencies";

@ApiEntity("Wallets", "Methods for managing wallets")
export class APIWallets {
  constructor(
    public api: ApiBilling
  ) { }

  @ApiMethod(
    "Method for create wallet",
    ["wallets#create"],
    {
      title: "My wallet",
      payload: { a: 42 }
    } as WalletCreateRequest,
    {
      id: "<id>",
      title: "<wallet-id>",
      isActive: true,
      payload: { a: 42 },
      users: {
        owner: "alice",
        update: [],
        read: []
      },
      balance: []
    } as Wallet
  )
  async create<T>(request: WalletCreateRequest<T>): Promise<Wallet> {
    return this.api.execute("/wallets", "POST", null, request);
  }

  @ApiMethod(
    "Method for update wallet",
    ["wallets#update"],
    {
      id: "<wallet-id>",
      title: "My wallet",
      payload: { a: 42 }
    } as WalletUpdateRequest,
    undefined
  )
  async update<T>(request: WalletUpdateRequest<T>): Promise<void> {
    return this.api.execute("/wallets", "PUT", null, request);
  }

  @ApiMethod(
    "Method for delete wallet",
    ["wallets#delete"],
    "<wallet-id>",
    undefined
  )
  async delete(id: string): Promise<void> {
    return this.api.execute(`/wallets/${id}`, "DELETE", null, null);
  }

  @ApiMethod(
    "Method for restore wallet",
    ["wallets#restore"],
    "<wallet-id>",
    undefined
  )
  async restore(id: string): Promise<void> {
    return this.api.execute(`/wallets/${id}/restore`, "PUT", null, null);
  }

  @ApiMethod(
    "Method for get wallet",
    ["wallets#restore"],
    "<wallet-id>",
    {
      id: "<id>",
      title: "<wallet-id>",
      isActive: true,
      payload: { a: 42 },
      users: {
        owner: "alice",
        update: [],
        read: []
      },
      balance: [
        { currency: "€", balance: 10, overdraft: -10 }
      ]
    } as Wallet
  )
  async get<T>(id: string): Promise<Wallet<T>> {
    return this.api.execute(`/wallets/${id}`, "GET", null, null);
  }

  @ApiMethod(
    "Method for get wallet",
    ["wallets#read"],
    "<wallet-id>",
    { count: 10 }
  )
  async count(filter: WalletsListFilterCount): Promise<{ count: number }> {
    return this.api.execute(`/wallets/count`, "GET", filter, null);
  }

  @ApiMethod(
    "Method for list wallet",
    ["wallets#read"],
    {
      q: "",
      isActive: true,
      limit: 10,
      offset: 0
    },
    {
      id: "<id>",
      title: "<wallet-id>",
      isActive: true,
      payload: { a: 42 }
    } as WalletListItem
  )
  async list<T = WalletPayload>(filter: WalletsListFilter): Promise<WalletListItem<T>[]> {
    return this.api.execute(`/wallets`, "GET", filter, null);
  }
}

export type WalletListItem<T = WalletPayload> = {
  id: string;
  title: string;
  isActive: boolean;
  payload: T;
}

export type Wallet<T = WalletPayload> = WalletListItem<T> & {
  users: {
    owner: string,
    update: [],
    read: []
  },
  balance: {
    currency: string,
    balance: number,
    overdraft: number,
  }[]
}

export type WalletBalance = Currency & { balance: number };

export type WalletCreateRequest<T = WalletPayload> = {
  title: string;
  payload: T;
}

export type WalletUpdateRequest<T = WalletPayload> = {
  id: string,
  title: string;
  payload: T,
}

export type WalletsListFilterCount = {
  q?: string | null,
  is_active?: boolean | null,
}

export type WalletsListFilter = WalletsListFilterCount & {
  limit: number,
  offset: number,
};

export type WalletPayload = Record<string, unknown>;