import {indentWithTab} from "@codemirror/commands"
import {EditorState, Compartment} from "@codemirror/state"
import {languages} from "@codemirror/language-data"
import {javascript} from "@codemirror/lang-javascript"
import {json} from "@codemirror/lang-json"
import {markdown} from "@codemirror/lang-markdown"
import {keymap, ViewPlugin, ViewUpdate} from "@codemirror/view"
import CodeIcon from "@mui/icons-material/Code"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import {EditorView, basicSetup} from "codemirror"
import React from "react";
import { useStyles } from "./styles";
import Button from "@mui/material/Button";

export function Code (props: CodeProps) {
  const styles = useStyles();
  const editorDivRef = React.useRef<HTMLDivElement | null>(null);
  const viewRef = React.useRef<EditorView | null>(null);
  const onChangeRef = React.useRef(props.onChange);
  
  React.useEffect(() => {
    if (editorDivRef.current == null) {
      return;
    }

    const languageConf = new Compartment();
    const state = EditorState.create({
      doc: props.value,
      extensions: [
        ViewPlugin.fromClass(class {
          update(update: ViewUpdate) {
            if (!update.docChanged || typeof onChangeRef.current !== 'function') {
              return
            }

            onChangeRef.current(update.state.doc.toString())
          }
        }),
        basicSetup,
        keymap.of([indentWithTab]),
        languageConf.of(getLanguage(props.language)),
        new Compartment().of(EditorState.tabSize.of(2)),
        new Compartment().of(EditorState.readOnly.of(Boolean(props.disabled))),
      ],
    });
    const editorView = new EditorView({ state, parent: editorDivRef.current });

    viewRef.current = editorView;
    return () => viewRef.current?.destroy()
  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [ editorDivRef, props.language, props.disabled ]);

  const handleCopyClick = React.useCallback(
    () => {
      const textarea = document.createElement("textarea");

      try {
        textarea.setAttribute('style', 'maxWidth:0;maxHeight:0;overfow:hidden;opacity:0');
        textarea.value = props.value;

        document.body.appendChild(textarea);

        textarea.select();
        textarea.setSelectionRange(0, props.value.length);
        navigator.clipboard.writeText(textarea.value);
      } catch (e) {
        alert('Copy error');
        console.error(e);
      } finally {
        document.body.removeChild(textarea);
      }
    },
    [ props.value ]
  );

  React.useEffect(
    () => {
      const view = viewRef.current;
      const doc = view?.state.doc.toString();
      if (view == null || props.value === doc) {
        return;
      }

      view.dispatch(view.state.update({
        changes: { from: 0, to: doc!.length, insert: props.value }
      }));
    },
    [ props.value ]
  );

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <CodeIcon />
        <div className={styles.headerText}>
          {props.title || props.language}
        </div>
        <Button size="small" color="inherit" onClick={handleCopyClick}>
          <ContentCopyIcon />
        </Button>
      </div>
      <div ref={editorDivRef} className={styles.editor} />
    </div>
  );
}

export type CodeProps = {
  disabled?: boolean,
  language: string,
  value: string,
  title?: string,
  onChange?: (value: string) => void
};

const getLanguage = (lang: string) => {
  switch(lang) {
    case 'json':
      return json();

    case 'javascript':
      return javascript();

    case 'markdown':
      return markdown({ codeLanguages: languages });

    default:
      return json();
  }
}