import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiPhones } from "..";

@ApiEntity("Ranges", "Methods for manipulate with numbers by ranges")
export class APIRanges {
  constructor (
    public api: ApiPhones
  ) { }

  @ApiMethod(
    "Method for create all numbers in range",
    [ "phones#create" ],
    {
      range: "127373____",
      space: "esim",
      payload: { }
    } as RangesCreateRequest,
    undefined
  )
  async create (request: RangesCreateRequest): Promise<void> {
    return this.api.execute(`/ranges`, "POST", null, request);
  }

  @ApiMethod(
    "Method for update all numbers in range",
    [ "phones#update" ],
    {
      range: "127373____",
      payload: { }
    } as RangesUpdateRequest,
    undefined
  )
  async update (request: RangesUpdateRequest): Promise<void> {
    return this.api.execute(`/ranges`, "POST", null, request);
  }

  @ApiMethod(
    "Method for delete all numbers in range",
    [ "phones#delete" ],
    {
      range: "127373____",
    } as RangesDeleteRequest,
    undefined
  )
  async delete (request: RangesUpdateRequest): Promise<void> {
    return this.api.execute(`/ranges`, "POST", null, request);
  }
}

export type RangesCreateRequest<T = RangePaylod> = {
  range: string;
  space: string;
  payload: T
};

export type RangesUpdateRequest<T = RangePaylod> = {
  range: string;
  space: string;
  payload: T
};

export type RangesDeleteRequest = {
  range: string;
};

export type RangePaylod = Record<string, unknown>;