import React from "react";
import { useRouter } from "../../../hooks";
import { useDev } from "../../../hooks/useDev";

export function useServices () {
  const { services, entities } = useDev();
  const route = useRouter();

  const pageParts = route.page.split('/');
  const serviceId = pageParts[2] || '';
  const articleId = pageParts[3] || '';
  
  return React.useMemo(
    () => ({
      serviceId,
      articleId,
      services,
      entities
    }), 
    [ serviceId, articleId, services, entities ]
  );
}