export default `
# Phones

## Services

We can use 32 services because services are integer (4 bytes).

| Bit  |   Service   |
|------|-------------|
| 0    | sip/voice   |
| 1    | sip/sms     |
| 2-31 | not used    |
`