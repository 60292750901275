import { API, ApiService, Article } from '@narayana/api'

import docs from './__docs'
import { APICallsIn } from './callsIn'
import { APICallsOut } from './callsOut'
import { APIDevices } from './devices'
import { APIProviders } from './providers'

export * from './callsIn';
export * from './callsOut';
export * from './devices';
export * from './providers';

@Article("concept", "Concept", docs.concept)
@Article("todo", "ToDo", docs.todo)
@ApiService("Freeswitch", docs.readme)
export class ApiFreeswitch extends API {
  public callsIn = new APICallsIn(this);
  public callsOut = new APICallsOut(this);
  public devices = new APIDevices(this);
  public providers = new APIProviders(this);

  constructor (prefix: string) {
    super(prefix);
  }
}