import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiFreeswitch } from "..";
import { 
  CALLS_CLOSE_REQUEST,
  CALLS_CREATE_CREATE_REQUEST, 
  CALLS_CREATE_CREATE_RESPONSE, 
  CALLS_WITHDRAW_REQUEST 
} from "../calls/examples";
import { 
  APICalls, 
  CallsCloseRequest, 
  CallsCreateRequest, 
  CallsCreateResponse,
  CallsWithdrawRequest
} from "../calls";

@ApiEntity("CallsOut", "Methods for managing connections")
export class APICallsOut {
  private calls = new APICalls(this.api, "/calls/out");

  constructor (
    public api: ApiFreeswitch
  ) { }

  @ApiMethod(
    "Method for create connection",
    [ "freeswitch#calls" ],
    CALLS_CREATE_CREATE_REQUEST,
    CALLS_CREATE_CREATE_RESPONSE
  )
  async create (request: CallsOutCreateRequest): Promise<CallsOutCreateResponse> {
    return this.calls.create(request);
  }

  @ApiMethod(
    "Method for withdraw balance",
    [ "freeswitch#calls" ],
    CALLS_WITHDRAW_REQUEST,
    undefined
  )
  async withdraw (request: CallsOutWithdrawRequest): Promise<void> {
    return this.api.execute("/calls/out/withdraw", "PUT", null, request);
  }

  @ApiMethod(
    "Method for close connection",
    [ "freeswitch#calls" ],
    CALLS_CLOSE_REQUEST,
    undefined
  )
  async close (request: CallsOutCloseRequest): Promise<void> {
    return this.api.execute(`/calls/out`, "DELETE", null, request);
  }
}

export type CallsOutCreateRequest = CallsCreateRequest;
export type CallsOutCreateResponse = CallsCreateResponse;
export type CallsOutWithdrawRequest = CallsWithdrawRequest;
export type CallsOutCloseRequest = CallsCloseRequest;