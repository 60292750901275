export const concept = `# Concept
Notification center is planned as service to deliver notifications to users.
NC uses load balancer to spread the workload.
SSO is used to lookup users to deliver message to.

## Methods
* sendMessage(user:string, type:string, message:string)

# Dependency diagram
\`\`\`mermaid
flowchart LR
  subgraph Processes
  socket_service_0("Socket instance 2 (socket.io app)")
  socket_service_1("Socket instance 1 (socket.io app)")
  socket_service_2("Socket instance 0 (socket.io app)")
  reverse_proxy("Reverse proxy (nginx)")

  reverse_proxy-->socket_service_0
  reverse_proxy-->socket_service_1
  reverse_proxy-->socket_service_2
  end
  
  subgraph DWH
  socket_db[(Socket DB - redis)]
  end

  subgraph Microservices
  billing(Billing)
  sso(SSO)
  end

  subgraph Users
  broser(Browser)
  mobile_app(Mobile App)
  etc(ETC)
  end

  broser---|Web socket|reverse_proxy
  mobile_app---|Web socket|reverse_proxy
  etc---|Web socket|reverse_proxy
  billing-->|Notifications|reverse_proxy
  sso-->|Notifications|reverse_proxy
  socket_service_0-->socket_db
  socket_service_1-->socket_db
  socket_service_2-->socket_db
  socket_service_0-->sso
  socket_service_1-->sso
  socket_service_2-->sso
  \`\`\`

  ## Workflow

* On socket creation:
  1. user cookie is sent to SSO to auth
  2. socket connection via socket.io subscribes to room "user:<userId>"
  3. socket.io manages reconnection and message delivery automatically
* On sending notification:
  1. any service can send notification to user (service should have permissions nc#send)
  2. message is delivered to user private room; user receives notification as soon as he able to
`;