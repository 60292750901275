import { WalletCreateRequest } from "@narayana/billing-api";
import { useCallback } from "react";

import { useApi, useForm, useGoto, useRequest } from "../../../../hooks";

export function useData({ onClose }: useDataProps) {
  const api = useApi();
  const { goto } = useGoto();
  const [
    { title },
    {
      handle: { title: handleTitleChange }
    }
  ] = useForm({ title: '', loading: false });
  const { execute: createWallet, ...createWalletRequest } = useRequest(
    async (wallet: WalletCreateRequest) => {
      const result = await api.billing.wallets.create(wallet);
      onClose(null);
      goto({ page: `wallets/`, query: { id: result.id } });
    },
    { debounce: true }
  );
  const handleWalletCreate = useCallback(
    (_: unknown) => createWallet({ title, payload: {} }),
    [createWallet, title]
  );

  return {
    title,
    disabled: createWalletRequest.inProgress,
    error: createWalletRequest.error?.message || '',
    handleWalletCreate,
    handleTitleChange
  }
}

export type useDataProps = {
  onClose: (_: unknown) => void;
}