import Alert from "@mui/material/Alert";
import Link from "@mui/material/Link";
import { ArticleDescription } from '@narayana/api';
import { routeLink } from "../../../hooks";
import { MdComponent } from "../../../ui";

export function Article ({ article }: ArticleProps) {
  if (article == null) {
    return (
      <div>
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          Article not found
        </Alert>
        <Link href={routeLink({ page: "dev/services", query: { } })}>
          BACK TO SERVICES
        </Link>
      </div>
    )
  }

  return <MdComponent md={article.article} />
}

export type ArticleProps = {
  article: ArticleDescription | undefined
};