import Typography from "@mui/material/Typography";
import { ActsEmission, ActsOverdraft, ActsTransfer, ActsAbsorption } from "../../../ui/billing";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useCallback } from "react";
import { useData } from "./useData";

export function BillingActs() {
  const {
    tab,
    setTab,
    inProgress,
    executeEmission,
    executeOverdraft,
    executeTransfer,
    executeAbsorption,
    error,
    emissionRequestStarted,
    overdraftRequestStarted,
    transferRequestStarted,
    absorptionRequestStarted,
  } = useData();
  const onTabChange = useCallback((_: unknown, value: string) => {
    setTab(value);
  }, [setTab]);

  return (
    <>
      <Typography variant="h4" sx={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          Acts
        </div>
      </Typography>
      {/* <div className={styles.root}> */}
      <div>
        <Tabs value={tab} onChange={onTabChange}>
          <Tab label="Emission" value="emission" />
          <Tab label="Overdraft" value="overdraft" />
          <Tab label="Transfer" value="transfer" />
          <Tab label="Absorption" value="absorption" />
        </Tabs>
        <div>
          {tab === "emission"
            ? <ActsEmission
              inProgress={inProgress}
              execute={executeEmission}
              error={error || ''}
              emissionRequestStarted={emissionRequestStarted}
            />
            : null
          }
          {tab === "overdraft"
            ? <ActsOverdraft
              inProgress={inProgress}
              execute={executeOverdraft}
              error={error || ''}
              setOverdraftRequestStarted={overdraftRequestStarted}
            />
            : null
          }
          {tab === "transfer"
            ? <ActsTransfer
              inProgress={inProgress}
              execute={executeTransfer}
              error={error || ''}
              transferRequestStarted={transferRequestStarted}
            />
            : null
          }
          {tab === "absorption"
            ? <ActsAbsorption
              inProgress={inProgress}
              execute={executeAbsorption}
              error={error || ''}
              absorptionRequestStarted={absorptionRequestStarted}
            />
            : null
          }
        </div>
      </div>
    </>
  );
}