import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { useData } from "./useData";

export function NewWalletForm({ visible, onClose }: newWalletFormProps) {
  const { title, disabled, error, handleTitleChange, handleWalletCreate } = useData({onClose});

  return (
    <Dialog open={visible} onClose={onClose} >
      <DialogContent>
        <TextField
          label="Wallet title"
          value={title}
          disabled={disabled}
          fullWidth
          onChange={handleTitleChange}
        />
        <div>
          {error}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleWalletCreate} disabled={disabled}>
          Create Wallet
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export type newWalletFormProps = {
  visible: boolean;
  onClose: (_: unknown) => void;
}