import { CallsCloseRequest, CallsCreateRequest, CallsCreateResponse, CallsWithdrawRequest } from ".";

export const CALLS_CREATE_CREATE_REQUEST: CallsCreateRequest = {
  device: "divice_id_without_prefix_freeswitch",
  direction: "180073728",
  isInternal: false
};


export const CALLS_CREATE_CREATE_RESPONSE: CallsCreateResponse = {
  id: "connection_id",
  secret: "connection_secret",
  device: {
    id: "id_without_prefix_freeswitch",
    ip: [ "8.8.8.8" ],
    wallet: "wallet_id",
    gateway: [ { id: "a", payload: { } } ],
    provider: {
      id: "provider_id",
      plan: "devices",
      gate: { },
      code: { },
      isActive: true,
      payload: { }
    },
    type: "freeswitch",
    isActive: true,
    payload: {
      "any": "value"
    }
  },
  services: [
    {
      id: "plan_service_id",
      provider: "provider_id",
      payload: {
        "planService": "payload"
      }
    }
  ]
};

export const CALLS_CLOSE_REQUEST: CallsCloseRequest = {
  secret: "connection_secret",
  reason: "close_reason"
};

export const CALLS_WITHDRAW_REQUEST: CallsWithdrawRequest = {
  secret: "connection_secret",
  elapsedTime: 20,
  planService: "plan_service_id",
  dueInterval: 10
};