import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Currency } from "@narayana/billing-api";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Loading } from "@narayana/ui";
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import React from "react";
import TollIcon from '@mui/icons-material/Toll';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

export const CurrencyListItem = (props: CurrencyListItemProps) => {
  const currency = props.currency;
  const propsOnClick = props.onClick;
  const onClick = React.useCallback(
    () => { propsOnClick(currency) },
    [currency, propsOnClick]
  );

  return (
    <ListItem disabled={props.disabled}>
      <ListItemButton
        disabled={props.disabled || props.loading}
        onClick={onClick}
      >
        <ListItemIcon>
          <Loading variant="circular" loading={props.loading} width={40} height={40}>
            {props.currency.id === '€'
              ? <TollIcon />
              : (props.currency.isActive) ? <AttachMoneyIcon /> : <MoneyOffIcon />
            }
          </Loading>
        </ListItemIcon>
        <ListItemText
          primary={
            <Loading loading={props.loading} height={20} width={180}>
              <Tooltip title={props.currency.isActive ? "" : "Currency is deleted"}>
                <Typography
                  sx={props.currency.isActive ? {} : { textDecoration: 'line-through' }}
                >
                  {props.currency.title}
                </Typography>
              </Tooltip>
            </Loading>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

export type CurrencyListItemProps = {
  currency: Currency;
  disabled: boolean;
  loading: boolean;
  onClick: (currency: Currency) => void;
}

