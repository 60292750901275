import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiSMS } from "..";

@ApiEntity("Devices", "Methods for managing devices for SMS")
export class APIDevices {
  constructor(public api: ApiSMS) {}

  @ApiMethod(
    "Method for getting devices which support SMS feature",
    ["personal#*", "personal#sms"],
    null,
    [
      {
        id: "73142",
        title: 'My home number'
      }
    ] as SMSDevice[]
  )
  async list(): Promise<SMSDevice[]> {
    return await this.api.execute(`/devices`, "GET", null, null);
  }
}
export type SMSDevice = {
  id: string,
  title: string
}