import { API, ApiService, Article } from '@narayana/api'

import docs from './__docs'
import { APIActs } from './acts'
import { APIConnections } from './connections'
import { APICurrencies } from './currencies'
import { APIDevices } from './devices'
import { APIDirections } from './directions'
import { APIPlans } from './plans'
import { APIProviders } from './providers'
import { APIServices } from './services'
import { APIWallets } from './wallets'
import { APIWithdraws } from './withdraws'

export * from './acts'
export * from './connections'
export * from './currencies'
export * from './devices'
export * from './directions'
export * from './plans'
export * from './services'
export * from './wallets'
export * from './providers'
export * from './withdraws'

@Article("concept", "Concept", docs.concept)
@Article("database", "Database", docs.database)
@Article("todo", "ToDo", docs.todo)
@Article("withdraws", "Withdraws", docs.withdraws)
@ApiService("Billing", docs.readme)
export class ApiBilling extends API {
  public acts = new APIActs(this);
  public connections = new APIConnections(this);
  public currencies = new APICurrencies(this);
  public devices = new APIDevices(this);
  public directions = new APIDirections(this);
  public plans = new APIPlans(this);
  public services = new APIServices(this);
  public wallets = new APIWallets(this);
  public providers = new APIProviders(this);
  public withdraws = new APIWithdraws(this);

  constructor (prefix: string) {
    super(prefix);
  }
}