import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { MethodDescription } from "@narayana/api";
import { Form } from "@narayana/ui";
import React from "react";
import { useForm } from "../../hooks";
import { Code } from "../Code";

export function MethodDialog ({ method, request: requestProps, open, onClose }: MethodDialogProps) {
  const [ { request, response, error }, actions ] = useForm({ 
    request: JSON.stringify(
      requestProps == null ? method?.request : requestProps, 
      null, 
      2
    ),
    response: JSON.stringify(method?.response || null, null, 2),
    error: null as string | null
  });
  const handleExecute = React.useCallback(
    () => {
      if (method == null) {
        actions.set.error('Method not found')
        return;
      }

      actions.set.error(null);

      try {
        const requestObj = JSON.parse(request);
        const fn = (method.entity.instance as Record<string, (a: unknown) => Promise<unknown>>)[method.name];

        if (typeof fn !== 'function') {
          actions.set.error('Method not function')
          return;
        }

        Promise.resolve(fn.bind(method.entity.instance, requestObj)())
          .then((result) => {
            actions.set.response(JSON.stringify(result, null, 2))
          })
          .catch((e: Error) => {
            actions.set.error(e?.message || '')
          });
      } catch (e) {
        actions.set.error((e as Error)?.message || '');
      }
    },
    [ method, request, actions.set ]
  )

  const setRequest = actions.set.request;
  const setError = actions.set.error;
  const handleClose = React.useCallback(
    () => { 
      setRequest(
        JSON.stringify(
          requestProps == null ? method?.request : requestProps, 
          null, 
          2
        )
      );
      setError(null);
      onClose();
    },
    [ onClose, setRequest, setError, requestProps, method?.request ]
  )

  if (method == null) {
    return null;
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>
        {method.id.replaceAll("_", ".")}
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          {method.description}
        </Typography>
        <Form>
          <Code 
            language="json"
            title={"Request"} 
            value={request} 
            onChange={actions.set.request}
          />
          <Code 
            disabled
            language="json"
            title={`Response`} 
            value={error ? error : response} 
          />
        </Form>
      </DialogContent>
      <DialogActions>
        <Form.Actions error={error || ''}>
          <Button onClick={handleExecute}>
            EXECUTE
          </Button>
        </Form.Actions>
      </DialogActions>
    </Dialog>
  )
}

export type MethodDialogProps = {
  method: MethodDescription | null,
  request?: unknown | null,
  open: boolean,
  onClose: () => void
};