import { Lambda, LambdaID, LambdasExecuteRequest } from ".";

export const LAMBDA_MATH_PLUS_ID: LambdaID = {
  package: "math",
  id: "plus",
};

export const LAMBDA_MATH_PLUS: Lambda = {
  package: "math",
  id: "plus",
  name: "Plus",
  description: "r.a + r.b",
  tags: [ "public" ],
  code: `fn execute(request) { request.a + request.b }`,
  payload: { }
};

export const LAMBDA_MATH_PLUS_EXECUTE: LambdasExecuteRequest = {
  package: "math",
  id: "plus",
  request: { 
    a: 4, 
    b: 2 
  }
};