import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiBilling, DeviceProviderWithId } from "..";

@ApiEntity("Devices", "Methods for managing devices")
export class APIDevices {
  constructor (
    public api: ApiBilling
  ) { }

  @ApiMethod(
    "Method for create device",
    [ "devices#create" ],
    {
      id: "alice",
      provider: "device_provider_id",
      ip: [ ],
      wallet: "wallet-id",
      type: "freeswitch",
      payload: { a: 42 }
    } as DeviceCreateRequest,
    undefined
  )
  async create<T> (request: DeviceCreateRequest<T>): Promise<undefined> {
    return this.api.execute("/devices", "POST", null, request);
  }

  @ApiMethod(
    "Method for update device",
    [ "devices#update" ],
    {
      id: "id",
      ip: [ ],
      wallet: "wallet-id",
      type: "freeswitch",
      payload: { a: 42 }
    } as DeviceUpdateRequest,
    undefined
  )
  async update (request: DeviceUpdateRequest): Promise<void> {
    return this.api.execute("/devices", "PUT", null, request);
  }

  @ApiMethod(
    "Method for delete device",
    [ "devices#delete" ],
    "id",
    undefined
  )
  async delete (id: string): Promise<void> {
    return this.api.execute(`/devices/${id}`, "DELETE", null, null);
  }

  @ApiMethod(
    "Method for restore device",
    [ "devices#restore" ],
    "id",
    undefined
  )
  async restore (id: string): Promise<void> {
    return this.api.execute(`/devices/${id}/restore`, "PUT", null, null);
  }

  @ApiMethod(
    "Method for get device",
    [ "devices#read" ],
    "id",
    {
      id: "id",
      wallet: "wallet-id",
      gateway: [ { id: "aaa" } ],
      ip: ["ip"],
      type: 'freeswitch',
      provider: {
        id: 'provider_id',
        plan: 'main',
        code: { main: '/* code */' },
        isActive: true,
        gate: {
          "gate-props": { }
        },
        payload: {
          "a": 1
        }
      },
      isActive: true,
      payload: { a: 42 }
    } as Device
  )
  async get (id: string): Promise<Device> {
    return this.api.execute(`/devices/${id}`, "GET", null, null);
  }

  @ApiMethod(
    "Method for counting number of devicies by filter",
    [ "devices#read" ],
    {
      wallet: "wlt",
      ip: "ip",
      device_type: "freeswitch",
      is_active: true,
    },
    { count: 42 }
  )
  async count (filter: DevicesListFilterCount): Promise<{ count: number }> {
    return this.api.execute(`/devices/count`, "GET", filter, null);
  }

  @ApiMethod(
    "Method for get devicies list",
    [ "devices#read" ],
    {
      wallet: "wlt",
      device_type: "freeswitch",
      ip: ['ip'],
      is_active: true,
      limit: 10,
      offset: 0
    },
    [
      {
        id: "id",
        wallet: "wallet-id",
        gateway: [ { id: "aaa" } ],
        ip: ['ip'],
        provider: {
          id: 'provider_id',
          plan: 'main',
          code: { main: '/* code */' },
          gate: {
            "gate-props": { }
          },
          isActive: true,
          payload: {
            "a": 1
          }
        },
        type: "freeswitch",
        isActive: true,
        payload: { a: 42 }
      }
    ] as Device[]
  )
  async list (filter: DevicesListFilter): Promise<Device[]> {
    return this.api.execute(`/devices`, "GET", filter, null);
  }

  @ApiMethod(
    "Method for register devices gateway",
    [ "devices#gateway" ],
    {
      id: "uinque_connection_id",
      device: "freeswitch:7495120000",
      payload: { type: "freeswitch", uri: "", etc: "other or any" }
    } as DevicesGatewayRegisterRequest,
    undefined
  )
  async gatewayRegister (request: DevicesGatewayRegisterRequest): Promise<void> {
    return this.api.execute(`/devices/gateway`, "POST", null, request);
  }

  @ApiMethod(
    "Method for unregister devices gateway",
    [ "devices#gateway" ],
    {
      device: "device_id",
      id: "uinque_connection_id"
    } as DevicesGatewayUnregisterRequest,
    undefined
  )
  async gatewayUnregister (request: DevicesGatewayUnregisterRequest): Promise<void> {
    return this.api.execute(`/devices/gateway/delete`, "DELETE", null, request);
  }

  @ApiMethod(
    "Method for get service balance",
    [ "devices#balance" ],
    {
      device: "device_id",
      service: "service_id",
      direction: "1800"
    } as DevicesServiceBalanceRequest,
    {
      amount: 42
    } as DevicesServiceBalanceResponse
  )
  async serviceBalance ({ device, service, direction }: DevicesServiceBalanceRequest): Promise<DevicesServiceBalanceResponse> {
    return this.api.execute(`/devices/${device}/service-balance`, "GET", { service, direction }, null);
  }
}

export type Device<T = DevicePayload, D = DevicePayload, G = DevicePayload> = {
  id: string;
  wallet: string;
  provider: DeviceProviderWithId<D>;
  gateway: G[];
  ip: string[],
  type: string;
  isActive: boolean;
  payload: T;
}

export type DeviceCreateRequest<T = DevicePayload> = {
  id: string;
  ip: string[];
  wallet: string;
  provider: string;
  type: string;
  payload: T;
}

export type DeviceUpdateRequest<T = DevicePayload> = {
  id: string;
  ip: string[];
  wallet: string;
  type: string;
  payload: T;
}

export type DevicesListFilterCount = {
  wallet?: string | null,
  ip?: string | null,
  device_provider?: string | null,
  device_type?: string | null,
  is_active?: boolean | null,
}

export type DevicesListFilter = DevicesListFilterCount & {
  limit: number,
  offset: number,
};

export type DevicesGatewayRegisterRequest = {
  id: string,
  device: string,
  payload: Record<string, unknown>
};

export type DevicesGatewayUnregisterRequest = {
  id: string,
  device: string,
};

export type DevicesServiceBalanceRequest = {
  device: string,
  service: string,
  direction: string,
};

export type DevicesServiceBalanceResponse = {
  amount: number
};

export type DevicePayload = Record<string, unknown>;