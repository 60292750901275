import { useApi, useForm, useQuery, useRequest } from "../../../../../hooks"

import { Filter } from "@narayana/ui";
import { List } from "../../../../../ui/List"
import React from "react";
import { SessionListItem } from "@narayana/sso-ui";
import { SessionsFilter } from "@narayana/sso-api";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

export function Sessions() {
  const api = useApi();
  const [{ filterVisible }, actions] = useForm({ filterVisible: false });
  const [{ page, auth }, queryActions] = useQuery({ page: 1, auth: '' });
  const countRequest = useRequest((filter: SessionsFilter) => api.sso.sessions.count(filter));
  const filter = React.useMemo(() => ({
    auth: auth || null,
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE
  }), [auth, page]);

  const countRequestExecute = countRequest.execute;
  React.useEffect(() => { countRequestExecute(filter) }, [filter, countRequestExecute]);

  const fetchEntities = React.useCallback(
    (filter: SessionsFilter) => api.sso.sessions.list(filter),
    [api.sso.sessions]
  );
  const handleDeleteFilterKey = React.useCallback(
    (key: string) => {
      switch (key) {
        case 'auth':
          queryActions.set.auth('');
          break;

        default:
          throw new Error(`Unhandled filter key: ${JSON.stringify(key)}`)
      }
    },
    [queryActions.set]
  );

  return (
    <>
      <Typography variant="h4">
        Sessions
      </Typography>
      <Filter
        withoutInput
        filterVisible={filterVisible}
        page={page}
        maxPage={Math.max(1, Math.ceil((countRequest.result?.count || 1) / PAGE_SIZE))}
        filter={{ auth }}
        onFilterVisibleChange={actions.set.filterVisible}
        onPageChange={queryActions.set.page}
        onDeleteFilterKey={handleDeleteFilterKey}

      >
        <TextField
          fullWidth
          label="Auth"
          value={auth}
          onChange={queryActions.handle.auth}
        />
      </Filter>
      <List
        fetch={fetchEntities}
        filter={filter}
        entityKey="session"
        Container={SessionListItem}
      />
    </>
  )
}

const PAGE_SIZE = 10;