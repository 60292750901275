import { PasswordSignInProps } from '@narayana/sso-ui';
import { UserMeResponse } from '@narayana/sso-api';
import md5 from 'md5';
import React from 'react';
import { useEffect } from 'react';
import { useRequest, useApi } from '../hooks';
import { ApiError } from '@narayana/api';
import { FieldError } from '../errors';

export function useApp(): AppState {
  const api = useApi();
  const meRequest = useRequest(() => api.sso.user.me(null));
  const signInRequest = useRequest(async ({ login, password }: { login: string, password: string }) => {

    try {
      const { salt } = await api.sso.password.salt(login);
      const { user, session } = await api.sso.password.signIn({ user: login, hash: md5(`${salt}${password}`) });
      return { user, session };
    } catch (e) {
      const error = e as ApiError;
      if (error.status === 403 || error.status === 404) {
        throw new FieldError('login', 'Invalid login or password');
      }

      throw new FieldError(null, `Unhandled error: ${error.message}`);
    }
  });
  const [error, setError] = React.useState<PasswordSignInProps['error']>();

  const meExecute = meRequest.execute;
  const signIn = React.useCallback(
    (login: string, password: string) => signInRequest.execute({ login, password }),
    [signInRequest]
  );

  /* eslint-disable-next-line react-hooks/exhaustive-deps */
  useEffect(() => meExecute(null), []);
  useEffect(() => setError(signInRequest.error ? { field: null, message: signInRequest.error.message } : null), [signInRequest.error]);

  return {
    isReady: !meRequest.inProgress,
    me: signInRequest.result || meRequest.result,
    error,
    setError,
    signIn
  }
}

export type AppState = {
  isReady: boolean,
  me: UserMeResponse | null,
  error: PasswordSignInProps['error'],
  setError: (error: PasswordSignInProps['error']) => void,
  signIn: (login: string, password: string) => void
}