import EditIcon from "@mui/icons-material/Edit";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { useGoto } from "../../../../hooks";

export function DevToolsList () {
  const { markdown } = useGoto({ 
    markdown: { page: 'dev/tools/md', query: { } },
  });

  return (
    <>
      <Typography variant="h4">
        Tools
      </Typography>
      <List>
        <ListItemButton onClick={markdown}>
          <ListItemIcon>
            <Avatar>
              <EditIcon />
            </Avatar>
          </ListItemIcon>
          <ListItemText primary="Markdown editor" />
        </ListItemButton>
      </List>
    </>
  )
}