import Typography from '@mui/material/Typography';
import ReactMarkdown from 'react-markdown'
import { MdCode } from './Code';
import { useStyles } from './styles';
import remarkGfm from 'remark-gfm'
import TableContainer from '@mui/material/TableContainer';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

export function MdComponent (props: MdComponentProps) {
  const styles = useStyles();

  return (
    <ReactMarkdown
      components={{
        h1: (props) => <Typography variant="h1" className={styles.h1}>{props.children}</Typography>,
        h2: (props) => <Typography variant="h2" className={styles.h2}>{props.children}</Typography>,
        h3: (props) => <Typography variant="h3" className={styles.h3}>{props.children}</Typography>,
        h4: (props) => <Typography variant="h4" className={styles.h4}>{props.children}</Typography>,
        h5: (props) => <Typography variant="h5" className={styles.h5}>{props.children}</Typography>,
        h6: (props) => <Typography variant="h6" className={styles.h6}>{props.children}</Typography>,
        p: (props) => <Typography variant="body1">{props.children}</Typography>,
        code: MdCode,
        li: (props) => <li className={styles.li}>{props.children}</li>,
        table: (props) => (
          <TableContainer sx={{ width: '80%', margin: `0 auto`, padding: 2 }} component={Paper}>
            <Table size="small">
              {props.children}
            </Table>
          </TableContainer>
        ),
        thead: (props) => (<TableHead>{props.children}</TableHead>),
        tbody: (props) => (<TableBody>{props.children}</TableBody>),
        tr: (props) => (<TableRow>{props.children}</TableRow>),
        td: (props) => (
          <TableCell sx={{ textAlign: `${props.style?.textAlign}` }}>
            {props.children}
          </TableCell>
        ),
      }}
      remarkPlugins={[remarkGfm]}
    >
      {props.md}
    </ReactMarkdown>
  )
}

export type MdComponentProps = {
  md: string
};