import { database } from './database';
import { concept } from './concept';
import { webassembly } from './webassembly';
import { readme } from './readme';
import { rhai } from './rhai';
import { todo } from './todo';

export default {
  database,
  concept,
  webassembly,
  readme,
  rhai,
  todo
}