import { API, ApiService, Article } from '@narayana/api'

import docs from './__docs'

@Article("database", "Database", docs.database)
@Article("concept", "Concept", docs.concept)
@Article("webassembly", "WebAssembly", docs.webassembly)
@Article("rhai", "rhai", docs.rhai)
@ApiService("PM", docs.readme)
export class ApiPM extends API {
  constructor (prefix: string) {
    super(prefix);
  }
}