import ErrorIcon from "@mui/icons-material/Error";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import { Form } from "@narayana/ui";

import { DirectionsImport } from "./import";
import { DirectionServices } from "./services";
import { DirectionsView } from "./view";
import { useDirections } from "./useDirections";

export function BillingDirections() {
  const { 
    error, 
    service, 
    services, 
    importVisible,
    setService,
    handleClearError,
    handleImportShow,
    handleImportHide,
  } = useDirections();

  if (error != null) {
    return (
      <Form>
        <Alert color="error" icon={<ErrorIcon />}>
          {error}
        </Alert>
        <Form.Actions>
          <Button onClick={handleClearError}>
            CLEAR
          </Button>
        </Form.Actions>
      </Form>
    )
  }

  if (service == null || services == null) {
    return (
      <DirectionServices 
        services={services} 
        setService={setService} 
      />
    )
  }

  if (importVisible) {
    return (
      <DirectionsImport 
        service={service}
        onClose={handleImportHide}
      />
    )
  }

  return (
    <DirectionsView 
      service={service}
      services={services}
      setService={setService}
      onImportShow={handleImportShow}
    />
  )
}