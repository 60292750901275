import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from '@mui/material/Link';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from "@mui/material/Typography";
import { Form } from "@narayana/ui";
import { Group } from "@narayana/sso-api";
import { useEffect, useState } from "react";

import { GroupGeneralTab } from './tabs/general';
import { GroupMembersTab } from './tabs/members';
import { GroupPermissionsTab } from './tabs/permissions';
import { useApi } from "../../../hooks";
import { useRouter } from "../../../hooks"

export function PersonalGroup() {
  const route = useRouter();
  const api = useApi();
  const groupId = route.page.split("/")[1];
  const [tabIndex, setTabIndex] = useState(0);
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => { setTabIndex(newValue) };
  const [group, setGroup] = useState({} as Group);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    (async () => {
      setError('');
      try {
        groupId && setGroup(await api.sso.groups.get(groupId));
      } catch (e) {
        setError((e as Error).message);
      }
      setLoading(false);
    })();
  }, [api.sso.groups, groupId]);

  return (
    <Form>
      <div id="navigation">
        <Breadcrumbs>
          <Link underline="hover" color="inherit" href="#!/groups">
            Groups
          </Link>
          <Typography color="text.primary">{group.title || "New group"}</Typography>
        </Breadcrumbs>
      </div>
      <div>
        <Tabs value={tabIndex} onChange={handleTabChange}>
          <Tab label="General" id={"General"} />
          {groupId ? (<Tab label="Members" id={"Members"} />) : null}
          {groupId ? (<Tab label="Permissions" id={"Permissions"} />) : null}
        </Tabs>
        <div hidden={tabIndex !== 0} >
          <GroupGeneralTab group={group} setGroup={setGroup} loading={loading} />
        </div>
        <div hidden={tabIndex !== 2}>
          <GroupPermissionsTab groupId={groupId} isPersonal={group.isPersonal} />
        </div>
        <div hidden={tabIndex !== 1}>
          <GroupMembersTab groupId={groupId} isPersonal={group.isPersonal} />
        </div>
      </div>
      <Form.Actions error={error || ''} />
    </Form>
  );
}