import DevicesIcon from '@mui/icons-material/Devices';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import Typography from '@mui/material/Typography';
import { Device } from "@narayana/billing-api";
import { Loading } from "@narayana/ui";
import { Tooltip } from '@mui/material';

export const DeviceListItem = (props: DevicesListItemProps) => {
  const device = props.device;
  const propsOnClick = props.onClick;
  const onClick = React.useCallback(
    () => { propsOnClick(device) },
    [device, propsOnClick]
  );

  return (
    <ListItem disabled={props.disabled}>
      <ListItemButton
        disabled={props.disabled || props.loading}
        onClick={onClick}
      >
        <ListItemIcon>
          <Loading variant="circular" loading={props.loading} width={40} height={40}>
            <DevicesIcon />
          </Loading>
        </ListItemIcon>
        <ListItemText
          primary={
            <Loading loading={props.loading} height={24} width={280}>
              <Tooltip title={
                props.device.type + "\n" + (props.device.isActive ? "Device enabled" : "Device disabled")
              }>
                <Typography
                  sx={props.device.isActive ? {} : { textDecoration: 'line-through' }}
                >
                  {props.device.id}

                </Typography>
              </Tooltip>
            </Loading>}
          secondary={
            <Loading loading={props.loading} height={20} width={180}>
              `Wallet: ${props.device.wallet}`
            </Loading>}
        />
      </ListItemButton>
    </ListItem>
  );
}

export type DevicesListItemProps = {
  device: Device;
  disabled: boolean;
  loading: boolean;
  onClick: (device: Device) => void;
  onDelete: (device: Device) => void;
}

