import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import { Device, DeviceCreateRequest } from "@narayana/billing-api";
import { Form, Loading } from "@narayana/ui";
import md5 from "md5";
import React, { useState } from 'react';
import { useQuery } from '../../../../hooks';
import { Code } from '../../../Code';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';

export const DeviceEdit = ({
  device,
  loading,
  disabled,
  error,
  onChange,
  onDelete,
  onRestore,
  onCreate
}: DeviceEditProps) => {
  const [query] = useQuery({ id: null as string | null });
  const [wallet, setWallet] = React.useState('');
  const [id, setId] = React.useState(query.id || '');
  const [ip, setIp] = React.useState('');
  const [formattedIp, setFormattedIp] = React.useState('');
  const [type, setType] = React.useState('freeswitch');
  const [providerID, setProviderID] = React.useState('');
  const [payload, setPayload] = React.useState('{}');
  const isNew = query.id == null || query.id === '';
  const [internalError, setInternalError] = useState('');
  const [credsWindowVisible, setCredsWindowVisible] = useState(false);
  const [login, setLogin] = React.useState('');
  const [domain, setDomain] = React.useState(window.location.hostname);
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [parsedPayload, setParsedPayload] = React.useState({} as Record<string, any>);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const handleCredsWindowVisible = React.useCallback(
    () => { setCredsWindowVisible(false) }, []
  );
  React.useEffect(
    () => {
      const login = id?.split(':');
      if (login?.length > 1) {
        if (login[0] === 'freeswitch') {
          setLogin(login[1]);
          setCredsWindowVisible(false);
        }
      }
      else setLogin(`Expecting login as '<type>:<id>'. For example, 'freeswitch:123'. Got ${login}.`);
    }, [id, type]
  )
  React.useEffect(
    () => {
      if (device == null) {
        return;
      }
      setWallet(device.wallet);
      setType(device.type || type);
      setId(device.id);
      setIp(device.ip?.join("\n") || '');
      setFormattedIp(device.ip?.join("\n") || '');
      setInternalError('');
      try {
        setPayload(JSON.stringify(device.payload || {}, null, 2));
      } catch (e) { setInternalError((e as Error).message) }
    }, [device, setWallet, setType, setPayload, type]
  );
  const handleIPChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInternalError('');
      setIp(e.target.value);
      const ip = e.target.value
        .replaceAll('  ', '\n')
        .replaceAll(' ', '\n')
        .replaceAll(',', '\n')
        .replaceAll(';', '\n')
        .replaceAll('\n\n', '\n');
      const ipRegexp = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/;
      if (ip.length > 0 && !ip.split('\n').every(ipStr => ipRegexp.test(ipStr))) {
        setInternalError(`Invalid IP list.`);
        setFormattedIp('');
        return;
      }
      setFormattedIp(ip);
      setIp(ip);
    }, []
  );
  const handleLoginChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setLogin(e.target.value);
    }, []
  );
  const handlePasswordChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    }, []
  );
  const handleDomainChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setDomain(e.target.value);
    }, []
  );
  const handleSaveClick = React.useCallback(
    () => {
      setInternalError('');
      const ipList = (formattedIp.length === 0 ? []:formattedIp.split("\n"));
      try {
        onChange
          && device
          && onChange({
            id,
            wallet,
            type,
            ip: ipList,
            provider: providerID,
            gateway: [],
            isActive: device.isActive,
            payload: JSON.parse(payload)
          } as unknown as Device)
      } catch (e) { setInternalError((e as Error).message) }
    }, [formattedIp, onChange, device, id, wallet, type, providerID, payload]
  );
  const handleCreateClick = React.useCallback(
    () => {
      setInternalError('');
      const ipList = (formattedIp.length === 0 ? []:formattedIp.split("\n"));
      try {
        onCreate && onCreate({
          wallet,
          type: type || 'freeswitch',
          ip: ipList,
          provider: providerID,
          payload: JSON.parse(payload),
          id
        } as DeviceCreateRequest);
      } catch (e) { setInternalError((e as Error).message) }
    }, [formattedIp, id, onCreate, payload, providerID, type, wallet]
  )
  const handleDeleteClick = React.useCallback(
    () => {
      setInternalError('');
      try {
        onDelete && device && onDelete(id)
      } catch (e) { setInternalError((e as Error).message) }
    }, [onDelete, device, id]
  );
  const handleRestoreClick = React.useCallback(
    () => {
      setInternalError('');
      try {
        onRestore && device && onRestore(id);
      } catch (e) { setInternalError((e as Error).message) }
    }, [onRestore, device, id]
  );
  const handleIdChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setId(e.target.value);
    }, []
  )
  const handleWalletChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setWallet(e.target.value);
    }, []
  );
  const handleTypeChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setType(e.target.value);
    }, []
  );
  const handleProviderIDChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setProviderID(e.target.value);
    }, []
  )
  const handlePayloadChange = React.useCallback(
    (payload: string) => {
      setPayload(payload);
    }, []
  )
  const handleCalculateHash = React.useCallback(
    () => {
      setInternalError('');
      try {
        const a1hash = md5(`${login}:${domain}:${password}`);
        const newPayload = JSON.stringify({ ...JSON.parse(payload), freeswitch: { a1hash } });
        setPayload(newPayload);
        setCredsWindowVisible(false);
        setProviderID(providerID);
        onChange && onChange({
          id,
          wallet,
          type,
          provider: providerID,
          isActive: device?.isActive || false,
          payload: JSON.parse(newPayload)
        } as unknown as Device);
      } catch (e) { setInternalError((e as Error).message) }
    },
    [login, domain, password, payload, providerID, onChange, id, wallet, type, device?.isActive]
  )
  React.useEffect(() => {
    try {
      setInternalError('');
      setParsedPayload(JSON.parse(payload));
    } catch (e) {
      setParsedPayload({});
      setInternalError('Incorrect JSON');
    }
  }, [payload]);

  return (
    <Form>
      {
        ((device?.id?.split(":")[0] === 'freeswitch' || type === 'freeswitch') && device && device?.id?.split(":")[1]?.length > 0)
          ? <Alert severity={parsedPayload
            ?.freeswitch?.a1hash ? "info"
            : "error"}>
            {parsedPayload?.freeswitch?.a1hash
              ? "You can replace Freeswitch authentication credentials "
              : "Enter Freeswitch authentication credentials "
            }
            <Link
              component="button"
              variant="body2"
              onClick={() => {
                setCredsWindowVisible(true);
              }}
            >
              here.
            </Link>
          </Alert>
          : null
      }
      {
        device?.type === 'freeswitch'
          ? <Alert severity="info">
            {"Authentication credentials are set up. "}
            <Link
              component="button"
              variant="body2"
              onClick={() => { setCredsWindowVisible(true) }}
            >
              Set up new.
            </Link>
          </Alert>
          : null
      }
      <Dialog
        open={credsWindowVisible}
        onClose={handleCredsWindowVisible}
      >
        <DialogTitle>
          Freeswitch credentials
        </DialogTitle>
        <DialogContent>
          <Form>
            <TextField
              id="login"
              label="Login"
              value={login || ''}
              disabled={true}
              onChange={handleLoginChange}
            />
            <FormControl>
              <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? 'text' : 'password'}
                label="Password"
                value={password || ''}
                onChange={handlePasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <TextField
              id="domain"
              label="Domain"
              value={domain || ''}
              onChange={handleDomainChange}
            />
          </Form>
          <DialogActions>
            <Button onClick={handleCalculateHash}>
              Save and close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <Loading loading={loading} height={56}>
        <TextField
          label="Type"
          variant="outlined"
          disabled={loading || disabled || !isNew}
          value={type || ''}
          onChange={handleTypeChange}
        />
      </Loading>
      {isNew
        ? <Loading loading={loading} height={56}>
          <TextField
            label="Provider ID"
            variant="outlined"
            disabled={loading || disabled || !isNew}
            hidden={!isNew}
            value={providerID || ''}
            onChange={handleProviderIDChange}
          />
        </Loading>
        : null}
      <Loading loading={loading} height={56}>
        <TextField
          label="ID"
          variant="outlined"
          disabled={loading || disabled || !isNew}
          value={id || 'freeswitch:'}
          onChange={handleIdChange}
        />
      </Loading>
      <Loading loading={loading} height={56}>
        <TextField
          label="IP"
          variant="outlined"
          disabled={loading || disabled}
          value={ip || ''}
          multiline
          onChange={handleIPChange}
        />
      </Loading>
      <Loading loading={loading} height={56}>
        <TextField
          label="Wallet"
          variant="outlined"
          disabled={loading || disabled}
          value={wallet || ''}
          onChange={handleWalletChange}
        />
      </Loading>
      <Loading loading={loading} height={56}>
        <Code
          value={payload || '{}'}
          language="json"
          title="Payload"
          disabled={loading || disabled}
          onChange={handlePayloadChange}
        />
      </Loading>
      <Form.Actions error={error || internalError}>
        <Loading loading={loading} height={56}>
          {!isNew
            ? <Button
              disabled={disabled}
              color="secondary"
              onClick={Boolean(device?.isActive) ? handleDeleteClick : handleRestoreClick}
            >
              {Boolean(device?.isActive) ? 'Delete' : 'Restore'}
            </Button>
            : null
          }
        </Loading>
        <Loading loading={loading} height={56}>
          <Button
            disabled={disabled}
            onClick={isNew ? handleCreateClick : handleSaveClick}
          >
            {!isNew ? "Save" : "Create"}
          </Button>
        </Loading>
      </Form.Actions>
    </Form>
  )
}

export type DeviceEditProps = {
  device: Device | null | undefined;
  loading?: boolean;
  disabled?: boolean;
  error?: string;
  onChange?: (device: Device) => void;
  onDelete?: (id: string) => void;
  onRestore?: (id: string) => void;
  onCreate?: (device: DeviceCreateRequest) => void;
}

export default DeviceEdit;