import { Provider } from '@narayana/billing-api'
import { useCallback, useEffect } from 'react';

import { useApi, useForm, useQuery, useRequest } from '../../../../../hooks';

export function useData(provider: Provider) {
  const api = useApi();
  const [{ id: queryId }, { set: { id: setQueryId } }] = useQuery({ id: '' });
  const isNew = queryId === '' || queryId == null;

  const [
    { id, title, ip, payload, isActive, error },
    {
      set: {
        title: setTitle,
        ip: setIp,
        payload: onPayloadChange,
        isActive: setIsActive,
        error: setError
      },
      handle: { id: onIdChange, title: onTitleChange, ip: onIpChange }
    }
  ] = useForm({
    id: queryId,
    title: '',
    ip: '',
    payload: '{}',
    isActive: true,
    error: undefined as string | undefined
  });

  const { execute: deleteRestore, ...deleteRestoreRequest } = useRequest(
    async ({ id, isActive }: DeleteRestoreRequest) => {
      await api.billing.providers[isActive ? 'delete' : 'restore'](id);
      setIsActive(!isActive);
    }
  );
  const { execute: save, ...saveRequest } = useRequest(
    async (provider: Provider) => {
      if (provider.id === '') {
        throw new Error(`Id can't be empty`);
      }

      if (isNew) {
        await api.billing.providers.create(provider);
        setQueryId(provider.id);
        return;
      }

      const providerWithoutId = { title: provider.title, ip: provider.ip || [], payload: provider.payload };
      await api.billing.providers.update({
        id: provider.id,
        provider: providerWithoutId
      });
    }
  );

  useEffect(() => {
    setTitle(provider.title || '');
    setIp(provider.ip?.join('\n') || '');
    onPayloadChange(JSON.stringify(provider.payload || {}, null, 2));
    setIsActive(provider.isActive == null || provider.isActive);
  }, [provider, setTitle, onPayloadChange, setIsActive, setIp]);

  useEffect(() => setError(saveRequest.error?.message), [setError, saveRequest.error]);
  useEffect(() => setError(deleteRestoreRequest.error?.message), [setError, deleteRestoreRequest.error]);

  const onDeleteRestore = useCallback((_: unknown) => {
    setError(undefined);
    deleteRestore({ id: provider.id, isActive });
  }, [deleteRestore, provider, isActive, setError]);

  const onSave = useCallback((_: unknown) => {
    let payloadObj = {};
    setError(undefined);

    try {
      payloadObj = JSON.parse(payload);
    } catch (e) {
      setError('Invalid payload (expected JSON)');
      return;
    }
    const formattedIp = ip
      .replaceAll('  ', '\n')
      .replaceAll(' ', '\n')
      .replaceAll(',', '\n')
      .replaceAll(';', '\n')
      .replaceAll('\n\n', '\n');
    setIp(formattedIp);
    const ipRegexp = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)\.?\b){4}$/;
    if (formattedIp.length > 0 && !formattedIp.split('\n').every(ipStr => ipRegexp.test(ipStr))) {
      setError(`Invalid IP string`);
    }
    save({ id, title, ip: formattedIp.split('\n'), isActive, payload: payloadObj });
  }, [setError, ip, setIp, save, id, title, isActive, payload]);

  return {
    isNew,
    error,
    id,
    title,
    ip,
    payload,
    isActive,
    inProgress: deleteRestoreRequest.inProgress || saveRequest.inProgress,
    onIdChange,
    onTitleChange,
    onIpChange,
    onPayloadChange,
    onDeleteRestore,
    onSave,
  }
}

export type DeleteRestoreRequest = {
  id: string,
  isActive: boolean
};