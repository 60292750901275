import { Service } from '@narayana/billing-api'
import { useCallback, useEffect } from 'react';

import { useApi, useForm, useQuery, useRequest } from '../../../../hooks';

export function useData(service: Service, onChange: (service: Service) => void) {
  const api = useApi();

  const [{ id: queryId }, { set: { id: setQueryId } }] = useQuery({ id: '' });
  const isNew = queryId === '' || queryId == null;
  const [
    { id, title, payload, isActive, error },
    {
      set: {
        title: setTitle,
        payload: onPayloadChange,
        isActive: setIsActive,
        error: setError
      },
      handle: {
        id: onIdChange,
        title: onTitleChange
      }
    }
  ] = useForm({
    id: queryId,
    title: '',
    payload: '{}',
    isActive: true,
    error: undefined as string | undefined
  });

  const { execute: deleteRestore, ...deleteRestoreRequest } = useRequest(
    async ({ id, isActive }: DeleteRestoreRequest) => {
      await api.billing.services[isActive ? 'delete' : 'restore'](id);
      setIsActive(!isActive);
    }
  );

  const { execute: save, ...saveRequest } = useRequest(
    async (service: Service) => {
      if (service.id === '') {
        throw new Error(`Id can't be empty`);
      }
      if (isNew) {
        await api.billing.services.create(service);
        setQueryId(service.id);
        return;
      }
      await api.billing.services.update(service);
    }
  );

  useEffect(() => {
    setTitle(service.title || '');
    onPayloadChange(JSON.stringify(service.payload || {}, null, 2));
    setIsActive(service.isActive == null || service.isActive);
  }, [setTitle, onPayloadChange, setIsActive, service.title, service.payload, service.isActive]);

  useEffect(() => setError(saveRequest.error?.message), [setError, saveRequest.error]);
  useEffect(() => setError(deleteRestoreRequest.error?.message), [setError, deleteRestoreRequest.error]);

  const onDeleteRestore = useCallback((_: unknown) => {
    setError(undefined);
    deleteRestore({ id: service.id, isActive });
  }, [setError, deleteRestore, service.id, isActive]);

  const onSave = useCallback((_: unknown) => {
    let payloadObj;
    try {
      payloadObj = JSON.parse(payload);
    } catch (e) {
      setError((e as Error).message);
      return;
    }

    save({ id, title, isActive, payload: payloadObj });
  }, [save, id, title, isActive, payload, setError]);

  return {
    isNew,
    id,
    title,
    payload,
    error,
    isActive,
    inProgress: deleteRestoreRequest.inProgress || saveRequest.inProgress,
    onIdChange,
    onTitleChange,
    onPayloadChange,
    onDeleteRestore,
    onSave,
    setIsActive,
    setTitle,
    setError,
  }
}

export type DeleteRestoreRequest = {
  id: string,
  isActive: boolean
};