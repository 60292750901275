export default `
# Phones

Phones number microservice

## Ranges

Range is number template like '1800300____'. This range contains 10000 phone numbers.

If you want to work with one number use it as template "18003000000" - one number range.
`