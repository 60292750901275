export default `
# Billing ToDo

+ Wallets/users (share&close&list&count)
+ Devices/users (share&close&list&count)

- Negative wallet balance

-- add tests::connections::payment_required
- Connections

- Holds
- Worker (recursive write-off of subscriptions)
  
- Fix rust packages names (sso_client -> narayana_sso_client, ...)
- Directions - update one row
- PlanServices - update one row
- DevicePlans
- Create indexes and profile sql

## Check API

- wallets
- directions
- devices
- holds

## UI Fixes

- Currencties:create add "id" 2 UI
- Providers:test UI 
- PlanServices
- Directions: filter
- Directions: update one row
`