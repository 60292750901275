import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiFreeswitch } from "..";
import { 
  CALLS_CLOSE_REQUEST,
  CALLS_CREATE_CREATE_REQUEST, 
  CALLS_CREATE_CREATE_RESPONSE, 
  CALLS_WITHDRAW_REQUEST 
} from "../calls/examples";
import { 
  APICalls, 
  CallsCloseRequest, 
  CallsCreateRequest, 
  CallsCreateResponse,
  CallsWithdrawRequest
} from "../calls";

@ApiEntity("CallsIn", "Methods for managing connections")
export class APICallsIn {
  private calls = new APICalls(this.api, "/calls/in");

  constructor (
    public api: ApiFreeswitch
  ) { }

  @ApiMethod(
    "Method for create connection",
    [ "freeswitch#calls" ],
    CALLS_CREATE_CREATE_REQUEST,
    CALLS_CREATE_CREATE_RESPONSE
  )
  async create (request: CallsInCreateRequest): Promise<CallsInCreateResponse> {
    return this.calls.create(request);
  }

  @ApiMethod(
    "Method for withdraw balance",
    [ "freeswitch#calls" ],
    CALLS_WITHDRAW_REQUEST,
    undefined
  )
  async withdraw (request: CallsInWithdrawRequest): Promise<void> {
    return this.api.execute("/calls/in/withdraw", "PUT", null, request);
  }

  @ApiMethod(
    "Method for close connection",
    [ "freeswitch#calls" ],
    CALLS_CLOSE_REQUEST,
    undefined
  )
  async close (request: CallsInCloseRequest): Promise<void> {
    return this.api.execute(`/calls/in`, "DELETE", null, request);
  }
}

export type CallsInCreateRequest = CallsCreateRequest;
export type CallsInCreateResponse = CallsCreateResponse;
export type CallsInWithdrawRequest = CallsWithdrawRequest;
export type CallsInCloseRequest = CallsCloseRequest;