import AddIcon from "@mui/icons-material/Add";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Filter } from '@narayana/ui';
import { GroupUser, GroupUserCreateRequest } from "@narayana/sso-api";
import { GroupUserListItem } from "@narayana/sso-ui";
import { GroupUsersFilter } from '@narayana/sso-api';
import { useCallback, useEffect, useMemo, useState } from "react";

import { AddMembersForm } from "./AddMembersForm";
import { List } from "../../../../ui/List";
import { MainAction } from "../../../../ui/MainAction";
import { useApi, useForm, useQuery, useRequest } from "../../../../hooks";

const PAGE_SIZE = 10;

export function GroupMembersTab(props: GroupMembersTabPropsType) {
  const api = useApi();
  const [{ now }, actions] = useForm({ now: Date.now() });
  const [addMembersFormVisible, setAddMembersFormVisible] = useState(false);
  const [{ page, q, group }, queryActions] = useQuery({
    group: props.groupId,
    page: 1,
    q: '',
  });
  const countRequest = useRequest(
    (filter: GroupUsersFilter) => api.sso.groupUsers.count(filter),
    { debounce: true }
  );
  const filter = useMemo(() => ({
    q: q || '',
    limit: PAGE_SIZE,
    group,
    offset: (page - 1) * PAGE_SIZE,
    now
  }), [q, group, page, now]);
  const countRequestExecute = countRequest.execute;
  useEffect(() => { countRequestExecute(filter) }, [filter, countRequestExecute]);
  const fetchEntities = useCallback(
    (filter: GroupUsersFilter) => api.sso.groupUsers.list(filter),
    [api.sso.groupUsers]
  );
  const onCreateClicked = useCallback(() => {
    setAddMembersFormVisible(true);
  },
    []
  );
  const handleCloseAddMembersForm = useCallback(() => {
    setAddMembersFormVisible(false);
    actions.set.now(Date.now());
  }, [actions.set]);
  const handleAdd = useCallback(async (request: GroupUserCreateRequest) => {
    await api.sso.groupUsers.create(request);
    actions.set.now(Date.now());
  }, [actions.set, api.sso.groupUsers]);
  const handleDeleteClick = useCallback(
    async (groupUser: GroupUser) => {
      await api.sso.groupUsers.delete({ group: groupUser.group, user: groupUser.user });
      actions.set.now(Date.now());
    },
    [actions.set, api.sso.groupUsers]
  );
  const handleListClick = useCallback(
    (groupUser: GroupUser) => console.log(groupUser),
    []
  );

  return (
    <>
      <div>
        <Filter
          query={q || ''}
          filterVisible={false}
          page={page}
          maxPage={Math.max(1, Math.ceil((countRequest.result?.count || 1) / PAGE_SIZE))}
          filter={{

          }}
          onQueryChange={queryActions.set.q}
          onPageChange={queryActions.set.page}
        />
      </div>
      <div>
        <List
          fetch={fetchEntities}
          filter={filter}
          entityKey="groupUser"
          Container={GroupUserListItem}
          ContainerProps={{
            disabled: false,
            onClick: handleListClick,
            onDelete: handleDeleteClick
          }}
        />
      </div>
      {
        props.isPersonal
          ? null
          : (
            <MainAction onClick={onCreateClicked}>
              <AddIcon />
            </MainAction>
          )
      }
      <Dialog open={addMembersFormVisible} onClose={handleCloseAddMembersForm}>
        <DialogTitle>{"Add new group member"}</DialogTitle>
        <DialogContent>
          <AddMembersForm
            onClick={handleAdd}
            onVisibleChange={handleCloseAddMembersForm}
            group={group}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

export type GroupMembersTabPropsType = {
  groupId: string;
  isPersonal: boolean;
}
