import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiBilling, Device } from "..";

@ApiEntity("Connections", "Methods for managing connections")
export class APIConnections {
  constructor (
    public api: ApiBilling
  ) { }

  @ApiMethod(
    "Method for create connection",
    [ "connections#create" ],
    {
      device: "device_id",
      service: "service_id",
      direction: "18002727373",
      amount: 1,
      dueInterval: 300,
      isInternal: false
    } as ConnectionsCreateRequest,
    {
      id: "uuid",
      secret: "uuid",
      device: {
        id: 'device_id',
        wallet: 'wallet_id',
        ip: ['ip'],
        type: 'freeswitch',
        gateway: [ { id: 'id', freeswitch: 'freeswitch_id' } ],
        isActive: true,
        payload: { },
        provider: {
          id: "provider_id",
          plan: "main",
          gate: { uri: "asdjsa" },
          code: { main: "/*code*/" },
          isActive: true,
          payload: { }
        }
      },
      services: [
        { id: '<plan-service-id>', provider: "provider_id", payload: { route: "narayana" } },
        { id: '<another-plan-service-id>', provider: "provider_id", payload: { route: "narayana" } }
      ]
    } as ConnectionsCreateResponse
  )
  async create<T> (request: ConnectionsCreateRequest): Promise<ConnectionsCreateResponse<T>> {
    return this.api.execute("/connections", "POST", null, request);
  }

  @ApiMethod(
    "Method for withdraw balance (amount is optional)",
    [ "connections#withdraw" ],
    {
      secret: "<secret>",
      elapsedTime: 60,
      planService: "plan_service_id",
      dueInterval: 300
    } as ConnectionsWithdrawRequest,
    undefined
  )
  async withdraw (request: ConnectionsWithdrawRequest): Promise<void> {
    return this.api.execute("/connections/withdraw", "PUT", null, request);
  }

  @ApiMethod(
    "Method for close connection",
    [ "connections#close" ],
    {
      secret: "<connection-secret>",
      reason: "normal"
    } as ConnectionsCloseRequest,
    undefined
  )
  async close ({ secret, reason }: ConnectionsCloseRequest): Promise<void> {
    return this.api.execute(`/connections/${secret}?reason=${reason}`, "DELETE", null, null);
  }
}

export type ConnectionsCreateRequest = {
  device: string;
  service: string;
  direction: string;
  amount: number;
  dueInterval: number;
  isInternal: boolean;
};

export type ConnectionsCreateResponse <T = Record<string, unknown>> = {
  id: string;
  secret: string;
  device: Device;
  services: ConnectionPlanService<T>[];
};

export type ConnectionPlanService<T = Record<string, unknown>> = {
  id: string;
  provider: string;
  payload: T
};

export type ConnectionsWithdrawRequest = {
  secret: string;
  elapsedTime: number;
  planService: string;
  dueInterval: number;
};

export type ConnectionsCloseRequest = {
  secret: string;
  reason: string;
};