import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Form, Loading } from "@narayana/ui";
import { Wallet } from "@narayana/billing-api";

import { useData } from "./useData";

export function WalletTabMain({ wallet, loading, isVisible }: WalletTabMainProps) {
  const {
    title,
    isActive,
    error,
    disabled,
    onTitleChange,
    onSave,
    onDeleteRestore
  } = useData(wallet);

  if (!isVisible) {
    return null;
  }
  const walletBalance = wallet.balance?.find((x) => x.currency === "€");
  return (
    <Form>
      <Loading loading={loading} >
        <TextField label="Title" value={title} disabled={disabled} onChange={onTitleChange} />
      </Loading>
      <Loading loading={loading} >
        <TextField label="Balance, €" value={walletBalance?.balance || 0} disabled />
      </Loading>
      <Loading loading={loading} >
        <TextField label="Overdraft, €" value={walletBalance?.overdraft || 0} disabled />
      </Loading>
      <Form.Actions error={error} >
        <Button disabled={loading || disabled} color="secondary" onClick={onDeleteRestore}>
          {isActive ? "Delete" : "Restore"}
        </Button>
        <Button disabled={loading || disabled} color="primary" onClick={onSave}>
          Save
        </Button>
      </Form.Actions>
    </Form>
  );
}

export type WalletTabMainProps = {
  wallet: Wallet;
  loading: boolean;
  isVisible: boolean;
}