import { API, ApiService, Article } from '@narayana/api'

import docs from './__docs'
import { APIIntegration } from './integration'

export * from './integration'


@Article("concept", "Concept", docs.concept)
@Article("todo", "ToDo", docs.todo)
@ApiService("GSM", docs.readme)
export class ApiGSM extends API {
  public integration = new APIIntegration(this);

  constructor (prefix: string) {
    super(prefix);
  }
}