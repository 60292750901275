import React from "react";
import { parseLocation, Route } from "./useRouter";

export function useGoto <T extends Record<string, Route>>(
  pages: T = { } as T,
  before?: () => void
): Goto<T> {
  const result = { goto } as unknown as Record<keyof T, () => void>;
  const [ p ] = React.useState(pages);
  
  for (const key in p) {
    /* eslint-disable-next-line react-hooks/rules-of-hooks */
    result[key as keyof T] = React.useCallback(
      () => {
        if (typeof before === 'function') {
          before();
        }
        
        goto(p[key]);
      },
      /* eslint-disable-next-line react-hooks/exhaustive-deps */
      [ before ]
    ) as () => void;
  }

  return result as unknown as Goto<T>;
}

type Goto<T> = { goto: (route: Route) => void } & { [key in keyof T]: () => void }

export function routeLink (route: Route) {
  const query: string[] = [];

  for (const key in route.query) {
    if (route.query[key] == null) {
      continue;
    }

    query.push(`${encodeURIComponent(key)}=${encodeURIComponent(route.query[key]!)}`)
  }

  return `/#!/${route.page}${query.length === 0 ? '' : `?${query.join('&')}`}`;
}

const goto = (route: Route) => {
  const uri = routeLink(route);
  const prev = parseLocation();
  
  if (prev.page !== route.page) {
    window.history.pushState(route, '', uri);
    window.dispatchEvent(new Event('routechange'));
    return;
  }

  window.history.replaceState(route, '', uri);
  window.dispatchEvent(new Event('routechange'));
};