import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Form } from "@narayana/ui";
import { PermissionCreateRequest } from "@narayana/sso-api";
import { useCallback, useState } from "react";

export function PermissionCreationForm(props: PermissionCreationFormProps) {
  const [permission, setPermission] = useState(
    {
      group: props.group,
      payload: {},
      entity: '',
      permission: ''
    } as PermissionCreateRequest);
  const handleCreate = useCallback(() => {
    props.onClick(permission);
    props.onVisibleChange(false);
  }, [permission, props]);
  const onEntityChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPermission({ ...permission, entity: event.target.value || '' })
  }, [permission]);
  const onPermissionChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setPermission({ ...permission, permission: event.target.value || '' })
  }, [permission]);

  return (
    <>
      <Form>
        <TextField label="Entity" onChange={onEntityChange} />
        <TextField label="Permission" onChange={onPermissionChange} />
        <Form.Actions>
          <Button onClick={handleCreate}>Create</Button>
        </Form.Actions>
      </Form>
    </>
  );
}

export type PermissionCreationFormProps = {
  onClick: (permission: PermissionCreateRequest) => void;
  onVisibleChange: (visible: boolean) => void;
  group: String;
}