import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  file: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 70,
    backgroundColor: 'rgba(0, 0, 0, 0.07)',
    marginBottom: 8
  },
  data: {
    height: 400
  }
}));