import { API, ApiService, Article } from '@narayana/api'

import docs from './__docs'
import { APINumbers } from './numbers'
import { APIRanges } from './ranges'

export * from './numbers';
export * from './ranges';

@Article("concept", "Concept", docs.concept)
@Article("todo", "ToDo", docs.todo)
@Article("database", "Database", docs.database)
@ApiService("Phones", docs.readme)
export class ApiPhones extends API {
  public numbers = new APINumbers(this);
  public ranges = new APIRanges(this);

  constructor (prefix: string) {
    super(prefix);
  }
}