import Alert from "@mui/material/Alert";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import { useMemo } from "react";
import { useDropzone } from 'react-dropzone'
import { useStyles } from "./styles";
import { useData } from "./useData";

export function DirectionsImportData (props: DirectionsImportDataProps) {
  const {
    data, 
    dataColumns
  } = props;

  const styles = useStyles();
  const { 
    uploadError,
    onDrop
  } = useData(props);
  const { getRootProps } = useDropzone({ onDrop: onDrop })

  const dataObjects = useMemo(() => 
    data.map((row, rowIndex) => {
      const result = { id: rowIndex } as Record<number | string, unknown>;
      for (let i = 0; i < row.length; i++) {
        result[i] = row[i];
      }

      return result;
    }), 
    [ data ]
  );

  return (
    <div className={styles.root}>
      <div 
        className={styles.file}
        { ...getRootProps() }
      >
        UPLOAD
      </div>
      {uploadError == null 
        ? null 
        : (
          <Alert color="error">
            {uploadError}
          </Alert>
        )}
      {data.length === 0 || dataColumns.length === 0 
        ? null
        : (
          <div className={styles.data}>
            <DataGrid
              rows={dataObjects}
              columns={dataColumns}
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
          </div>
        )}
    </div>
  )
}

export type DirectionsImportDataProps = {
  data: string[][],
  dataColumns: GridColDef[],
  onDataChange: (data: string[][]) => void,
  onDataColumnsChange: (columns: GridColDef[]) => void,
};