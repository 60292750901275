export const database = `# Database

\`\`\`mermaid
erDiagram
Teams {
  id VARCHAR
  title VARCHAR
  payload JSONB
}
TeamUsers {
  team VARCHAR
  user VARCHAR  
  group VARCHAR
}
TeamUsers }o--|| Teams : team
PackageType {
  team VARCHAR
  id VARCHAR
  title VARCHAR
  description VARCHAR
  readme VARCHAR
  ide VARCHAR
}
PackageType }o--|| Teams : team
Packages {
  id VARCHAR
  team VARCHAR
  publicKey VARCHAR
  type VARCHAR
  description VARCHAR
  head VARCHAR
  payload JSONB
}
Packages }o--|| Teams : team
Packages }o--|| PackageType : type
Packages }o--|| Versions : head
PackageUsers {
  package VARCHAR
  user VARCHAR  
  group VARCHAR
}
PackageUsers }o--|| Packages : package
Versions {
  package VARCHAR
  version VARCHAR
  publish TIMESTAMP
  data VARCHAR
  payload JSONB
  hash VARCHAR
}
Versions }o--|| Packages : package
History {
  date TIMESTAMP
  session VARCHAR
  type VARCHAR
  payload JSONB
}
\`\`\``;