import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: 'calc(100% - 32px)',
    top: 0,
    left: 16,
    backgroundColor: '#f3f9ff',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 24,
    margin: 0,
    color: '#6c6c6c',
    '& svg': {
      fontSize: 16,
      margin: '0 8px',
      color: '#6c6c6c'
    },
  },
  headerText: {
    display: 'flex',
    marginLeft: 4,
    height: '100%',
    flex: 1,
    alignItems: 'center',
  },
  editor: {
    margin: 0
  }
});