import { Plan } from "@narayana/billing-api";
import { useData } from "./data";
import { useStyles } from "./styles";

export const PlanEdit = ({ plan }: PlanEditProps) => {
  useData(plan);
  const styles = useStyles();

  return (
    <div className={styles.root}>
      !#$
    </div>
  )
}

export type PlanEditProps = {
  plan: Plan
};