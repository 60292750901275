import Avatar from '@mui/material/Avatar';
import DeleteIcon from '@mui/icons-material/Delete';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import IconButton from '@mui/material/IconButton';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import { GroupUser } from "@narayana/sso-api";
import { Loading } from "@narayana/ui/target/Loading";

export const GroupUserListItem = (props: GroupUserListItemProps) => {
  const onClick = React.useCallback(
    () => { props.onClick(props.groupUser) },
    [props.groupUser, props.onClick]
  );
  const onDeleteClick = React.useCallback(
    () => { 
      props.onDelete(props.groupUser) 
    },
    [props.groupUser, props.onDelete]
  )

  return (
    <ListItem
      disabled={props.disabled}
      secondaryAction={
        <IconButton
          disabled={props.disabled || props.loading}
          edge="end" aria-label="delete"
          onClick={onDeleteClick}
        >
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemButton
        disabled={props.disabled || props.loading}
        onClick={onClick}
      >
        <ListItemIcon>
          <Loading variant="circular" loading={props.loading} width={40} height={40}>
            <Avatar>
              <Diversity3Icon />
            </Avatar>
          </Loading>
        </ListItemIcon>
        <ListItemText
          primary={
            <Loading loading={props.loading} height={24} width={140}>
              {props.groupUser.user}
            </Loading>
          }
        />
      </ListItemButton>
    </ListItem>
  );
}

export type GroupUserListItemProps = {
  groupUser: GroupUser;
  disabled: boolean;
  loading: boolean;
  onClick: (groupUser: GroupUser) => void;
  onDelete: (groupUser: GroupUser) => void;
}

export default GroupUserListItem;