import { Filter, Form } from "@narayana/ui";
import { useApi, useForm, useQuery, useRequest } from "../../../hooks"
import AddIcon from "@mui/icons-material/Add";

import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { List } from "../../../ui/List"
import { useEffect, useMemo } from "react";
import { ServiceListItem } from "../../../ui/billing/services/ListItem";
import { Service, ServicesListFilter } from "@narayana/billing-api";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Entity, MainAction } from "../../../ui";
import { useCallback } from "react";
import { ServiceEdit } from "../../../ui/billing/services/Edit";

export function BillingServices() {
  const api = useApi();
  const [{ filterVisible }, actions] = useForm({ filterVisible: false });
  const [{ page, q, id, wallet, isActive }, queryActions] = useQuery({
    page: 1,
    q: '',
    id: null as string | null,
    wallet: null as string | null,
    isActive: null as string | null
  });
  const countRequest = useRequest(
    (filter: ServicesListFilter) => api.billing.services.count(filter),
    { debounce: true }
  );
  const filter = useMemo(() => ({
    id,
    q: q || null,
    is_active: isActive == null ? null : isActive === 'true',
    wallet: wallet || null,
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE
  }), [id, q, isActive, wallet, page]);

  const countRequestExecute = countRequest.execute;
  useEffect(() => { countRequestExecute(filter) }, [filter, countRequestExecute]);

  const handleIsActiveChange = useCallback(
    (_: unknown, value: boolean) => queryActions.set.isActive(value.toString()),
    [queryActions.set]
  );

  const fetchEntities = useCallback(
    (filter: ServicesListFilter) => api.billing.services.list(filter),
    [api.billing.services]
  );
  const handleDeleteFilterKey = useCallback(
    (key: string) => {
      switch (key) {
        case 'isActive':
          queryActions.set.isActive(null);
          break;
        case 'wallet':
          queryActions.set.wallet(null);
          break;

        default:
          throw new Error(`Unhandled filter key: ${JSON.stringify(key)}`)
      }
    },
    [queryActions.set]
  );
  const handleListClick = useCallback(
    (service: Service) => queryActions.set.id(service.id),
    [queryActions.set]
  );
  const handleIdClear = useCallback(
    () => queryActions.set.id(null),
    [queryActions.set]
  );
  const handleCreate = useCallback(
    () => queryActions.set.id(''),
    [ queryActions.set ]
  )

  return (
    <>
      <Typography variant="h4" sx={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          Services
        </div>
      </Typography>
      <Filter
        query={q}
        filterVisible={filterVisible}
        page={page}
        maxPage={Math.max(1, Math.ceil((countRequest.result?.count || 1) / PAGE_SIZE))}
        filter={{
          isActive: isActive == null ? null : (isActive === 'true' ? 'Active' : 'Inactive'),
          wallet: wallet == null ? null : `Wallet:${wallet}`,
        }}
        onQueryChange={queryActions.set.q}
        onFilterVisibleChange={actions.set.filterVisible}
        onPageChange={queryActions.set.page}
        onDeleteFilterKey={handleDeleteFilterKey}
      >
        <Form>
          <TextField
            fullWidth
            label="Wallet"
            type="string"
            value={wallet}
            onChange={queryActions.handle.wallet}
          />
          <FormGroup>
            <FormControlLabel
              label="Is Active"
              control={<Switch checked={isActive === 'true'} onChange={handleIsActiveChange} />}
            />
          </FormGroup>
        </Form>
      </Filter>
      <List
        fetch={fetchEntities}
        filter={filter}
        entityKey="service"
        Container={ServiceListItem}
        ContainerProps={{ 
          onClick: handleListClick,
          onDelete: (_: Service) => { },
          disabled: false
        }}
      />
      <Entity
        id={id}
        key={id}
        componentKey="service"
        get={(id: string) => 
          id === '' 
            ? Promise.resolve({} as unknown as Service)
            : api.billing.services.get(id)
        }
        save={async (service: Service) => {
          await api.billing.services.update(service);
          handleIdClear();
        }}
        DialogProps={{
          fullScreen: true
        }}
        Component={ServiceEdit}
        ComponentProps={{ }}
        onClose={handleIdClear}
      />
      <MainAction onClick={handleCreate}>
        <AddIcon />
      </MainAction>
    </>
  )
}

const PAGE_SIZE = 10;