import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

import { useStyles } from "./styles";
import { useImport } from "./useImport";
import Tabs from "@mui/material/Tabs";
import { Tab } from "@mui/material";
import { DirectionsImportData } from "./data";
import { DirectionsImportCode } from "./code";
import { DirectionsImportTabImport } from "./import";

export function DirectionsImport ({
  service,
  onClose
}: DirectionsImportProps) {
  const styles = useStyles();
  const {
    tab, 
    data,
    dataColumns,
    code,
    setData,
    setDataColumns,
    setCode,
    handleTabChange
  } = useImport();

  return (
    <div>
      <div className={styles.header}>
        <Typography variant="h5" sx={{ flex: 1 }}>
          IMPORT {service}
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab label="Data" value="data" />
        <Tab label="Code" value="code" />
        <Tab label="Import" value="import" />
      </Tabs>
      <div className={styles.content}>
        {tab !== 'data' 
          ? null 
          : (
            <DirectionsImportData 
              data={data} 
              dataColumns={dataColumns} 
              onDataChange={setData} 
              onDataColumnsChange={setDataColumns} 
            />
          )
        }
        {tab !== 'code' 
          ? null 
          : (<DirectionsImportCode code={code} onCodeChange={setCode} />)
        }
        {tab !== 'import' 
          ? null 
          : (<DirectionsImportTabImport service={service} data={data} code={code} />)
        }
      </div>
    </div>
  )
}

export type DirectionsImportProps = {
  service: string,
  onClose: () => void
};