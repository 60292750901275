export default `
# GSM Integration (w TC, Top Connect)

These docs are written in top-down design

### Visualization
Very un-representative and bad mermaid graph that needs to be improved:
\`\`\`mermaid
flowchart LR
  subgraph Processes
  service("Billing API")
  end



  subgraph Microservices
  public_api(Public API)
  gsm(GSM Service)
  end

  gsm -->|Funds| public_api
  gsm-->|Service Balance| service

\`\`\`


---
## Terminology

* **Subscriber** - user/client of telephony company
* **GSM** (_Global System for Mobile Communications_) - is a standard developed by _ETSI_ to describe the protocols for second-generation (2G, and other) digital cellular networks used by mobile devices such as mobile phones and tablets.)
* **GSM services** - are a standard collection of applications and features available over the _GSM_ to mobile phone _subscribers_ all over the world.

.
* **CDR** (_Call Detail Record_) - Contains all data about _GSM_-session. Formed and received after the end of session
* **MSISDN** - is a number uniquely identifying a subscription in a [Global System for Mobile communications](https://en.wikipedia.org/wiki/GSM "GSM") or a [Universal Mobile Telecommunications System](https://en.wikipedia.org/wiki/UMTS "UMTS") mobile network.
It is the mapping of the telephone number to the [subscriber identity module](https://en.wikipedia.org/wiki/SIM_card "SIM card") in a mobile or cellular phone.
This abbreviation has several interpretations, the most common one being "Mobile Station International Subscriber Directory Number".
* **ToS** (_Type Of Service_) - specifies which *service* was requested by user
* **MCC** (_Mobile country code_) and **MNC** (_Mobile Network Code_) used in a couple to identify country and network of subscriber
* **VAS** (_Value-added service_) - term for non-core services, or, in short, all services beyond standard voice calls and fax transmissions.
* **USSD** (_Unstructured Supplementary Service Data_) - is a communication protocol to communicate with network carrier in a form of short codes.

.
* **Service provision** - *A service provision is a type of business agreement where one party provides a service to another in return for money.*

Which means that:
* *Service* provision **request** - asks how much service available for a user

And in such way we also have...:
* *Service provision* **response** - is a response to *previous* question

## Provisioning

In this process. We allow or not allow some service for _subscriber_ and then charge him based on _CDR_.

### Start of GSM-service session

#### Request

When _subscriber_ wants to do something, we receive such request from provider in *XML* over *HTTP*:
\`\`\`xml
<?xml version="1.0" encoding="UTF-8"?>
<getBalance>
	<msisdn>37257032619</msisdn>
	<tos>3</tos>
	<callid>d1-eepg2.epc.mnc002.mcc248.3gppnetwork.org;1692100166;628115666;64db6646-248029027003269</callid>
	<mccmnc>23420</mccmnc>
	<currency>EUR</currency>
</getBalance>
\`\`\`

Let's break it down.

| Tag                        | Type      | Description                                                         |
|----------------------------|-----------|---------------------------------------------------------------------|
| _<msisdn>[msisdn]_         | Mandatory | This tag shouldn't be a surprise if you've read _Terminology_ section. |
| _<tos>[tos]_               | Mandatory | Contains _ToS_, see _Terminology_. |
| _<currency>[currency]_     | Mandatory | In which currency balance accounting performed on remote billing for _Subscriber_. _EUR_ or _USD_. |
| _<callid>[callid]_         | Optional  | _Unique_ Id of a session comprised of a network identifiers and unique values. Must be equal to one which we receive in _CDR_ later on. Mandatory for _ToS_:1, 2, 3, 4 |
| _<mccmnc>[mccmnc]_         | Optional  | _MCC_ + _MNC_, see _Terminology_. Mandatory for _ToS_:3 (GPRS) |
| _<parthynum>[Other party number]_ | Optional | Other number that participates in a call. In case of outgoing call it's a number that was dialed. In case of incoming it's number of whoever called us. Also specified in case of incoming SMS |
| _<description>[Description]_ | Optional | Specifies which _VAS_ _Subscriber_ wants to activate, for example: _*146*921*3#_ or _command=discount&packetid=1_. Used for _ToS_: 7, 8 |

Enumeratiov variants for _ToS_:

1. Outbound call
2. Inbound Call
3. GRPS
4. Classic MO SMS
5. USSD SMS
6. USSD: Check Balance
7. VAS Activation: USSD
8. VAS Activation: XML

#### Response

And response will look like this:

\`\`\`xml
<?xml version='1.0' encoding='UTF-8' standalone='yes'?>
	<getBalanceRes>
		<allow>yes</allow>
		<text>ok</text>
		<funds>11517.26</funds>
	</getBalanceRes>
\`\`\`

| Tag               | Type      | Description                                                                    |
|-------------------|-----------|--------------------------------------------------------------------------------|
| _<allow></allow>_ | Mandatory | Whether we allow service or not. _yes_/_no_ as text                            |
| _<funds></funds>_ | Mandatory | Current balance of _Subscriber_. Specifies _amount_ of available service, can be in megabytes, kbytes, ...  |
| _<text></text>_   | Optional  | Text that's displayed in a _USSD_ response. At the moment doesn't work for outgoing calls, _USSD_ answer contains only our text - "wait for call". Will be fixed. |


#### Notes

- If some param was not received from partner default value from database is used.
Example: in case of _ToS_ = 6 partner did not specified what text needs to be displayed for _subscriber_ instead of one from appendix,
"Balance Is %b", then system will use default value.

- Sometimes remote billing may return negative banace. This is normal and indicates that _subscriber_/_client_ has a partner debt.

- Partner ***always*** allows SMS on free number, such as: 9100, 911, 9146. Which means that when new remote billing appears,
we need to ask partners to respond with _<allow>yes</allow>_ on SMS requests that have above numbers in a B-leg

### End of a GSM-service session

Upon the end of such session we receive _CDR_ in a form of _JSON_ over _HTTP_:

#### Request

\`\`\`json
{
  "strmsisdn": "37257032901",
  "strnetworkid": "28602",
  "dtdatestart": "2023-08-15T14:57:31.792",
  "dtdatestop": "2023-08-15T14:57:31.792",
  "strsessionid": "d0-eepg3.epc.mnc002.mcc248.3gppnetwork.org%3B1692100651%3B132190231%3B64db682b-248029027003323",
  "nappendixid": 32803,
  "ncost": 0,
  "ncorpgroupid": -1,
  "nservicetype": 1,
  "strapn": "internet",
  "strcurrency": "EUR",
  "noldbalance": 0,
  "nnewbalance": 0,
  "nquotaexpired": 0,
  "strstatus": "40",
  "ngprsfreebytestariff": 0,
  "ngprsoldfreebytes": 0,
  "ngprsnewfreebytes": 0,
  "npackageactflag": 0,
  "npackageactcost": 0,
  "npackagetraffic": 0,
  "nblockid": 4121,
  "nratinggroup": 0,
  "npackagesimid": -1,
  "npackagevolumekey": -1,
  "ispackageturbo": 0,
  "strpackagedetails": "",
  "strtrafficleft": "-1",
  "nqosp": 49,
  "nusedbytes": 0,
  "nrownum": null,
  "nroundedcost": null
}
\`\`\`

Based on this data we can charge _subscriber_.
It's pretty long, so let's focus on most important things:
- _nservicetype_ - You already know it, _ToS_
- _strmsisdn_ - SIM Card, _MSISDN_
- _strnetworkid_ - Network in which it's all happening
- _nusedbytes_ - Number of used bytes, based on ***this*** we charge user


#### Response

Same as for start of _session_ or any _HTTP_ response with codes ***lower*** than 400. If we send error-_like_ code
then remote billing will try to send _CDR_ every second. And this is ***bad*** and ***must*** be avoided

`