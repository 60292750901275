import AddIcon from "@mui/icons-material/Add";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React from "react";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Filter, Form } from "@narayana/ui";
import { Group, GroupsFilter } from "@narayana/sso-api";
import { GroupListItem } from "@narayana/sso-ui";

import { List } from "../../../ui/List"
import { MainAction } from "../../../ui/MainAction";
import { useApi, useForm, useGoto, useQuery, useRequest } from "../../../hooks"

export function PersonalGroups() {
  const { goto } = useGoto();
  const api = useApi();
  const [{ filterVisible }, actions] = useForm({ filterVisible: false });
  const [{ page, q, owner, isPersonal, isActive }, queryActions] = useQuery({
    page: 1,
    id: null as string | null,
    q: '',
    owner: null as string | null,
    isPersonal: null as string | null,
    isActive: 'true' as string | null
  });
  const countRequest = useRequest(
    (filter: GroupsFilter) => api.sso.groups.count(filter),
    { debounce: true }
  );
  const filter = React.useMemo(() => ({
    q: q || null,
    owner: owner == null || owner === '' ? null : owner,
    is_personal: isPersonal == null ? null : isPersonal === 'true',
    is_active: isActive == null ? null : isActive === 'true',
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE
  }), [q, owner, isPersonal, isActive, page]);

  const countRequestExecute = countRequest.execute;
  React.useEffect(() => { countRequestExecute(filter) }, [filter, countRequestExecute]);

  const handleIsPersonalChange = React.useCallback(
    (_: unknown, value: boolean) => queryActions.set.isPersonal(value.toString()),
    [queryActions.set]
  );
  const handleIsActiveChange = React.useCallback(
    (_: unknown, value: boolean) => queryActions.set.isActive(value.toString()),
    [queryActions.set]
  );

  const fetchEntities = React.useCallback(
    (filter: GroupsFilter) => api.sso.groups.list(filter),
    [api.sso.groups]
  );
  const handleListClick = React.useCallback(
    (group: Group) =>  goto({ page: `group/${group.id}`, query: {} }),
    [goto]
  );
  const handleDeleteFilterKey = React.useCallback(
    (key: string) => {
      switch (key) {
        case 'isPersonal':
          queryActions.set.isPersonal(null);
          break;

        case 'isActive':
          queryActions.set.isActive(null);
          break;

        case 'owner':
          queryActions.set.owner(null);
          break;

        default:
          throw new Error(`Unhandled filter key: ${JSON.stringify(key)}`)
      }
    },
    [queryActions.set]
  );
  const handleCreate = React.useCallback(()=>
    goto({ page: `group/`, query: {} }),
    [goto]
  );

  return (
    <>
      <Typography variant="h4" sx={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          Groups
        </div>
      </Typography>
      <Filter
        query={q}
        filterVisible={filterVisible}
        page={page}
        maxPage={Math.max(1, Math.ceil((countRequest.result?.count || 1) / PAGE_SIZE))}
        filter={{
          owner: owner == null || owner === '' ? null : `Owner:${owner}`,
          isPersonal: isPersonal == null ? null : (isPersonal === 'true' ? 'Personal' : 'Public'),
          isActive: isActive == null ? null : (isActive === 'true' ? 'Active' : 'Inactive'),
        }}
        onQueryChange={queryActions.set.q}
        onFilterVisibleChange={actions.set.filterVisible}
        onPageChange={queryActions.set.page}
        onDeleteFilterKey={handleDeleteFilterKey}
      >
        <Form>
          <TextField
            fullWidth
            label="Owner"
            type="string"
            value={owner}
            onChange={queryActions.handle.owner}
          />
          <FormGroup>
            <FormControlLabel
              label="Is Personal"
              control={<Switch checked={isPersonal === 'true'} onChange={handleIsPersonalChange} />}
            />
          </FormGroup>
          <FormGroup>
            <FormControlLabel
              label="Is Active"
              control={<Switch checked={isActive === 'true'} onChange={handleIsActiveChange} />}
            />
          </FormGroup>
        </Form>
      </Filter>
      <List
        fetch={fetchEntities}
        filter={filter}
        entityKey="group"
        Container={GroupListItem}
        ContainerProps={{
          disabled: false,
          onClick: handleListClick,
          onDelete: handleListClick //TODO
        }}
      />
      <MainAction onClick={handleCreate}>
        <AddIcon />
      </MainAction>
    </>
  )
}

const PAGE_SIZE = 10;