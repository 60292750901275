import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { UserCreateRequest } from "@narayana/sso-api";
import { Form } from "@narayana/ui";
import { useCallback } from "react";
import { useForm } from '../../../../hooks'
import md5 from 'md5';

const createRandomString = () => 'xxxxxxxxxxxxxxxxxxxxxxxx'.replace(/[x]/g, _ => (Math.random() * 16 | 0).toString(16));

export function UserCreate({ disabled, error, onCreate }: UserCreateProps) {
    const [{ id, password }, actions] = useForm({ id: '', password: '' });
    const handleCreate = useCallback(
        (e: unknown)=>{
            const passwordSalt = createRandomString();
            const passwordHash = md5(`${passwordSalt}${password}`);
            onCreate({id, passwordHash, passwordSalt, payload:{}})
        },
        [id, onCreate, password]
        )

    return (
        <Form>
            <TextField
                label="User ID"
                disabled={disabled}
                value={id}
                onChange={actions.handle.id}
            />
            <TextField
                label="Password"
                disabled={disabled}
                value={password}
                onChange={actions.handle.password}
            />
            <Form.Actions error={error}>
                <Button
                    variant='outlined'
                    disabled={disabled}
                    onClick={handleCreate}
                >
                    Create user
                </Button>
            </Form.Actions>
        </Form>
    );
}

export type UserCreateProps = {
    disabled: boolean;
    error: string,
    onCreate: (request: UserCreateRequest) => void
}