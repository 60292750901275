import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiFreeswitch } from "..";
import { Device, DevicesGatewayRegisterRequest, DevicesGatewayUnregisterRequest } from "@narayana/billing-api";

@ApiEntity("Devices", "Methods for manipulate with devices")
export class APIDevices {
  constructor (
    public api: ApiFreeswitch
  ) { }

  @ApiMethod(
    "Method for get device",
    [ "freeswitch#devices" ],
    "divice_id_with_prefix_freeswitch",
    { 
      id: "<id>",
      ip: [ "8.8.8.8" ],
      wallet: "<wallet-id>",
      gateway: [ { id: "aaa" } ],
      provider: {
        id: "<id>",
        plan: 'main', 
        code: { main: '/* code */' },
        gate: { 
          "gate-props": { } 
        },
        isActive: true,
        payload: { 
          "a": 1 
        }
      },
      type: "freeswitch",
      isActive: true,
      payload: { a: 42 }
    } as Device
  )
  async get (id: string): Promise<Device> {
    return this.api.execute(`/devices/${id}`, "GET", null, null);
  }

  @ApiMethod(
    "Method for register devices gateway",
    [ "devices#gateway" ],
    {
      device: "freeswitch:7495120000",
      id: "uinque_connection_id",
      payload: { type: "freeswitch", uri: "", etc: "other or any" }
    } as DevicesGatewayRegisterRequest,
    undefined
  )
  async gatewayRegister (request: DevicesGatewayRegisterRequest): Promise<void> {
    return this.api.execute(`/devices/gateway`, "POST", null, request);
  }

  @ApiMethod(
    "Method for unregister devices gateway",
    [ "devices#gateway" ],
    {
      device: "device_id",
      id: "uinque_connection_id"
    } as DevicesGatewayUnregisterRequest,
    undefined
  )
  async gatewayUnregister (request: DevicesGatewayUnregisterRequest): Promise<void> {
    return this.api.execute(`/devices/gateway`, "DELETE", null, request);
  }
}