import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiPhones } from "..";

@ApiEntity("Numbers", "Methods for manipulate with phone numbers")
export class APINumbers {
  constructor (
    public api: ApiPhones
  ) { }

  @ApiMethod(
    "Method for get random numbers",
    [ "personal", "devices" ],
    {
      spaces: [ "sim", "esim" ],
      prefix: '7495',
      limit: 10
    } as NumbersFilterRequest,
    [
      {
        id: "uuid",
        spaces: "sim",
        number: "",
        parent: "74958272___",
        payload: { }
      }
    ] as PhoneNumber[]
  )
  async list (request: NumbersFilterRequest): Promise<PhoneNumber[]> {
    return this.api.execute(`/invites`, "PUT", null, request);
  }
}

export type NumbersFilterRequest = {
  spaces: string[],
  prefix?: string | null | undefined,
  limit: number
};

export type PhoneNumber<T = PhoneNumberPayload> = {
  id: string,
  spaces: string,
  number: string,
  parent: string,
  payload: T
};

export type PhoneNumberPayload = Record<string, unknown>;