import { Provider } from '@narayana/billing-api'

import { useQuery } from '../../../../hooks';

export function useData (provider: Provider) {
  const [ 
    { id, tab },
    { set: { tab: setTab } }
  ] = useQuery({ id: '', tab: 'main' });
  
  return {
    isNew: id === '',
    tab,
    setTab
  }
}