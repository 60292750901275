import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiBilling } from "..";

@ApiEntity("Directions", "Methods for managing directions")
export class APIDirections {
  constructor (
    public api: ApiBilling
  ) { }

  @ApiMethod(
    "Method for update directions",
    [ "directions#update" ],
    {
      provider: "narayana",
      service: "call",
      directions: [
        {
          title: "Title",
          direction: "1800",
          interval: 60,
          payload: { },
          billing: [
            { currency: "euro", price: 10 }
          ]
        }
      ],
    } as DirectionsUpdateRequest,
    undefined
  )
  async update<T> (request: DirectionsUpdateRequest<T>): Promise<void> {
    return this.api.execute("/directions", "PUT", null, request);
  }

  @ApiMethod(
    "Method for get directions",
    [ "directions#read" ],
    {
      service: "call",
      provider: null,
      is_active: true,
      q: ""
    } as DirectionsListRequest,
    {
      groups: [],
      directions: []
    } as DirectionsListResponse
  )
  async list (filter: DirectionsListRequest): Promise<DirectionsListResponse> {
    return this.api.execute(`/directions`, "GET", filter, null);
  }
}

export type DirectionsUpdateRequest<T = DirectionPayload> = {
  provider: string,
  service: string,
  directions: DirectionsUpdateRequestItem<T>[],
};

export type DirectionsUpdateRequestItem<T = DirectionPayload> = {
  title: string,
  direction: string,
  interval: number,
  payload: T,
  billing: DirectionsUpdateRequestBilling[]
};

export type DirectionsUpdateRequestBilling = {
  currency: string,
  price: number,
}

export type DirectionsListRequest = {
  service: string,
  provider: string | null,
  is_active: boolean | null,
  q: string | null,
};

export type DirectionsListResponse = {
  groups: DirectionGroup[],
  directions: Direction[]
};

export type DirectionGroup = {
  service: string,
  direction: string,
  interval: number,
  billing: DirectionGroupBilling[]
};

export type DirectionGroupBilling = {
  currency: string,
  min: number,
  max: number
};

export type Direction<T = DirectionPayload> = {
  id: string,
  provider: string,
  title: string,
  service: string,
  direction: string,
  interval: number,
  payload: T,
  billing: DirectionBilling[]
}

export type DirectionBilling = {
  currency: string,
  price: number
};

export type DirectionPayload = Record<string, unknown>;

