import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Service } from "@narayana/billing-api";

import { useStyles } from './styles';

export function DirectionServices ({ services, setService }: DirectionServicesProps) {
  const styles = useStyles();

  if (services == null) {
    return (
      <div className={styles.loading}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <div>
      <Typography variant="h3">
        Select service
      </Typography>
      <List>
        {services.map((service, i) => (
          <ListItemButton 
            key={service.id} 
            onClick={() => setService(service.id)}
          >
            <ListItemText>
              {service.title}
            </ListItemText>
          </ListItemButton>
        ))}
      </List>
    </div>
  )
}

export type DirectionServicesProps = {
  services: Service[] | null | undefined,
  setService: (service: string) => void
};