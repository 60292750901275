import { useCallback } from "react";
import { useApi } from "../../hooks/useApi";
import { useForm } from "../../hooks/useForm";
import { useRequest } from "../../hooks/useRequest";
import { InvitesCreateRequest } from "@narayana/invites-api";

export const useData = () => {
  const api = useApi();
  const [
    {
      message,
      currency,
      amount,
    },
    actions
  ] = useForm(
    {
      message: '' as string,
      currency: null as string | null,
      amount: null as number | null,
    }
  );

  const createRequest = useRequest(
    (invite: InvitesCreateRequest) => api.invites.invites.create(invite),
    { debounce: true }
  );

  const onCreateInvite = useCallback(
    ()=>{
      const inviteRequest: InvitesCreateRequest = {message};
      if (currency) {
        inviteRequest.bonus = {currency, amount: amount || 0}
      }
      createRequest.execute(inviteRequest);
    },
    [amount, createRequest, currency, message]);

  return {
    message,
    currency,
    amount,
    result: createRequest.result,
    error: createRequest.error,
    inProgress: createRequest.inProgress,
    onMessageChange: actions.handle.message,
    onBonusCurrencyChange: actions.handle.currency,
    onBonusAmountChange: actions.handle.amount,
    onCreateInvite
  }
};