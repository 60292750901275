import { SetOverdraftRequest } from '@narayana/billing-api';
import { TransferRequest, EmissionRequest, AbsorptionRequest } from '@narayana/billing-api';
import { useState } from 'react';

import { useApi, useQuery, useRequest } from '../../../hooks';

export function useData() {
  const api = useApi();
  const [
    { tab },
    { set: { tab: setTab } }
  ] = useQuery({ tab: 'emission' });

  const [emissionRequestStarted, setEmissionRequestStarted] = useState(false);
  const [overdraftRequestStarted, setOverdraftRequestStarted] = useState(false);
  const [transferRequestStarted, setTransferRequestStarted] = useState(false);
  const [absorptionRequestStarted, setAbsorptionRequestStarted] = useState(false);
  const emissionRequest = useRequest(
    async (request: EmissionRequest) => {
      const result = await api.billing.acts.emission(request);
      setEmissionRequestStarted(true);
      return result;
    }
  );
  const overdraftRequest = useRequest(
    async (request: SetOverdraftRequest) => {
      const result = await api.billing.acts.setOverdraft(request);
      setOverdraftRequestStarted(true);
      return result;
    }
  );
  const transferRequest = useRequest(
    async (request: TransferRequest) => {
      const result = await api.billing.acts.transfer(request);
      setTransferRequestStarted(true);
      return result;
    }
  );
  const absorptionRequest = useRequest(
    async (request: AbsorptionRequest) => {
      const result = await api.billing.acts.absorption(request);
      setAbsorptionRequestStarted(true);
      return result;
    }
  );

  return {
    inProgress:
      emissionRequest.inProgress
      || overdraftRequest.inProgress
      || transferRequest.inProgress
      || absorptionRequest.inProgress,
    tab,
    error: (tab === 'emission')
      ? emissionRequest.error?.message
      : (tab === 'overdraft')
        ? overdraftRequest.error?.message
        : (tab === 'transfer')
          ? transferRequest.error?.message
          : (tab === 'absorption')
            ? absorptionRequest.error?.message
            : '',
    emissionRequestStarted,
    overdraftRequestStarted,
    transferRequestStarted,
    absorptionRequestStarted,
    setEmissionRequestStarted,
    setOverdraftRequestStarted,
    setTransferRequestStarted,
    setAbsorptionRequestStarted,
    setTab,
    executeEmission: emissionRequest.execute,
    executeOverdraft: overdraftRequest.execute,
    executeTransfer: transferRequest.execute,
    executeAbsorption: absorptionRequest.execute,
  }
}