import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { TransferRequest } from "@narayana/billing-api";
import { Form } from "@narayana/ui";
import { useCallback } from "react";
import { useForm } from "../../../../hooks";

export function ActsTransfer({ inProgress, execute, error, transferRequestStarted }: ActsTransferProps) {
    const [
        { walletFrom, walletTo, currency, amount },
        {
            handle: {
                walletFrom: handleWalletFrom,
                walletTo: handleWalletTo,
                currency: handleCurrency,
                amount: handleAmount,
            },

        }
    ] = useForm({
        walletFrom: '',
        walletTo: '',
        currency: '',
        amount: '0',
        transferRequestStarted: false
    });

    const handleExecute = useCallback(
        () => {
            execute({
                from: walletFrom,
                to: walletTo,
                currency,
                amount: parseInt(amount, 10)
            });
        },
        [execute, walletFrom, walletTo, currency, amount]
    );

    return (
        <>
            <Form>
                <TextField
                    id="walletFrom"
                    label="Wallet from"
                    variant="outlined"
                    value={walletFrom}
                    disabled={inProgress}
                    onChange={handleWalletFrom}
                />
                <TextField
                    id="walletTo"
                    label="Wallet to"
                    variant="outlined"
                    value={walletTo}
                    disabled={inProgress}
                    onChange={handleWalletTo}
                />
                <TextField
                    id="currency"
                    label="Currency"
                    variant="outlined"
                    value={currency}
                    disabled={inProgress}
                    onChange={handleCurrency}
                />
                <TextField
                    id="amount"
                    label="Amount"
                    variant="outlined"
                    value={amount}
                    disabled={inProgress}
                    onChange={handleAmount}
                />
                <Form.Actions
                    error={error}
                    success={!inProgress && !error && transferRequestStarted ? "Done" : ""}
                >
                    <Button
                        variant="contained"
                        onClick={handleExecute}
                        disabled={inProgress}
                    >
                        Transfer
                    </Button>
                </Form.Actions>
            </Form>
        </>
    );
}
export type ActsTransferProps = {
    inProgress: boolean;
    execute: (request: TransferRequest) => void;
    error: string;
    transferRequestStarted: boolean;
}