export default `
# Billing database

\`\`\`mermaid
erDiagram
Services {
  id VARCHAR
  title VARCHAR
  isActive BOOLEAN
  payload JSONB
}
Wallets {
  id VARCHAR
  title VARCHAR
  isActive BOOL   
  payload JSONB  
}
WalletUsers {
  wallet VARCHAR
  user VARCHAR  
  group VARCHAR
}
WalletUsers }o--|| Wallets : wallet
Currencies {
  id VARCHAR
  title VARCHAR
  comma INTEGER
  isActive BOOL   
  payload JSONB  
}
Providers {
  id VARCHAR
  ip VARCHAR[]
  title VARCHAR
  isActive BOOL
  payload JSONB
}
DevicesProviders {
  id VARCHAR
  plan VARCHAR
  gate JSONB
  code JSONB
  isActive BOOL
  payload JSONB
}
DevicesProviders }o--|| Providers : id
DevicesProviders }o--|| Plans : plan
ServiceProviders {
  id VARCHAR
  gate JSONB
  isActive BOOL
  payload JSONB
}
ServiceProviders }o--|| Providers : id
Directions {
  id VARCHAR
  provider VARCHAR
  title VARCHAR   
  service VARCHAR
  direction VARCHAR
  interval INTEGER
  isActive BOOL   
  payload JSONB
}
Directions }o--|| ServiceProviders : provider
Directions }o--|| Services : service
DirectionBilling {
  direction VARCHAR
  currency VARCHAR
  price DECIMAL   
  order INTEGER
}
DirectionBilling }o--|| Directions : direction
DirectionBilling }o--|| Currencies : currency
Balance {
  wallet VARCHAR
  currency VARCHAR
  balance DECIMAL
  overdraft DECIMAL
}
Balance }o--|| Wallets : wallet
Balance }o--|| Currencies : currency
ProviderBalance {
  provider VARCHAR
  currency VARCHAR
  balance DECIMAL
}
ProviderBalance }o--|| Providers : provider
ProviderBalance }o--|| Currencies : currency
Plans {
  id VARCHAR
  title VARCHAR
  wallet VARCHAR
  isActive BOOL   
  payload JSONB  
}
Plans }o--|| Wallets : wallet
PlanServices {
  id VARCHAR
  plan VARCHAR
  direction VARCHAR
  interval INTEGER 
  freeThreshold INTEGER
  connectionFeeCurrency VARCHAR
  connectionFeePrice DECIMAL  
  order INTEGER  
  payload JSONB  
}
PlanServices }o--|| Plans : plan
PlanServices }o--|| Directions : direction
PlanServiceBilling {
  planService VARCHAR
  currency VARCHAR
  priceType VARCHAR
  price DECIMAL 
  order INTEGER  
}
PlanServiceBilling }o--|| Currencies : currency
PlanServiceBilling }o--|| PlanServices : planService
Devices {
  id VARCHAR
  deviceProvider VARCHAR
  ip VARCHAR[]
  wallet VARCHAR
  gateway JSONB[]
  isActive BOOL
  payload JSONB
}
Devices }o--|| DevicesProviders : deviceProvider
Devices }o--|| Wallets : wallet
DeviceUsers {
  device VARCHAR
  user VARCHAR
  group VARCHAR
}
DeviceUsers }o--|| Devices : device
Connections {
  id VARCHAR
  secret VARCHAR
  device VARCHAR
  planServices VARCHAR
  isFeePaid BOOLEAN
  start TIMESTAMP
  processed TIMESTAMP
  due TIMESTAMP
  closeReason VARCHAR
  payload JSONB
}
Connections }o--|| Devices : device
DevicePlans {
  device VARCHAR
  plan VARCHAR
  order INTEGER
  due TIMESTAMP  
  payload JSONB  
}
DevicePlans }o--|| Devices : device
DevicePlans }o--|| Plans : plan
Withdraws {
  id VARCHAR
  secret VARCHAR
  payer VARCHAR
  recipient VARCHAR  
  currency VARCHAR  
  amount DECIMAL
  status VARCHAR
  start TIMESTAMP
  due TIMESTAMP
  payload JSONB
}
Withdraws }o--|| Wallets : payer
Withdraws }o--|| Wallets : recipient
Withdraws }o--|| Currencies : currency
History {
  date TIMESTAMP
  session VARCHAR
  type VARCHAR
  payload JSONB
}
\`\`\`
`