import React from "react";
import { useQuery } from "../../../../hooks";

export function useMdEditor () {
  const [ { tab }, queryActions ] = useQuery({ tab: 'editor' as EditorTab });
  const [ value, setValue ] = React.useState(localStorage.getItem(KEY) || "");
  
  const onTabChange = queryActions.set.tab;
  const handleTabChange = React.useCallback(
    (_: unknown, tab: string) => onTabChange(tab as EditorTab),
    [ onTabChange ]
  );
  const handleValueChange = React.useCallback(
    (value: string) => {
      localStorage.setItem(KEY, value);
      setValue(value);
    },
    [ setValue ]
  );

  return {
    tab,
    value,
    handleTabChange,
    handleValueChange
  }
}

export type EditorTab = 'editor' | 'preview' | 'help';
const KEY = "md-editor.value";