import { Provider } from "@narayana/billing-api"

import { useData } from "./useData"
import { useStyles } from "./styles"
import { ProviderTabMain } from "./TabMain";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import React from "react";
import Box from "@mui/material/Box";
import { ProviderTabDevices } from "./TabDevices";
import { ProviderTabServices } from "./TabServices";

export const ProviderEdit = ({ provider, loading }: ProviderEditProps) => {
  const { isNew, tab, setTab } = useData(provider);
  const styles = useStyles();

  const onTabChange = React.useCallback((_: unknown, value: string) => {
    setTab(value);
  }, [ setTab ]);

  if (isNew) {
    return (
      <div className={styles.root}>
        <ProviderTabMain provider={provider} loading={loading} />
      </div>
    )
  }

  return (
    <div className={styles.root}>
      <Tabs value={tab} onChange={onTabChange}>
        <Tab label="Main" value="main" />
        <Tab label="Devices" value="devices" />
        <Tab label="Services" value="services" />
      </Tabs>
      <Box sx={{ marginTop: 2 }}>
        <RenderTab tab={tab} provider={provider} loading={loading} />
      </Box>
    </div>
  )
}

export type ProviderEditProps = {
  provider: Provider,
  loading: boolean
};

const RenderTab = ({ tab, provider, loading }: RenderTabProps) => {
  switch (tab) {
    case 'main':
      return <ProviderTabMain key={provider?.id} provider={provider} loading={loading} />;

    case 'devices':
      return <ProviderTabDevices key={provider?.id} provider={provider} loading={loading} />;

    case 'services':
      return <ProviderTabServices key={provider?.id} provider={provider} loading={loading} />;

      default:
      return <div>Unhandled tab {JSON.stringify(tab)}</div>;
  }
}

type RenderTabProps = {
  tab: string,
  provider: Provider,
  loading: boolean
};