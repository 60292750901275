export default `# Withdraws

Methods for withdraw money for services.

## SMS example

### Debit

When sending SMS, we first debit money from the user's wallet using the method

\`\`\`method:billing_withdraws_create
\`\`\`

### Enrollment

If the sms sending was successful, we credit the money to the plan wallet

\`\`\`method:billing_withdraws_apply
\`\`\`

### Cancel

If the service was not provided, we return the money to the user\’s wallet

\`\`\`method:billing_withdraws_cancel
\`\`\`

### Timeout

If the SMS sending service managed to create a withdraw and then crashed 
(apply/cancel were not called).
After a specified dueInterval, the money will be returned to the user\’s wallet automatically
`