import { ApiBilling } from "@narayana/billing-api";
import { ApiSSO } from "@narayana/sso-api";
import { ApiPhones } from "@narayana/phones-api";
import { ApiInvites } from "@narayana/invites-api";
import { ApiPM } from "@narayana/pm-api";
import { ApiNC } from "@narayana/nc-api"
import { ApiPublic } from "@narayana/public-api"
import { ApiFreeswitch } from "@narayana/freeswitch-api"
import { ApiGSM } from "@narayana/gsm-api"
import { ApiSMS } from "@narayana/sms-api"

import { ApiAdmin } from "./admin";

export function useApi() {
  return APIInstance;
}

export const APIInstance: API = {
  admin: new ApiAdmin("/api/admin"),
  public: new ApiPublic("/api/public"),
  sso: new ApiSSO("/api/sso"),
  billing: new ApiBilling("/api/billing"),
  invites: new ApiInvites("/api/invites"),
  phones: new ApiPhones("/api/phones"),
  pm: new ApiPM("/api/pm"),
  nc: new ApiNC("api/nc"),
  freeswitch: new ApiFreeswitch("api/freeswitch"),
  gsm: new ApiGSM("api/gsm"),
  sms: new ApiSMS("api/sms")
};

export type API = {
  admin: ApiAdmin,
  public: ApiPublic,
  sso: ApiSSO,
  billing: ApiBilling,
  invites: ApiInvites,
  pm: ApiPM,
  phones: ApiPhones,
  nc: ApiNC,
  freeswitch: ApiFreeswitch,
  gsm: ApiGSM,
  sms: ApiSMS
};