import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LockPersonIcon from '@mui/icons-material/LockPerson';
import React from "react";
import { Loading } from "@narayana/ui/target/Loading";
import { Permission } from "@narayana/sso-api";

export const PermissionListItem = (props: PermissionListItemProps) => {
  const onClick = React.useCallback(
    () => { !props.disabled && props.onClick(props.permission) },
    [props.permission, props.onClick]
  );
  const onDeleteClick = React.useCallback((e)=>{
    props.onDelete(props.permission);
  },[props.permission, props.onDelete])
  return (
    <ListItem 
      disabled={props.disabled}
      secondaryAction={
        <IconButton
          edge="end" aria-label="delete"
          onClick={onDeleteClick}
          disabled={props.disabled || props.loading}
        >
          <DeleteIcon />
        </IconButton>
      }
    >
      <ListItemButton
        disabled={props.disabled || props.loading }
        onClick={onClick}
      >
        <ListItemIcon>
          <Loading variant="circular" loading={props.loading} width={40} height={40}>
            <LockPersonIcon />
          </Loading>
        </ListItemIcon>
        <ListItemText
          primary={
            <Loading loading={props.loading} height={24} width={85}>
              {'Entity: ' + props.permission.entity}
            </Loading>}
          secondary={
            <Loading loading={props.loading} height={20} width={210}>
              {'Permission: ' + props.permission.permission}
            </Loading>}
        />
      </ListItemButton>
    </ListItem>
  );
}
export type PermissionListItemProps = {
  permission: Permission;
  disabled: boolean;
  loading: boolean;
  onClick: (permission: Permission) => void;
  onDelete: (permission: Permission) => void;
}

export default PermissionListItem;