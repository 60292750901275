import { useCallback } from "react";
import { GridColDef } from "@mui/x-data-grid";

import { useForm } from "../../../../hooks"

export const useImport = () => {
  const [ 
    { tab, data, dataColumns, code }, 
    { 
      set: { 
        tab: setTab,
        data: setData,
        dataColumns: setDataColumns,
        code: setCode,
      } 
    } 
  ] = useForm({ 
    tab: 'data',
    data: [] as string[][], 
    dataColumns: [] as GridColDef[],
    code: DEFAULT_CODE, 
  });

  const handleTabChange = useCallback((_: unknown, value: string) => {
    setTab(value);
  }, [ setTab ]);

  return {
    tab,
    data,
    dataColumns,
    code,
    setData,
    setDataColumns,
    setCode,
    handleTabChange
  }
}

const DEFAULT_CODE = `const DIRECTION_COLUMN = 0;
const TITLE_COLUMN = 1;
const PRICE_COLUMN = 2;
const INTERVAL_COLUMN = 3;

/**
* @param {string[]} row
* @return {Direction | null | undefined}
*/
function processRow(row, index) {
  const title = row[TITLE_COLUMN];
  const direction = row[DIRECTION_COLUMN];
  const price = parseFloat(row[PRICE_COLUMN]);
  let interval = parseInt(row[INTERVAL_COLUMN]);
  switch (interval) {
    case 1:
      interval = 60;
      break;

    case 60:
      interval = 1;
      break;
  }

  if (
    direction.length === 0
    || isNaN(parseInt(direction))
    || isNaN(price)
    || isNaN(interval)
  ) {
    return null;
  }
  
  return {
    title,
    direction,
    interval,
    payload: { },
    billing: [
      { currency: "€", price: price }
    ]
  }
}`