import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiBilling } from "..";

@ApiEntity("Services", "Methods for managing services")
export class APIServices {
  constructor (
    public api: ApiBilling
  ) { }

  @ApiMethod(
    "Method for create service",
    [ "services#create" ],
    {
      id: "call",
      title: "call",
      payload: { a: 42 }
    } as ServiceCreateRequest,
    { 
      id: "<id>",
      title: "call",
      isActive: true,
      payload: { a: 42 }
    } as Service
  )
  async create<T> (request: ServiceCreateRequest<T>): Promise<Service<T>> {
    return this.api.execute("/services", "POST", null, request);
  }

  @ApiMethod(
    "Method for update service",
    [ "services#update" ],
    {
      id: "call",
      title: "call",
      payload: { a: 42 }
    } as ServiceUpdateRequest,
    undefined
  )
  async update (request: ServiceUpdateRequest): Promise<void> {
    return this.api.execute("/services", "PUT", null, request);
  }

  @ApiMethod(
    "Method for delete service",
    [ "services#delete" ],
    "<service-id>",
    undefined
  )
  async delete (id: string): Promise<void> {
    return this.api.execute(`/services/${id}`, "DELETE", null, null);
  }

  @ApiMethod(
    "Method for restore service",
    [ "services#restore" ],
    "<service-id>",
    undefined
  )
  async restore (id: string): Promise<void> {
    return this.api.execute(`/services/${id}/restore`, "PUT", null, null);
  }

  @ApiMethod(
    "Method for get service",
    [ "services#read" ],
    "<service-id>",
    { 
      id: "<id>",
      title: "call",
      isActive: true,
      payload: { a: 42 }
    } as Service
  )
  async get (id: string): Promise<Service> {
    return this.api.execute(`/services/${id}`, "GET", null, null);
  }

  @ApiMethod(
    "Method for counting number of services by filter",
    [ "services#read" ],
    {
      q: 'ca',
      is_active: true
    } as ServicesListFilterCount,
    { count: 2 } as { count: number }
  )
  async count (filter: ServicesListFilterCount): Promise<{ count: number }> {
    return this.api.execute(`/services/count`, "GET", filter, null);
  }

  @ApiMethod(
    "Method for get services list",
    [ "services#read" ],
    {
      q: 'ca',
      is_active: true,
      limit: 50,
      offset: 0,
    } as ServicesListFilterCount,
    [ 
      { 
        id: "<id>",
        title: "call",
        isActive: true,
        payload: { a: 42 }
      } 
    ] as Service[]
  )
  async list (filter: ServicesListFilter): Promise<Service[]> {
    return this.api.execute(`/services`, "GET", filter, null);
  }
}

export type Service<T = ServicePayload> = {
  id: string;
  title: string;
  isActive: boolean;
  payload: T;
}

export type ServiceCreateRequest<T = ServicePayload> = {
  id: string;
  title: string;
  payload: T;
}

export type ServiceUpdateRequest<T = ServicePayload> = {
  id: string;
  title: string;
  payload: T;
}

export type ServicesListFilterCount = {
  q?: string | null,
  is_active?: boolean | null,
}

export type ServicesListFilter = ServicesListFilterCount & {
  limit: number,
  offset: number,
};

export type ServicePayload = Record<string, unknown>;