import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React from "react";
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { Loading } from "@narayana/ui";
import { Service } from "@narayana/billing-api";

export const ServiceListItem = (props: ServiceListItemProps) => {
  const service = props.service;
  const propsOnClick = props.onClick;
  const onClick = React.useCallback(
    () => { propsOnClick(service) },
    [service, propsOnClick]
  );

  return (
    <ListItem disabled={props.disabled}>
      <ListItemButton
        disabled={props.disabled || props.loading}
        onClick={onClick}
      >
        <ListItemIcon>
          <Loading variant="circular" loading={props.loading} width={40} height={40}>
            <Tooltip title={props.service.isActive ? "Service enabled" : "Service disabled"}>
              <ElectricalServicesIcon />
            </Tooltip>
          </Loading>
        </ListItemIcon>
        <ListItemText
          primary={
            <Loading loading={props.loading} height={24} width={280}>
              {`ID: ${props.service.id}`}
            </Loading>}
          secondary={
            <Loading loading={props.loading} height={20} width={180}>
              <Typography
                sx={props.service.isActive ? {} : { textDecoration: 'line-through' }}
              >
                {`Title: ${props.service.title}.`}
              </Typography>
            </Loading>}
        />
      </ListItemButton>
    </ListItem>
  );
}

export type ServiceListItemProps = {
  service: Service;
  disabled: boolean;
  loading: boolean;
  onClick: (service: Service) => void;
  onDelete: (service: Service) => void;
}

