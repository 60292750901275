export const concept = `# Concept

## Teams & users

Each team has an owner who can manage the public keys of the repositories and team members.

There are also administrators who can manage packages (not public keys).

## Packages & versions

The owner and administrators of the team can manage packages. 

Also, for each package, 2 user groups are created: 

- project administrators, who can manage package properties and delete versions
- project developers, who can only create new versions of package

## Signature

When publishing each version, you need to specify a valid hash, 
this is a signature that guarantees the client that we have not replaced the code with our own.

Therefore, despite the fact that developers can push new versions, they still must have the 
private part of the project key.

## Publish new version

Therefore, the publishing process looks like this: we take the current time, and then we 
calculate using the RSA-256 (RSA-512?) hash from the string "publish_time_utc:version_id:data:payload".

Next, the server checks that the publish time is greater than the time of the previous version of the package, 
the signature, and if everything is fine, publishes the new version.`;