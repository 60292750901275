import Avatar from "@mui/material/Avatar";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { MethodDescription } from "@narayana/api";
import React from "react";
import { useForm } from "../../hooks";
import { MethodDialog } from "./Dialog";
import { MethodIcon } from "./Icon";

export function Method({ method, request }: MethodProps) {
  const [ { open }, { set: { open: setOpen } } ] = useForm({ open: false });

  const handleOpen = React.useCallback(
    () => setOpen(true),
    [ setOpen ]
  );
  const handleClose = React.useCallback(
    () => setOpen(false),
    [ setOpen ]
  );

  return (
    <>
      <ListItemButton onClick={handleOpen}>
        <ListItemAvatar>
          <Avatar>
            <MethodIcon methodName={method?.name || 'Method not found'} />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={`${method?.entity?.name}/${method?.name}`}
          secondary={`${(method?.permissions || []).join(', ')}`}
        />
      </ListItemButton>
      <MethodDialog 
        method={method} 
        request={request}
        open={open} 
        onClose={handleClose} 
      />
    </>
  )
}

export type MethodProps = {
  method: MethodDescription | null,
  request?: unknown | null
};