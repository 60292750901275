import Alert from '@mui/material/Alert';
import { useStyles } from './styles'

function FormActions({ error, info, success, warning, className, children }: FormActionProps) {
  const styles = useStyles();

  return (
    <div className={`${styles.root}${className ? ` ${className}` : ''}`}>
      <div className={styles.error}>
        {error ? <Alert severity="error">{error}</Alert> : null}
      </div>
      <div className={styles.error}>
        {info ? <Alert severity="info">{info}</Alert> : null}
      </div>
      <div className={styles.error}>
        {success ? <Alert severity="success">{success}</Alert> : null}
      </div>
      <div className={styles.error}>
        {warning ? <Alert severity="warning">{warning}</Alert> : null}
      </div>
      <div>
        {children}
      </div>
    </div>);
}

export type FormActionProps = React.PropsWithChildren<{
  error?: string,
  info?: string,
  success?: string,
  warning?: string,
  className?: string
}>;

export default FormActions;