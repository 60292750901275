import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    position: 'relative',
    display: "flex",
    flexDirection: "column"
  },
  inputContainer: {
    display: "flex",
    flexDirection: 'column',
    alignItems:'end',
    "& > *":{
      margin: 8
    },
    zIndex: 100
  },
  filter: {
    position: 'absolute',
    overflow: 'visible',
    height: 0,
    width: '90%',
    top: 72,
    right: 8,
    margin: '0 auto',
    zIndex: 100
  },
  filterForm: {
    padding: 16,
    backgroundColor: 'white',
    zIndex: 100,
    borderRadius: 4,
    boxShadow: 'rgb(0 0 0 / 20%) 0px 11px 15px -7px, rgb(0 0 0 / 14%) 0px 24px 38px 3px, rgb(0 0 0 / 12%) 0px 9px 46px 8px',
    '& > *': {
      margin: '8px 0 8px 0'
    }
  }
});