import List from "@mui/material/List";
import TextField from "@mui/material/TextField";
import { Filter, Form } from "@narayana/ui";
import React from "react";
import { useForm, useQuery } from "../../../hooks";
import { useDevApi } from "./useDevApi"
import { Method } from "../../../ui";

export function DevApi() {
  const [{ filterVisible }, formActions] = useForm({ filterVisible: false });
  const [{ q, service, entity, permission, page }, queryActions] = useQuery({
    q: '',
    service: '',
    entity: '',
    permission: '',
    page: 1,
    selected: null as string | null 
  });

  const { visibleMethods } = useDevApi({ q, service, entity, permission });

  const handleDeleteFilterKey = React.useCallback(
    (key: string) => {
      switch (key) {
        case 'service':
          queryActions.set.service('');
          break;

        case 'entity':
          queryActions.set.entity('');
          break;

        case 'permission':
          queryActions.set.permission('');
          break;

        default:
          throw new Error(`Unhandled key: ${JSON.stringify(key)}`)
      }
    },
    [queryActions.set]
  );
  
  return (
    <div>
      <Filter
        query={q}
        filterVisible={filterVisible}
        page={page}
        maxPage={1}
        filter={{
          service: service || null,
          entity: entity || null,
          permission: permission || null
        }}
        onQueryChange={queryActions.set.q}
        onPageChange={queryActions.set.page}
        onFilterVisibleChange={formActions.set.filterVisible}
        onDeleteFilterKey={handleDeleteFilterKey}
      >
        <Form>
          <TextField
            fullWidth
            label="Service"
            value={service}
            onChange={queryActions.handle.service}
          />
          <TextField
            fullWidth
            label="Entity"
            value={entity}
            onChange={queryActions.handle.entity}
          />
          <TextField
            fullWidth
            label="Permission"
            value={permission}
            onChange={queryActions.handle.permission}
          />
        </Form>
      </Filter>
      <List>
        {visibleMethods.map((x) => (<Method key={x.id} method={x} />))}
      </List>
    </div>
  )
}