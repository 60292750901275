import { Service } from "@narayana/billing-api";
import { useCallback, useEffect } from "react";
import { useApi, useForm, useQuery } from "../../../hooks"

export const useDirections = () => {
  const api = useApi();
  const [ 
    { error, services, importVisible }, 
    { 
      set: { 
        error: setError,
        services: setServices,
        importVisible: setImportVisible
      } 
    } 
  ] = useForm({ error: null, services: null, importVisible: false } as ServicesForm);
  const [
    { service }, 
    {
      set: { 
        service: setService,
      } 
    }
  ] = useQuery({ service: null as string | null })

  useEffect(() => {
    if (services != null) {
      return;
    }
    
    api.billing.services
      .list({ limit: 100, offset: 0 })
      .then(result => setServices(result))
      .catch(e => setError(e.message));
  }, [ services, api.billing.services, setError, setServices ]);

  const handleClearError = useCallback(
    () => { 
      setService(null); 
      setServices(null); 
    },
    [ setService, setServices ]
  );
  const handleImportShow = useCallback(
    () => setImportVisible(true),
    [ setImportVisible ]
  );
  const handleImportHide = useCallback(
    () => setImportVisible(false),
    [ setImportVisible ]
  );

  return {
    error,
    service,
    services,
    importVisible,
    setService,
    handleClearError,
    handleImportShow,
    handleImportHide
  }
}

type ServicesForm = {
  error: string | null,
  services: Service[] | null,
  importVisible: boolean
};