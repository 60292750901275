import AddIcon from "@mui/icons-material/Add";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React from "react";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Filter, Form } from "@narayana/ui";
import { User } from "@narayana/sso-api";
import { UserEdit, UserCreate, UserListItem } from "../../../ui/billing/users";
import { UsersListFilter } from "@narayana/sso-api";
import CloseIcon from "@mui/icons-material/Close";

import { Entity } from "../../../ui";
import { List } from "../../../ui/List"
import { MainAction } from "../../../ui";
import { useApi, useForm, useQuery, useRequest } from "../../../hooks"
import { UserCreateRequest } from "@narayana/sso-api";
import { AppBar, Dialog, DialogContent, DialogTitle, IconButton, Toolbar } from "@mui/material";

const PAGE_SIZE = 10;

export function SSOUsers() {
  const api = useApi();
  const [{ filterVisible }, actions] = useForm({ filterVisible: false });
  const [{ id, page, q, status, isActive }, queryActions] = useQuery({
    id: null as string | null,
    page: 1,
    q: '',
    status: '' as string | null,
    isActive: null as string | null
  });
  const countRequest = useRequest(
    (filter: UsersListFilter) => api.sso.users.count(filter),
    { debounce: true }
  );
  const filter = React.useMemo(() => ({
    id,
    q: q || null,
    status: status == null || status === '' ? null : parseInt(status, 10),
    is_active: isActive == null ? null : isActive === 'true',
    limit: PAGE_SIZE,
    offset: (page - 1) * PAGE_SIZE
  }), [id, q, status, isActive, page]);

  const countRequestExecute = countRequest.execute;
  React.useEffect(() => { countRequestExecute(filter) }, [filter, countRequestExecute]);

  const handleIsActiveChange = React.useCallback(
    (_: unknown, value: boolean) => queryActions.set.isActive(value.toString()),
    [queryActions.set]
  );
  const fetchEntities = React.useCallback(
    (filter: UsersListFilter) => api.sso.users.list(filter),
    [api.sso.users]
  );
  const handleDeleteFilterKey = React.useCallback(
    (key: string) => {
      switch (key) {
        case 'status':
          queryActions.set.status('');
          break;

        case 'isActive':
          queryActions.set.isActive(null);
          break;

        default:
          throw new Error(`Unhandled filter key: ${JSON.stringify(key)}`)
      }
    },
    [queryActions.set]
  );
  const handleIdClear = React.useCallback(
    () => queryActions.set.id(null),
    [queryActions.set]
  );
  const handleListClick = React.useCallback(
    (user: User) => queryActions.set.id(user.id),
    [queryActions.set]
  );
  const handleUserDelete = React.useCallback(
    async (id: string) => {
      await api.sso.users.delete(id);
      handleIdClear();
    },
    [api.sso.users, handleIdClear]
  );
  const handleUserRestore = React.useCallback(
    async (id: string) => {
      await api.sso.users.restore(id);
      handleIdClear();
    },
    [api.sso.users, handleIdClear]
  );
  const onCreate = React.useCallback(
    () => queryActions.set.id(''),
    [queryActions.set]
  );
  const handleCreate = useRequest(
    async (request: UserCreateRequest) => {
      const user = await api.sso.users.create(request);
      queryActions.set.id(user.id);
    }
  );
  const handleNewUserDialogClose = React.useCallback(
    () => handleIdClear(),
    [handleIdClear]
  );

  return (
    <>
      <Typography variant="h4" sx={{ display: 'flex' }}>
        <div style={{ flex: 1 }}>
          Users
        </div>
      </Typography>
      <Filter
        query={q || ''}
        filterVisible={filterVisible}
        page={page}
        maxPage={Math.max(1, Math.ceil((countRequest.result?.count || 1) / PAGE_SIZE))}
        filter={{
          status: status == null || status === '' ? null : `Status:${status}`,
          isActive: isActive == null ? null : (isActive === 'true' ? 'Active' : 'Inactive'),
        }}
        onQueryChange={queryActions.set.q}
        onFilterVisibleChange={actions.set.filterVisible}
        onPageChange={queryActions.set.page}
        onDeleteFilterKey={handleDeleteFilterKey}
      >
        <Form>
          <TextField
            fullWidth
            label="Status"
            type="number"
            value={status || 0}
            onChange={queryActions.handle.status}
          />
          <FormGroup>
            <FormControlLabel
              label="Is Active"
              control={<Switch checked={isActive === 'true'} onChange={handleIsActiveChange} />}
            />
          </FormGroup>
        </Form>
      </Filter>
      <List
        fetch={fetchEntities}
        filter={filter}
        entityKey="user"
        Container={UserListItem}
        ContainerProps={{
          disabled: false,
          onClick: handleListClick
        }}
      />
      {(id !== '')
        ? <Entity
          key={id}
          id={id}
          componentKey="user"
          get={(id: string) => api.sso.users.get(id)}
          save={async (user: User) => {
            await api.sso.users.update(user);
            handleIdClear();
          }}
          Component={UserEdit}
          ComponentProps={{
            onDelete: handleUserDelete,
            onRestore: handleUserRestore
          }}
          onClose={handleIdClear}
        />
        : null
      }
      <Dialog fullScreen open={id === ''} >
        <DialogTitle sx={{ height: 64 }}>
          <AppBar position="absolute">
            <Toolbar>
              <Typography sx={{ flex: 1 }}>
                {'New user'}
              </Typography>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => handleNewUserDialogClose()}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
        </DialogTitle>
        <DialogContent>
          <UserCreate
            onCreate={handleCreate.execute}
            disabled={handleCreate.inProgress}
            error={(handleCreate.error as Error)?.message || ''}
          />
        </DialogContent>
      </Dialog>
      <MainAction onClick={onCreate}>
        <AddIcon />
      </MainAction>
    </>
  )
}