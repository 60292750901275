import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Pagination from '@mui/material/Pagination';
import React from 'react';
import TextField from '@mui/material/TextField';
import { useStyles } from './styles';
import Grow from '@mui/material/Grow';
import Chip from '@mui/material/Chip';
import ClickAwayListener from '@mui/material/ClickAwayListener';

export const Filter = (props: FilterProps) => {
  const styles = useStyles();
  const handlePageChange = React.useCallback(
    (_: unknown, newPage: number) => props.onPageChange && props.onPageChange(newPage),
    [props.onPageChange]
  );
  const handleQueryChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => 
      props.onQueryChange && props.onQueryChange(event.target.value),
    [props.onQueryChange]
  );
  const handleFilterVisibleClicked = React.useCallback(
    (_: unknown) => props.onFilterVisibleChange && props.onFilterVisibleChange(!props.filterVisible),
    [props.onFilterVisibleChange, props.filterVisible]
  );
  const handleFilterClose = React.useCallback(
    (_: unknown) => (props.onFilterVisibleChange && props.filterVisible) && props.onFilterVisibleChange(false),
    [props.onFilterVisibleChange, props.filterVisible]
  );

  return (
    <ClickAwayListener onClickAway={handleFilterClose}>
      <div className={styles.root}>
        <div className={styles.inputContainer}>
          <TextField
            fullWidth
            label={props.withoutInput ? "" : "Search query"}
            variant={"outlined"}
            value={props.query || ''}
            disabled={props.withoutInput}
            onChange={handleQueryChange}
            InputProps={{
              startAdornment: (
                <>
                  {Array.from(Object.keys(props.filter || {}))
                    .filter((x) => props.filter[x] !== '' && props.filter[x] != null)
                    .map(key => (
                      <Chip 
                        key={key} 
                        label={props.filter[key].toString()} 
                        onDelete={() => props.onDeleteFilterKey(key)} 
                      />
                    ))}
                </>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleFilterVisibleClicked}>
                    {props.filterVisible ? <FilterListOffIcon /> : <FilterListIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <div className={styles.filter}>
            <Grow in={props.filterVisible}>
              <div className={styles.filterForm}>
                <div>
                  {props.children}
                </div>
              </div>
            </Grow>
          </div>
          <Pagination
            count={props.maxPage}
            page={props.page}
            color="primary"
            variant="outlined"
            onChange={handlePageChange}
          />
        </div>
      </div>
    </ClickAwayListener>
  );
};

export type FilterProps = React.PropsWithChildren & {
  query?: string,
  filterVisible: boolean,
  page: number,
  maxPage: number,
  filter?: Record<string, unknown>,
  withoutInput?: boolean,
  onQueryChange?: (query: string) => void,
  onPageChange?: (page: number) => void,
  onFilterVisibleChange?: (isVisible: boolean) => void,
  onDeleteFilterKey?: (key: string) => void
}

export default Filter;