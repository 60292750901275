import ArticleIcon from '@mui/icons-material/Article';
import WebhookIcon from '@mui/icons-material/Webhook';
import Alert from "@mui/material/Alert";
import Avatar from '@mui/material/Avatar';
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { ArticleDescription } from '@narayana/api';
import { EntityDescription, ServiceDescription } from "@narayana/api";
import { routeLink, useGoto } from "../../../hooks";
import { MdComponent } from "../../../ui";
import { Article } from './Article';

export function DevService ({ serviceId, articleId, service, entities }: DevServiceProps) {
  const { goto } = useGoto();
  const articles = (service.articles || []) as ArticleDescription[];

  if (service == null) {
    return (
      <div>
        <Alert severity="error" sx={{ marginBottom: 2 }}>
          Service {JSON.stringify(serviceId)} not found
        </Alert>
        <Link href={routeLink({ page: "dev/services", query: { } })}>
          BACK TO SERVICES
        </Link>
      </div>
    )
  }

  if (articleId !== '') {
    return <Article article={articles.find(({ route }) => route === articleId)} />
  }

  return (
    <div>
      <MdComponent md={service.description} />
      {articles.length === 0 
        ? <></>
        : (
          <>
            <Typography variant="h5">
              Articles
            </Typography>
            <List>
              {articles.map((article) => (
                <ListItemButton 
                  key={article.name}
                  onClick={() => goto({ 
                    page: `dev/services/${serviceId}/${article.route}`, 
                    query: { } 
                  })}
                >
                  <ListItemAvatar>
                    <Avatar>
                      <ArticleIcon />
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={article.name} />
                </ListItemButton>
              ))}
            </List>
          </>
        )}
      {entities.length === 0
        ? <></>
        : (
          <>
            <Typography variant="h5">
              Entities
            </Typography>
            <List>
            {entities.map((entity) => (
              <ListItemButton 
                key={entity.name}
                onClick={() => goto({ 
                  page: `dev/api`, 
                  query: { 
                    service: serviceId,
                    entity: entity.name
                  } 
                })}
              >
                <ListItemAvatar>
                  <Avatar>
                    <WebhookIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText 
                  primary={entity.name} 
                  secondary={`${entity.description} ${entityPermissions(entity)}`}
                />
              </ListItemButton>
            ))}
          </List>
          </>
        )
      }
    </div>
  )
}

const entityPermissions = (entity: EntityDescription): string => {
  const result = new Set<string>();
  for (const method of entity.methods) {
    for (const permission of method.permissions) {
      result.add(permission);
    }
  }

  const resultArr = Array.from(result);
  let hasMore = false;
  if (resultArr.length > 3) {
    resultArr.length = 3;
    hasMore = true;
  }

  return result.size === 0
    ? ''
    : `(permissions: ${resultArr.join(',')}${hasMore ? '...' : ''})`
}

export type DevServiceProps = {
  service: ServiceDescription,
  serviceId: string,
  articleId: string,
  entities: EntityDescription[]
};