import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
  },
  children: {
    maxWidth: 1024,
    margin: '0 auto',
    paddingBottom: 16
  }
});