import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import WalletIcon from '@mui/icons-material/Wallet';
import { Loading } from "@narayana/ui/target/Loading";
import { WalletListItem } from "@narayana/billing-api";
import { useCallback } from "react";

export function WalletListItemComponent({ wallet, onClick, disabled, loading }: WalletListItemComponentProps) {
  const handleClick = useCallback(
    () => { onClick(wallet) },
    [onClick, wallet]
  );
  return (
    <>
      <ListItem disabled={disabled} >
        <ListItemButton
          disabled={disabled || loading}
          onClick={handleClick}
        >
          <ListItemIcon>
            <WalletIcon />
          </ListItemIcon>
          <ListItemText
            primary={
              <Loading loading={loading} height={24} width={280}>
                <Tooltip title={
                  wallet.isActive ? "" : "Deleted"
                }
                >
                  <Typography
                    sx={wallet.isActive ? {} : { textDecoration: 'line-through' }}
                  >
                    {wallet.title}
                  </Typography>
                </Tooltip>
              </Loading>
            }
          />
        </ListItemButton>
      </ListItem>

    </>
  );
}

export type WalletListItemComponentProps = {
  wallet: WalletListItem;
  disabled: boolean;
  loading: boolean;
  onClick: (wallet: WalletListItem) => void;
}