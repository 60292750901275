import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiSMS } from "..";

@ApiEntity("SMS", "Methods for managing SMS")
export class APIMessages {
  constructor(public api: ApiSMS) {}

  @ApiMethod(
    "Method for sending SMS",
    ["personal#*", "personal#sms"],
    {
      from: "123456789",
      to: "123456789",
      body: { text: "message text" },
    } as SMSSendRequest,
    {
      id: "<uuid>",
      from: "123456789",
      to: "123456789",
      date: new Date("01.01.2024 12:20"),
      body: { text: "message text" },
      status: "delivered",
    } as SMSServerResponse
  )
  async send(request: SMSSendRequest): Promise<SMSServerResponse> {
    return await this.api.execute(`/sms`, "POST", null, request);
  }

  @ApiMethod(
    "Method for delete sms from server",
    ["personal#*", "personal#sms"],
    "sms-id>",
    undefined
  )
  async delete(id: string): Promise<void> {
    await this.api.execute(`/sms/${id}`, "DELETE", null, null);
  }

  @ApiMethod(
    "Method to get SMS from server by ID",
    ["personal#*", "personal#sms"],
    "<sms-id>",
    {
      id: "<uuid>",
      from: "123456789",
      to: "123456789",
      body: { text: "message text" },
      date: new Date("01.01.2024 12:20"),
      status: "delivered",
    } as SMSServerResponse
  )
  async get(id: string): Promise<SMSServerResponse> {
    return await this.api.execute(`/sms/${id}`, "GET", null, null);
  }

  @ApiMethod(
    "Method for counting the number of sms by criteria (all parameters are optional)",
    ["personal#*", "personal#sms"],
    {
      q: "Search filter",
      from: "123456789",
      to: "123456789",
      beforeDate: new Date("02.01.2024 12:00"),
      afterDate: new Date("01.01.2024 00:00"),
    },
    { count: 42 }
  )
  async count(request: SMSListRequest): Promise<{ count: number }> {
    return await this.api.execute(`/sms/count`, "GET", request, null);
  }

  @ApiMethod(
    "Method for getting a list of user SMS",
    ["personal#*", "personal#sms"],
    {
      q: "Search filter",
      from: "123456789",
      to: "123456789",
      beforeDate: new Date("02.01.2024 12:00"),
      afterDate: new Date("01.01.2024 00:00"),
      limit: 10,
      offset: 0,
    },
    [
      {
        id: "<uuid>",
        from: "123456789",
        to: "123456789",
        body: { text: "message text" },
        date: new Date("01.01.2024 12:20"),
        status: "delivered",
      },
    ] as SMSServerResponse[]
  )
  async list(request: SMSListRequest): Promise<SMSServerResponse[]> {
    return await this.api.execute(`/groups`, "GET", request, null);
  }
}

export type SMSSendRequest = {
  from: string;
  to: string;
  body: Record<string, any>;
};

export type SMSServerResponse = SMSSendRequest & {
  id: string;
  date: Date;
  status: SMSStatus;
};

export type SMSStatus = "error" | "delivered" | "draft" | "sent";

export type SMSCountRequest = {
  q?: string | null;
  from?: string | null;
  to?: string | null;
  beforeDate?: Date;
  afterDate?: Date;
};

export type SMSListRequest = SMSCountRequest & {
  limit: number;
  offset: number;
};
