import { Wallet, WalletUpdateRequest } from "@narayana/billing-api";
import { useCallback } from "react";

import { useApi, useForm, useRequest } from "../../../../../hooks";

export function useData(wallet: Wallet) {
  const api = useApi();
  const [
    { title, isActive },
    { 
      handle: { title: onTitleChange },
      set: {isActive: setIsActive}
   }
  ] = useForm({ title: wallet.title, isActive: wallet.isActive });
  const { execute: save, ...saveRequest } = useRequest(
    (wallet: WalletUpdateRequest) => api.billing.wallets.update(wallet)
  );
  const { execute: deleteRestore, ...deleteRestoreRequest } = useRequest(
    ({id,isActive}:{id:string, isActive:boolean}) => api.billing.wallets[isActive?"delete":"restore"](id)
  );
  const onSave = useCallback(
    (_: unknown) => {
      save({
        id: wallet.id,
        title: title,
        payload: wallet.payload
      });
    },
    [save, title, wallet.id, wallet.payload]
  );
  const onDeleteRestore = useCallback(
    (_:unknown) => {
      deleteRestore({id:wallet.id, isActive});
      setIsActive(!isActive);
    },
    [deleteRestore, isActive, setIsActive, wallet.id]
  )

  return {
    title,
    isActive,
    disabled: saveRequest.inProgress || deleteRestoreRequest.inProgress,
    error: saveRequest.error?.message || deleteRestoreRequest.error?.message || '',
    onTitleChange,
    onSave,
    onDeleteRestore
  };
}