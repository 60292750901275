import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import { InvitesCreateRequest } from "@narayana/invites-api";
import { Form } from "@narayana/ui";
import Button from "@mui/material/Button";

export const Invite = (
  {message, bonus, error, inProgress, onMessageChange, onBonusCurrencyChange, onBonusAmountChange, onCreateInvite}: InviteProps
  ) => {
  return (
    <Form>
      <TextField
        label={'Invite message'}
        value={message}
        disabled={inProgress}
        onChange={onMessageChange}
      />
      <Paper elevation={2}>
        <TextField
          label={'Bonus currency'}
          value={bonus?.currency || ''}
          disabled={inProgress}
          onChange={onBonusCurrencyChange}
        />
        <TextField
          label={'Bonus amount'}
          value={bonus?.amount || 0}
          disabled={inProgress}
          onChange={onBonusAmountChange}
        />
      </Paper>
      <Form.Actions error={error || ''}>
        <Button
          onClick={onCreateInvite}
          disabled={inProgress}
        >
          Create invite
        </Button>
      </Form.Actions>
    </Form>
  )
};

export type InviteProps = InvitesCreateRequest & {
  error: string;
  inProgress: boolean;
  onMessageChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBonusCurrencyChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onBonusAmountChange: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  onCreateInvite: React.MouseEventHandler<HTMLButtonElement>;
}