import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Provider } from "@narayana/billing-api"
import { Form, Loading } from "@narayana/ui";
import { Code } from "../../../../Code";

import { useData } from "./useData"

export const ProviderTabMain = ({ provider, loading }: ProviderTabMainProps) => {
  const {
    isNew,
    error,
    id,
    title,
    ip,
    payload,
    isActive,
    inProgress,
    onIdChange,
    onIpChange,
    onTitleChange,
    onPayloadChange,
    onDeleteRestore,
    onSave,
  } = useData(provider);

  return (
    <Form>
      <Loading loading={loading}>
        <TextField
          fullWidth
          disabled={!isNew || inProgress}
          label="ID"
          value={id}
          onChange={onIdChange}
        />
      </Loading>
      <Loading loading={loading}>
        <TextField
          fullWidth
          disabled={inProgress}
          label="Title"
          value={title || ''}
          onChange={onTitleChange}
        />
      </Loading>
      <Loading loading={loading}>
        <TextField
          multiline
          fullWidth
          disabled={inProgress}
          label="IP"
          value={ip || ''}
          onChange={onIpChange}
        />
      </Loading>
      <Loading loading={loading}>
        <Code
          language="json"
          title="Payload"
          value={payload}
          onChange={onPayloadChange}
          disabled={inProgress}
        />
      </Loading>
      <Form.Actions error={error}>
        {isNew
          ? <></>
          : (
            <Loading loading={loading}>
              <Button onClick={onDeleteRestore} color="secondary">
                {isActive ? 'delete': 'restore'}
              </Button>
            </Loading>
          )
        }
        <Loading loading={loading}>
          <Button onClick={onSave} color="primary">
            {provider.id ? 'UPDATE' : 'CREATE'}
          </Button>
        </Loading>
      </Form.Actions>
    </Form>
  )
}

export type ProviderTabMainProps = {
  provider: Provider,
  loading: boolean,
};