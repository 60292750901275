import Fab, { FabProps } from "@mui/material/Fab";
import { useStyles } from "./styles";

export function MainAction ({ className, ...props }: MainActionProps) {
  const styles = useStyles();

  return (
    <Fab 
      className={`${styles.fab} ${className}`} 
      color="secondary"
      {...props} 
    />
  )
}

export type MainActionProps = FabProps;