import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { DirectionBilling, DirectionGroupBilling, Service } from "@narayana/billing-api";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import GroupWorkIcon from "@mui/icons-material/GroupWork";
import { Form } from "@narayana/ui";

import { useStyles } from "./styles";
import { useView } from "./useView";
import ListItemButton from "@mui/material/ListItemButton";
import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";

export function DirectionsView (props: DirectionsViewType) {
  const styles = useStyles();
  const {
    q,
    handleQ,
    setQ,
    list,
    inProgress
  } = useView(props);
  const {
    service,
    services,
    setService,
    onImportShow,
  } = props;

  return (
    <div>
      <div className={styles.filter}>
        <TextField
          sx={{ minWidth: '150px' }}
          select
          label="Service"
          value={service}
          variant="filled"
          onChange={(e) => setService(e.target.value)}
        >
          {services.map((service) => (
            <MenuItem key={service.id} value={service.id}>
              {service.title}
            </MenuItem>
          ))}
        </TextField>
        <TextField 
          fullWidth 
          label="Direction prefix"
          value={q} 
          onChange={handleQ} 
        />
      </div>
      <Form.Actions>
        <Button startIcon={<ImportExportIcon />} onClick={onImportShow}>
          Import
        </Button>
      </Form.Actions>
      {list == null || inProgress
        ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Box>
        )
        : (
          list.length === 0 
            ? (
              <Alert color="error">
                Route "{q}" not supported
              </Alert>
            )
            : (
              <List>
                {list.map((item, index) => (
                  item.isGroup 
                    ? (
                      <ListItemButton key={index} onClick={() => setQ(item.group.direction)}>
                        <ListItemAvatar>
                          <GroupWorkIcon />
                        </ListItemAvatar>
                        <ListItemText 
                          primary={`${item.group.direction} [${item.group.interval}s]`}
                          secondary={formatBilling(item.group.billing)}
                        />
                      </ListItemButton>
                    )
                    : (
                      <ListItemButton key={index} onClick={() => setQ(item.direction.direction)}>
                        <ListItemAvatar>
                          <CompareArrowsIcon />
                        </ListItemAvatar>
                        <ListItemText 
                          primary={`${item.direction.direction} [${item.direction.interval}s] - ${item.direction.provider}`}
                          secondary={formatBilling(item.direction.billing)}
                        />
                      </ListItemButton>
                    )
                ))}
              </List>
            )
        )
      }
    </div>
  )
}

export type DirectionsViewType = {
  service: string,
  services: Service[],
  setService: (value: string) => void,
  onImportShow: () => void,
};

const formatBilling = (billing: (DirectionGroupBilling | DirectionBilling)[]) => 
  billing
    .map(x => 
      (x as DirectionBilling).price != null 
        ? `${(x as DirectionBilling).price} ${x.currency}`
        : `${(x as DirectionGroupBilling).min}-${(x as DirectionGroupBilling).max} ${x.currency}`
    )
    .join(' | ');