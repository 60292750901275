/* eslint-disable-next-line import/no-anonymous-default-export */
export default `# Todo & Issues

Entity / Component
Legend: 💡 - has proposals, 🐞 - has bugs, 🔳 - unfinished TODOs, ✅ - done

| Entity     |  List  | List Item |   Edit            | Create | Playground |
| ---------- |:------:|:---------:|:-----------------:|:------:|:----------:|
| Users      |   ✅   |    ✅     |   ✅💡🐞            |   ✅   |     ✅     |
| Currencies |   ✅   |    ✅     |   ✅💡🐞            |   ✅   |     ✅     |
| Devices    |  ✅🔳  |   ✅🔳     |   🔳💡🐞            |   🔳   |     ✅     |
| Services   |   ✅   |    ✅🐞Ï  |    ✅🐞             |   ✅   |     ✅     |
| Plans      |   ✅   |    ✅     |    🔳              |   🔳   |     ✅     |
| Acts       |        |          |                    |   ✅   |     ✅🐞   |
| Wallets    |   ✅   |    ✅     |  🐞🔳 Users, Balance|   ✅🐞 |     ✅    |
| Groups     |  ✅🐞  |    ✅🐞    |  ✅🔳🐞             |   ✅   |     ✅     |
| Sessions   | ✅🔳🐞  |    ✅🐞   |    🔳               |       |     ✅     |
| Routes     |   ✅   |   ✅🔳    |    ✅              |   ✅   |     ✅     |
| Providers  |   ✅   |   ✅      |    ✅              |   ✅   |     ✅     |

## General
* [ ] Proposal: Make sure we have Docz documentation for all components in library.
* [ ] Proposal: Restrict entering not letters in ID's of most of forms (we have a problem with '/' for example)
* [ ] Proposal: Default filter for entities should not show deleted elements.

## Filter component
* [ ] Bug: Filter chips are deleted after saving Edit card, but Filter values are not cleared.
* [ ] Bug: When changing filter settings, pagination should set page to 1.
* [ ] Proposal: Hide pagination if only one page available.
* [ ] Proposal: "a | b" to search a or b.
* [ ] Proposal: Click on search chips opens filter and focuses input.

## Menu component
* [ ] ? Proposal: Make group foldable by clicking on header.

## Article component
* [ ] Proposal: Back button.
* [ ] Proposal: Bug symbol character for "* [ ] Bug: ".
* [ ] Proposal: Proposal symbol character for "* [ ] Proposal: ".
* [ ] Proposal: Summary tag which counts open/closed bugs, , open/closed proposals, open/closed TODOs

## API Playground
* [ ] Proposal: Way to restore request.
* [ ] Proposal: API Calls history

## Users page
### Edit
* [ ] Bug: On the first display: A component is changing an uncontrolled input to be controlled. This is likely caused by the value changing from undefined to a defined value, which should not happen. Decide between using a controlled or uncontrolled input element for the lifetime of the component.
* [ ] Bug: API bug - Can create user with empty id
* [ ] Proposal: Entity deletion prompt.
* [ ] Proposal: password complexity validation.
* [ ] Proposal: Avatar upload.

## Currencies page
### Edit
* [ ] Proposal: ? Entity deletion prompt for unrecoverable entities.

## Devices page
### List
* [ ] TODO: Icons for different types of devices.
* [ ] TODO: Hide ID.
* [ ] TODO: Show wallet title instead of ID.
* [ ] TODO: Make Wallet picker in filter

## Services page
### List
* [ ] Bug: Service List api ignores q parameter value, on the server side

## Plans page
* [ ] TODO: Create empty plan
* [ ] TODO: Breadcrumbs

## Acts page
* [ ] Bug: Server side sometimes drops error with overdraft requests.

## Wallets page
### Create
* [x] Bug: Wallets create dialog is not closed after creating wallet
* [x] Bug: Wallets list is not updated after creating new wallet.чё
### Edit
* [ ] TODO: Overdraft edit on balance tab
* [ ] TODO: Users edit tab
* [ ] TODO: main tab: balance list for every currency
* [ ] Bug: Wallet Users are not returned by API
### List
* [ ] TODO: "My wallets" filter
* [ ] TODO: Update wallets list information after editing wallet
### ListItem
* [ ] Proposal: move component to UI

## Groups page
### Edit Item
* [ ] TODO: error handling for permissions creation at groups
* [ ] TODO: disable editing members for private groups
* [ ] Bug: routing regex for /group/ also works for /grouptrololo
* [ ] Bug: can create permissions with empty entity string and empty permissions string
* [ ] Bug: incorrect server error when sharing to already shared user
### List
* [ ] Bug: Can't remove 'Active' filter. Removing 'Inactive' filter makes it 'Active', not removes it.
### ListItem

## Sessions page
### List
* [ ] Bug: Filter by id (q) is not working.
* [ ] TODO: Filter by auth types should be ListBox
* [ ] TODO: Deleting session

## Routes page
* [ ] TODO: move RoutesListItem to UI library
* [ ] TODO: invalid json error displayed in Code

## Invites page
### Create
* [ ] TODO: invite creation
  );
`