import { GridColDef } from "@mui/x-data-grid";
import { parseCsv } from "./csv";
import { parseXlsx } from "./xlsx";

export const parse = async (file: File): Promise<ParseResult> => {
  const fileSplitted = file.name.split('.');
  const fileType = fileSplitted[fileSplitted.length - 1];
  
  switch (fileType) {
    case 'csv':
      return parseCsv(file);

    case 'xlsx':
      return parseXlsx(file);

    default:
      throw new Error(`Unsupported file type ${JSON.stringify(fileType)}`)
  }
}

export type ParseResult = {
  data: string[][], 
  columns: GridColDef[]
}