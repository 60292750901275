import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import { Editor } from "./Editor";
import { Help } from "./Help";
import { Preview } from "./Preview";
import { EditorTab, useMdEditor } from "./useMdEditor";

export function MdEditor () {
  const { tab, value, handleTabChange, handleValueChange } = useMdEditor();

  return (
    <div>
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab label="Editor" value="editor" />
        <Tab label="Preview" value="preview" />
        <Tab label="Help" value="help" />

      </Tabs>
      <Box sx={{ marginTop: 2 }}>
        <MdEditorRouter tab={tab} value={value} onValueChange={handleValueChange} />
      </Box>
    </div>
  )
}

function MdEditorRouter ({ tab, value, onValueChange }: MdEditorRouterProps) {
  switch (tab) {
    case 'editor':
      return <Editor value={value} onValueChange={onValueChange} />

    case 'preview':
      return <Preview value={value} />

    case 'help':
      return <Help />

    default:
      return <div>Unhandled tab: {JSON.stringify(tab)}</div>
  }
}

type MdEditorRouterProps = { 
  tab: EditorTab, 
  value: string, 
  onValueChange: (value: string) => void 
};