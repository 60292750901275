import GroupIcon from '@mui/icons-material/Group';
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import React from "react";
import Tooltip from '@mui/material/Tooltip'
import Typography from "@mui/material/Typography";
import { Group } from "@narayana/sso-api";
import { Loading } from "@narayana/ui/target/Loading";

export const GroupListItem = (props: GroupListItemProps) => {
  const onClick = React.useCallback(
    () => { props.onClick(props.group) },
    [props.group, props.onClick]
  );

  return (
    <ListItem disabled={props.disabled}>
      <ListItemButton
        disabled={props.disabled || props.loading}
        onClick={onClick}
      >
        <ListItemIcon>
          <Loading variant="circular" loading={props.loading} width={40} height={40}>
            <Tooltip title={
              props.group.isActive
                ? (props.group.isPersonal ? "Personal, Active" : "Not personal, Active")
                : (props.group.isPersonal ? "Personal, Not active" : "Not personal, Not active")
            }>
              {
                props.group.isActive
                  ? (props.group.isPersonal ? <PersonIcon /> : <GroupIcon />)
                  : (props.group.isPersonal ? <PersonOutlineIcon /> : <PeopleOutlineIcon />)
              }
            </Tooltip>
          </Loading>
        </ListItemIcon>
        <ListItemText
          primary={
            <Loading loading={props.loading} height={24} width={280}>
              <Typography
                sx={props.group.isActive ? {} : { textDecoration: 'line-through' }}
              >
                {props.group.title}
              </Typography>
            </Loading>}
          secondary={
            <Loading loading={props.loading} height={20} width={180}>
              {'Owner: ' + props.group.owner}
            </Loading>}
        />
      </ListItemButton>
    </ListItem>
  );
}
export type GroupListItemProps = {
  group: Group;
  disabled: boolean;
  loading: boolean;
  onClick: (group: Group) => void;
  onDelete: (group: Group) => void;
}

export default GroupListItem;