import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from "@mui/material/ListItemText";
import { Wallet } from "@narayana/billing-api";

export function WalletTabBalance({ wallet, loading, isVisible }: WalletTabBalanceProps) {
  if (!isVisible || loading) {
    return null;
  }

  return (
    <div>
      <List>
        {wallet.balance.map(
          (element) => {
            return (
              <ListItem>
                <ListItemButton >
                  <ListItemText 
                    primary={`${element.currency}: ${element.balance}`}
                    secondary={`Overdraft: ${element.overdraft}`}
                  />
                </ListItemButton>
              </ListItem>
            )
          }
        )}
      </List>
    </div>
  );
}

export type WalletTabBalanceProps = {
  wallet: Wallet;
  loading: boolean;
  isVisible: boolean;
}