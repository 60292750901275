import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import { Form } from "@narayana/ui";
import { Group, GroupPayload } from "@narayana/sso-api";
import { Loading } from "@narayana/ui";
import { useCallback, useState } from "react";

import { useApi, useGoto } from "../../../../hooks";

export function GroupGeneralTab({ loading, group, setGroup }: GroupGeneralTabPropsType) {
  const api = useApi();
  const { goto } = useGoto();
  const [error, setError] = useState('');
  const [disabled, setDisabled] = useState(false);

  const handleTitleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setGroup({ ...group, title: e.target.value })
  }, [group, setGroup]);
  const handleSaveClick = useCallback(
    async () => {
      setDisabled(true);
      setError('');
      try {
        group && await api.sso.groups.update({ id: group.id, title: group.title, payload: {} });
      } catch (e) {
        setError(JSON.stringify(e));
      }
      setDisabled(false);
    },
    [api.sso.groups, group]
  );
  const handleCreateClick = useCallback(
    async () => {
      setDisabled(true);
      setError('');
      try {
        let response;
        if (group && !group?.isPersonal) {
          response = (await api.sso.groups.create({ title: group.title, payload: {} })) as Group;
        }

        goto({ page: `group/${response?.id || ''}`, query: {} });
      } catch (e) {
        setError((e as Error).message);
      }
      setDisabled(false);
    },
    [api.sso.groups, goto, group]
  );
  const handleDeleteClick = useCallback(
    async () => {
      setDisabled(true);
      setError('');
      try {
        group && await api.sso.groups.delete(group.id);
        setGroup({ ...group, isActive: false });
      } catch (e) {
        setError(JSON.stringify(e));
      }
      setDisabled(false);
    },
    [api.sso.groups, group, setGroup]
  );
  const handleRestoreClick = useCallback(
    async () => {
      setDisabled(true);
      setError('');
      try {
        group && await api.sso.groups.restore(group.id);
        setGroup({ ...group, isActive: true });
      } catch (e) {
        setError(JSON.stringify(e));
      }
      setDisabled(false);
    },
    [api.sso.groups, group, setGroup]
  );
  const handleSwitchClick = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setGroup({ ...group, isPersonal: Boolean(event.target.value) })
    },
    [group, setGroup]
  );

  return (
    <Form>
      <Loading loading={loading}>
        <FormControlLabel
          control={
            <Switch
              checked={group.isPersonal}
              onChange={handleSwitchClick}
            />
          }
          label="Is personal group"
          disabled
        />
      </Loading>
      <Loading loading={loading}>
        <TextField
          label="Title"
          value={group.title}
          disabled={group.isPersonal || disabled}
          onChange={handleTitleChange}
        />
      </Loading>
      <Form.Actions error={error}>
        <Loading loading={loading} height={56}>
          <Button
            disabled={group.isPersonal || disabled || !group.id}
            color="secondary"
            onClick={Boolean(group?.isActive) ? handleDeleteClick : handleRestoreClick}
          >
            {Boolean(group?.isActive) ? 'Delete' : 'Restore'}
          </Button>
        </Loading>
        <Loading loading={loading} height={56}>
          <Button
            disabled={group.isPersonal || disabled}
            onClick={group.id ? handleSaveClick : handleCreateClick}
          >
            {group.id ? "Save" : "Create"}
          </Button>
        </Loading>
      </Form.Actions>
    </Form>
  );
}

export type GroupGeneralTabPropsType = {
  group: Group;
  setGroup: (group: Group<GroupPayload>) => void;
  loading: boolean;
}