import AppBar from "@mui/material/AppBar";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import React from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Dialog, { DialogProps } from "@mui/material/Dialog";

import { useRequest } from "../../hooks";

export function Entity<T, K extends string>({
  id,
  title,
  get,
  save,
  componentKey,
  Component,
  ComponentProps,
  DialogProps,
  onClose
}: EntityProps<T, K>) {
  const { execute: getRequestExecute, ...getRequest } = useRequest((id: string) => get(id));
  const { execute: saveRequestExecute, ...saveRequest } = useRequest((entity: T) => save(entity));

  React.useEffect(
    () => {
      if (!Boolean(id) && id !== '') {
        return;
      }

      getRequestExecute(id!);
    },
    [getRequestExecute, id]
  );

  const handleChange = React.useCallback(
    (entity: T) => saveRequestExecute(entity),
    [saveRequestExecute]
  );

  return (
    <Dialog
      {...(DialogProps || {})}
      fullWidth
      open={Boolean(id) || id === ''}
      onClose={onClose}
    >
      <DialogTitle sx={{ height: 64 }}>
        <AppBar position="absolute">
          <Toolbar>
            <Typography sx={{ flex: 1 }}>
              {title || id || ''}
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </DialogTitle>
      <DialogContent>
        <Component
          key={getRequest.result != null ? (getRequest.result as unknown as Record<"id", string>)["id"] : null}
          {...({ [componentKey]: getRequest.result || {} } as Record<K, T>)}
          loading={getRequest.result == null}
          disabled={saveRequest.inProgress}
          error={getRequest.error?.message || saveRequest.error?.message}
          onChange={handleChange}
          {...ComponentProps}
        />
      </DialogContent>
    </Dialog>
  )
}

export type EntityProps<T, K extends string> = {
  id: string | null | undefined,
  title?: string | null | undefined,
  get: (id: string) => Promise<T>,
  save: (entity: T) => Promise<void>,
  componentKey: K,
  Component: React.FC<EntityComponentProps<T, K>>,
  DialogProps?: Partial<DialogProps>,
  ComponentProps?: Record<string, unknown>,
  onClose: () => void
};

export type EntityComponentProps<T, K extends string> = Record<K, T> & {
  loading: boolean;
  disabled: boolean;
  error?: string;
  onChange: (entity: T) => void;
};