import { ApiEntity, ApiMethod } from "@narayana/api";
import { ApiBilling } from "..";

@ApiEntity("Plans", "Methods for managing plans")
export class APIPlans {
  constructor (
    public api: ApiBilling
  ) { }

  @ApiMethod(
    "Method for create plan",
    [ "plans#create" ],
    {
      id: "base",
      title: "Base",
      wallet: "<wallet-id>",
      isActive: true,
      payload: { a: 42 },
      services: [
        {
          direction: '1', 
          interval: 1,
          freeThreshold: 0,
          connectionFeeCurrency: "€",
          connectionFeePrice: "0.22",
          payload: {},
          billing: [
            { currency: 'euro', price: "69", priceType: 'just_the_price' }
          ]
        },
        {
          direction: '12', 
          interval: 1,
          freeThreshold: 0,
          connectionFeeCurrency: "€",
          connectionFeePrice: "0.22",
          payload: {},
          billing: [
            { currency: 'euro', price: "1.3", priceType: 'direction_k' }
          ]
        }
      ]
    } as PlanCreateRequest,
    undefined
  )
  async create<T, R> (request: PlanCreateRequest<T, R>): Promise<undefined> {
    return this.api.execute("/plans", "POST", null, request);
  }

  @ApiMethod(
    "Method for update plan",
    [ "plans#update" ],
    {
      id: "plan_id",
      title: "new_title",
      wallet: "wallet_id",
      isActive: true,
      payload: { }
    } as PlanUpdateRequest,
    undefined
  )
  async update<T> (request: PlanUpdateRequest<T>): Promise<void> {
    return this.api.execute("/plans", "PUT", null, request);
  }

  @ApiMethod(
    "Method for delete plan",
    [ "plans#delete" ],
    "<plan-id>",
    undefined
  )
  async delete (id: string): Promise<void> {
    return this.api.execute(`/plans/${id}`, "DELETE", null, null);
  }

  @ApiMethod(
    "Method for restore plan",
    [ "plans#restore" ],
    "<plan-id>",
    undefined
  )
  async restore (id: string): Promise<void> {
    return this.api.execute(`/plans/${id}/restore`, "PUT", null, null);
  }

  @ApiMethod(
    "Method for get plan",
    [ "plans#read" ],
    "<plan-id>",
    {
      id: "plan_id",
      title: "new_title",
      wallet: "wallet_id",
      isActive: true,
      payload: { }
    } as Plan
  )
  async get (id: string): Promise<Plan> {
    return this.api.execute(`/plans/${id}`, "GET", null, null);
  }

  @ApiMethod(
    "Method for counting number of plans by filter",
    [ "plans#read" ],
    {
      q: "ba",
      wallet: "wlt_id",
      is_active: true,
    } as PlansCountRequest,
    { count: 42 }
  )
  async count (filter: PlansCountRequest): Promise<{ count: number }> {
    return this.api.execute(`/plans/count`, "GET", filter, null);
  }

  @ApiMethod(
    "Method for get plans list",
    [ "plans#read" ],
    {
      q: "ba",
      wallet: "wlt_id",
      is_active: true,
      limit: 10,
      offset: 0
    } as PlansListRequest,
    [ 
      {
        id: "base",
        title: "Base",
        wallet: "wallet_id",
        isActive: true,
        payload: { a: 42 },
      }
    ]
  )
  async list (filter: PlansCountRequest): Promise<Plan[]> {
    return this.api.execute(`/plans`, "GET", filter, null);
  }

  @ApiMethod(
    "Method for update services",
    [ "plans#update" ],
    {
      plan: "ba",
      service: "sip_call_out",
      services: [
        {
          direction: '1', 
          interval: 1,
          freeThreshold: 0,
          connectionFeeCurrency: "€",
          connectionFeePrice: "0.22",
          payload: {},
          billing: [
            { currency: 'euro', price: "69", priceType: 'just_the_price' }
          ]
        }
      ]
    } as PlanServicesUpdateRequest,
    undefined
  )
  async servicesUpdate<T> (filter: PlanServicesUpdateRequest<T>): Promise<undefined> {
    return this.api.execute(`/plans/services`, "PUT", filter, null);
  }

  @ApiMethod(
    "Method for get plans services list",
    [ "plans#read" ],
    {
      plan: 'plan_id_or_null',
      device: 'device_id_or_null',
      service: 'sip_call_out',
      q: '372',
    } as PlanServicesListRequest,
    {
      groups: [
        {
          plan: 'plan_id',
          direction: 'direction_id',
          interval: 1,
          billing: [
            { currency: '€', min: '0.22', max: '0.25' }
          ],
        }
      ],
      services: [
        {
          direction: 'direction_id',
          interval: 1,
          freeThreshold: 0,
          connectionFeeCurrency: '€',
          connectionFeePrice: '0',
          payload: { },
          billing: [
            {
              currency: '€',
              priceType: 'just_the_price',
              price: '0.25',
              directionPrice: '0.17'
            }
          ]
        }
      ]
    } as PlanServicesListResponse
  )
  async servicesList<T> (filter: PlanServicesListRequest): Promise<PlanServicesListResponse<T>> {
    return this.api.execute(`/plans/services`, "PUT", filter, null);
  }
}

export type PlanCreateRequest<T = PlansPayload, R = PlansPayload> = {
  id: string;
  title: string;
  wallet: string;
  isActive: boolean;
  payload: T;
  services: PlanService<R>[]
};

export type PlanUpdateRequest<T = PlansPayload> = {
  id: string;
  title: string;
  wallet: string;
  isActive: boolean;
  payload: T;
};

export type PlansCountRequest = {
  q: string | null | undefined,
  wallet: string | null | undefined,
  is_active: boolean | null | undefined
};

export type PlansListRequest = PlansCountRequest & {
  limit: number,
  offset: number,
};

export type PlanServicesListRequest = {
  plan?: string | null | undefined,
  device?: string | null | undefined,
  service: string,
  q: string,
};

export type PlanServicesListResponse<T = PlansPayload> = {
  groups: PlanServicesGroup[],
  services: PlanService<T>[]
};

export type PlanService<T> = {
  direction: string,
  interval: number,
  freeThreshold: number,
  connectionFeeCurrency: string,
  connectionFeePrice: string,
  payload: T,
  billing: PlanServiceBilling[]
};

export type PlanServiceBilling = {
  currency: string,
  priceType: string,
  price: string,
  directionPrice?: string | null | undefined
}

export type Plan<T = PlansPayload> = {
  id: string;
  title: string;
  wallet: string;
  isActive: boolean;
  payload: T;
};

export type PlanServicesUpdateRequest<T = PlansPayload> = {
  plan: string,
  service: string,
  services: PlanService<T>[],
};

export type PlanServicesGroup = {
  plan: string,
  direction: string,
  interval: number,
  billing: PlanServiceGroupBilling[],
};

export type PlanServiceGroupBilling = {
  currency: string,
  min: string,
  max: string
};

export type PlansPayload = Record<string, unknown>;