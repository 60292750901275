import { ParseResult } from ".";

export const parseCsv = async (file: File): Promise<ParseResult> => {
  return parseCsv.text(await file.text());
}

parseCsv.text = async (str: string, separator: string = ','): Promise<ParseResult> => {
  const data = str.split('\n').map((row) => row.split(separator));

  return { 
    data, 
    columns: data.length === 0 
      ? []
      : new Array(data[0].length)
        .fill(null)
        .map((_, index) => ({ field: `${index}`, headerName: `Column ${index}` }))
  }
}