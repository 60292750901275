import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  signIn: {
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    minWidth: '60vw',
    maxWidth: 500,
    margin: 'auto'
  }
});