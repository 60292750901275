export const webassembly = `# WebAssembly

## For what

This will give us the ability to safely run custom code on the web client side.

We will be able not only to develop custom solutions for clients ourselves, 
but also let them write an application for automating calls, for example.

## How?

WebAssembly is a well-isolated sandbox in itself and we just have 
to give access to a part of the screen and import the necessary API access.

## UI

Working version [iced](https://iced.rs/).

![Iced ToDo example](https://camo.githubusercontent.com/54e584347854ab6084f26859910d421e45a95f6fe066eb5cb13dc32a78fb6cc3/68747470733a2f2f7468756d62732e6766796361742e636f6d2f4c6974746c6553616e6548616c69636f72652d736d616c6c2e676966)`;