import { createUseStyles } from 'react-jss';

export const useStyles = createUseStyles({
  h1: { 
    fontSize: '2rem'
  },
  h2: { 
    fontSize: '1.5rem',
    margin: '0.5rem 0'
  },
  h3: { 
    fontSize: '1.3rem',
    margin: '0.5rem 0'
  },
  h4: { 
    fontSize: '1.2rem',
    margin: '0.5rem 0'
  },
  h5: { 
    fontSize: '1.1rem',
    margin: '0.5rem 0'
  },
  h6: { 
    fontSize: '1rem',
    margin: '0.5rem 0'
  },
  li: {
    fontFamily: `"Roboto","Helvetica","Arial",sans-serif`,
    fontWeight: '400',
    fontSize: '1rem'
  },
  table: {
    width: '80%',
    margin: '8px auto'
  }
});