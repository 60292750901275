import { API, ApiService, Article } from '@narayana/api'
import docs from './__docs'
import { APIMessages } from './messages';
import { APIDevices } from './devices';

@Article("sms", "SMS managing service", docs.concept)
@ApiService("SMS", docs.readme)
export class ApiSMS extends API {
  public devices = new APIDevices(this);
  public messages = new APIMessages(this);

  constructor (prefix: string) {
    super(prefix);
  }
}
