import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { Wallet } from "@narayana/billing-api";

import { WalletTabBalance } from './TabBalance';
import { WalletTabMain } from './TabMain';
import { WalletTabUsers } from './TabUsers';
import { useData } from "./data";

export function PersonalWallet({ wallet, loading }: PersonalWalletProps) {
  const { tab, onTabChange } = useData();

  return (
    <div>
      <Tabs value={tab} onChange={onTabChange}>
        <Tab label="Main" value="main" />
        <Tab label="Users" value="users" />
        <Tab label="Balance" value="balance" />
      </Tabs>
      <WalletTabMain wallet={wallet} loading={loading} isVisible={tab === 'main'} />
      <WalletTabUsers wallet={wallet} loading={loading} isVisible={tab === 'users'} />
      <WalletTabBalance wallet={wallet} loading={loading} isVisible={tab === 'balance'} />
    </div>
  )
}

export type PersonalWalletProps = {
  wallet: Wallet;
  loading: boolean;
}