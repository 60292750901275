import { API, ApiService, Article } from '@narayana/api'
import docs from './__docs'

@Article("nc", "Notification Center", docs.concept)
@Article("todo", "TODO", docs.todo)
@ApiService("Notification Center", docs.readme)
export class ApiNC extends API {
  constructor (prefix: string) {
    super(prefix);
  }
}