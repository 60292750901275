import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { DataGrid } from "@mui/x-data-grid";
import { DirectionsUpdateRequestBilling } from '@narayana/billing-api';
import { Form } from '@narayana/ui';
import { useImport } from './useImport'

export function DirectionsImportTabImport (props: DirectionsImportTabImportProps) {
  const { 
    provider, 
    directions, 
    error, 
    importError,
    inProgress,
    handleProvider,
    handleImport,
    handleRefresh
  } = useImport(props);

  if (error) {
    return (
      <div style={{ display: 'flex' }}>
        <Alert color="error" sx={{ flex: 1 }}>
          {error}
        </Alert>
        <div>
          <IconButton onClick={handleRefresh}>
            <CloseIcon />
          </IconButton>
        </div>
      </div>
    );
  }

  return (
    <>
      {directions == null 
        ? (
          <CircularProgress />
        )
        : (
          <div style={{ height: 400 }}>
            <Form>
              <TextField 
                label="Provider"
                disabled={inProgress}
                value={provider}
                onChange={handleProvider}
              />
              <Form.Actions error={importError}>
                <Button
                  disabled={inProgress || provider.length === 0}
                  onClick={handleImport}
                >
                  Import
                </Button>
              </Form.Actions>
            </Form>
            <DataGrid
              rows={directions}
              columns={[
                { field: 'direction', headerName: 'Direction' },
                { field: 'title', headerName: 'Title', width: 300 },
                { field: 'interval', headerName: 'Interval' },
                { 
                  field: 'payload', 
                  headerName: 'Payload', 
                  renderCell: ({ value }) => (<div>{JSON.stringify(value)}</div>)
                },
                { 
                  field: 'billing', 
                  headerName: 'Billing', 
                  renderCell: ({ value }) => {
                    const billing = value as DirectionsUpdateRequestBilling[];

                    return (
                      <div>
                        {billing
                          .map(({ currency, price }) => `${currency}:${price}`)
                          .join('/')
                        }
                      </div>
                    );
                  }
                },
              ]}
              pageSize={5}
              rowsPerPageOptions={[ 5 ]}
            />
          </div>
        )}
    </>
  )
}

export type DirectionsImportTabImportProps = {
  service: string,
  data: string[][],
  code: string
};