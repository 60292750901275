import { useData } from "./useData"
import { Provider } from "@narayana/billing-api";
import Button from "@mui/material/Button";
import { Form, Loading } from "@narayana/ui";
import { Code } from "../../../../Code";

export const ProviderTabServices = ({ provider, loading }: ProviderTabServicesProps) => {
  const {
    gate,
    payload,
    isActive,
    inProgress,
    error,
    setGate,
    setPayload,
    onDeleteRestore,
    onSave
  } = useData(provider);

  return (
    <Form>

      <Loading loading={loading}>
        <Code
          language="json"
          title="Gate"
          value={gate}
          onChange={setGate}
          disabled={inProgress}
        />
      </Loading>
      <Loading loading={loading}>
        <Code
          language="json"
          title="Payload"
          value={payload}
          onChange={setPayload}
          disabled={inProgress}
        />
      </Loading>
      <Form.Actions error={error}>
        {isActive === null
          ? null
          : (
            <Loading loading={loading}>
              <Button onClick={onDeleteRestore} color="secondary">
                {isActive ? 'delete service': 'restore'}
              </Button>
            </Loading>
          )
        }
        <Loading loading={loading}>
          <Button onClick={onSave} color="primary">
            {isActive === null?
              'CREATE SERVICE':
              'SAVE'
            }
          </Button>
        </Loading>
      </Form.Actions>
    </Form>
  )
}

export type ProviderTabServicesProps = {
  provider: Provider,
  loading: boolean,
};
