export default `
# Phones database

## Database

\`\`\`mermaid
erDiagram
Numbers {
  id VARCHAR
  spaces VARCHAR
  services INTEGER
  number VARCHAR
  parent VARCHAR
  order REAL
  payload JSONB
}
History {
  date TIMESTAMP
  session VARCHAR
  type VARCHAR
  payload JSONB
}
\`\`\`
`