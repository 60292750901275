import { Form, Loading } from "@narayana/ui";

import Button from '@mui/material/Button';
import React from 'react'
import { Session } from "@narayana/sso-api";
import TextField from '@mui/material/TextField';

export const SessionEdit = (props: SessionEditProps) => {
  const onTerminate = React.useCallback(()=>{
    props.onSessionTerminate(props.session)
  },[props.session, props.onSessionTerminate]);
  return (
    <Form>
      <Loading loading={props.loading} height={56}>
        <TextField 
          id="id" 
          label="ID" 
          variant="outlined" 
          fullWidth 
          disabled 
          value={props.session.id} 
        />
      </Loading>
      <Loading loading={props.loading} height={56}>
        <TextField 
          id="auth" 
          label="Auth" 
          variant="outlined" 
          disabled 
          value={props.session.auth} 
        />
      </Loading>
      <Loading loading={props.loading} height={56}>
        <TextField 
          id="start" 
          label="Start" 
          variant="outlined" 
          disabled 
          value={props.session.start} 
        />
      </Loading>
      <Loading loading={props.loading} height={56}>
        <TextField 
          id="due" 
          label="Due" 
          variant="outlined" 
          disabled 
          value={props.session.due} 
        />
      </Loading>
      <Form.Actions error={props.error}>
        <Loading loading={props.loading} height={56}>
          <Button 
            disabled={props.disabled} 
            color='primary' 
            onClick={onTerminate}>
              Terminate session
            </Button>
        </Loading>
      </Form.Actions>
    </Form>
  );
}
export type SessionEditProps = {
  session: Session;
  loading: boolean;
  disabled: boolean;
  error?: string;
  onSessionTerminate: (session: Session) => void;
}

export default SessionEdit;