import { DirectionsUpdateRequest, DirectionsUpdateRequestItem } from "@narayana/billing-api";
import { useCallback, useEffect } from "react";
import { DirectionsImportTabImportProps } from ".";
import { useApi, useForm, useQuery, useRequest } from "../../../../../hooks";

export const useImport = (props: DirectionsImportTabImportProps) => {
  const api = useApi();
  const [ 
    { 
      directions,
      error,
      next
    },
    { 
      set: { 
        directions: setDirections,
        error: setError,
        next: setNext
      }
    }
  ] = useForm({ 
    directions: null as DirectionsUpdateRequestItem[] | null,
    error: null as string | null,
    next: 0
  });


  const [ 
    { provider, },
    { 
      handle: {
        provider: handleProvider
      }
    }
  ] = useQuery({ 
    provider: '',
  });

  const { execute: executeImport, ...importRequest } = useRequest(
    async (requset: DirectionsUpdateRequest) => api.billing.directions.update(requset)
  );

  useEffect(() => {
    setError(null);
    setDirections(null);

    (async () => {
      /* eslint-disable-next-line no-eval */
      const mapFn = eval(`(() => {${props.code}; return processRow; })()`);
      const result: DirectionsUpdateRequestItem[] = [];

      for (let i = 0; i < props.data.length; i++) {
        const direction = mapFn(props.data[i], i);
        if (direction == null) {
          continue;
        }

        direction.id = i;
        result.push(direction);
      }
      
      return result;
    })()
      .then((result: DirectionsUpdateRequestItem[]) => setDirections(result))
      .catch((e) => setError(e.message))
  }, [ props.data, props.code, next, setDirections, setError ]);

  const handleImport = useCallback(() => {
    if (directions == null || directions.length === 0) {
      setError("No directions to import")
      return;
    }

    executeImport({ provider, service: props.service, directions });
  }, [ provider, props.service, directions, executeImport, setError ]);
  const handleRefresh = useCallback(
    () => setNext(next + 1),
    [ next, setNext ]
  );

  return {
    provider,
    directions,
    error: error,
    importError: importRequest.error?.message,
    inProgress: importRequest.inProgress,
    handleProvider,
    handleImport,
    handleRefresh
  }
}